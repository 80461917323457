// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";

export const activeCssClasses = { true: "success", false: "danger" };
export const activeTitles = { true: "Yes", false: "No" };

export function BooleanColumnFormatter(cellContent, row, rowIndex, { field }) {
  let boolValue = row[field] || false;
  if (boolValue === "0") boolValue = false;
  else if (boolValue === "1") boolValue = true;

  const getLabelCssClasses = () => {
    return `label label-lg label-light-${activeCssClasses[boolValue]} label-inline`;
  };
  return (
    <span className={getLabelCssClasses()}>{activeTitles[boolValue]}</span>
  );
}
