import React from "react";
import "./style.css";

const TechStackComponent = ({ title, data, selected, handleSelect }) => {
  const stackTypes = {
    be_stack: "backEnd",
    fe_stack: "frontEnd",
    qa_stack: "qa",
    devOps_stack: "devOps",
    full_stack: "fullStack",
  };

  return (
    <div className="tech-stack-component-container">
      <h2
        className="col-md-3"
        style={{
          color: "#515444",
          fontWeight: "500",
          fontSize: "2em",
          marginBottom:'0'
        }}
      >
        {title}
      </h2>

      <div
        style={{
          display: "flex",
          width: "100%",
          gap: "2em",
        }}
        className="row"
      >
        {data?.map((item, index) => (
          <button
            key={index}
            style={{
              background: `${
                selected?.id === item?.id ? "#029863" : "#dcdbd3"
              }`,
              color: `${selected?.id === item?.id ? "#fff" : ""}`,
            }}
            className="tech-stack-component-button col-md-3"
            onClick={() => handleSelect(item)}
          >
            {Object.keys(stackTypes)?.map(
              (stackType) =>
                item[stackType] && (
                  <p key={stackType}>
                    <span
                      style={{ textTransform: "capitalize", fontWeight: 500 }}
                    >
                      {`${stackTypes[stackType]
                        .charAt(0)
                        .toUpperCase()}${stackTypes[stackType]
                        .slice(1)
                        .toLowerCase()}: ${item[stackType]}`}
                    </span>
                  </p>
                )
            )}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TechStackComponent;
