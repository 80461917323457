import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./JobRolesUIHelpers";

const JobRolesUIContext = createContext();

export function useJobRolesUIContext() {
  return useContext(JobRolesUIContext);
}

export const JobRolesUIConsumer = JobRolesUIContext.Consumer;

export function JobRolesUIProvider({ jobRolesUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initJobRole = {
    id: undefined,
    name: "",
    skillSearchText: "",
    skills: [],
    active: true,
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
    initJobRole,
    newJobRoleButtonClick: jobRolesUIEvents.newJobRoleButtonClick,
    openViewJobRoleDialog: jobRolesUIEvents.openViewJobRoleDialog,
    openEditJobRoleDialog: jobRolesUIEvents.openEditJobRoleDialog,
    openDeleteJobRoleDialog: jobRolesUIEvents.openDeleteJobRoleDialog,
  };

  return (
    <JobRolesUIContext.Provider value={value}>
      {children}
    </JobRolesUIContext.Provider>
  );
}
