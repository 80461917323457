export const actionTypes = {
  CatchError: "[Catch Job Role Error] Action",
  ResetError: "[Reset Job Role Error] Action",
  StartCall: "[Start Job Role Call] Action",
  FetchJobRole: "[Fetch Job Role] Action",
  JobRoleFetched: "[Job Role Fetched] Action",
  FetchJobRoles: "[Fetch Job Roles] Action",
  JobRolesFetched: "[Job Roles Fetched] Action",
  UpsertJobRole: "[Upsert Job Role] Action",
  JobRoleCreated: "[Job Role Created] Action",
  JobRoleUpdated: "[Job Role Updated] Action",
  DeleteJobRole: "[Delete Job Role] Action",
  JobRoleDeleted: "[Job Role Deleted] Action",
  Logout: "[Logout] Action",
};

export const callTypes = {
  list: "list",
  action: "action",
};

const initialJobRolesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  jobRoleForEdit: undefined,
  error: null,
};

export const actions = {
  catchError: (error, callType) => ({
    type: actionTypes.CatchError,
    payload: { error, callType },
  }),
  startCall: (callType) => ({
    type: actionTypes.StartCall,
    payload: { callType },
  }),
  resetError: () => ({ type: actionTypes.ResetError }),
  fetchJobRole: (id) => ({
    type: actionTypes.FetchJobRole,
    payload: { jobRoleId: id },
  }),
  jobRoleFetched: (jobRole) => ({
    type: actionTypes.JobRoleFetched,
    payload: { jobRoleForEdit: jobRole },
  }),
  fetchJobRoles: (queryParams) => ({
    type: actionTypes.FetchJobRoles,
    payload: { queryParams },
  }),
  jobRolesFetched: (totalCount, jobRoles) => ({
    type: actionTypes.JobRolesFetched,
    payload: { totalCount, entities: jobRoles },
  }),
  upsertJobRole: (jobRole, pageSize, onHide) => ({
    type: actionTypes.UpsertJobRole,
    payload: { jobRole, pageSize, onHide },
  }),
  jobRoleCreated: (jobRole, pageSize) => ({
    type: actionTypes.JobRoleCreated,
    payload: { jobRole, pageSize },
  }),
  jobRoleUpdated: (jobRole) => ({
    type: actionTypes.JobRoleUpdated,
    payload: { jobRole },
  }),
  deleteJobRole: (id, handlePostDelete) => ({
    type: actionTypes.DeleteJobRole,
    payload: { jobRoleId: id, active: false, handlePostDelete },
  }),
  jobRoleDeleted: (id) => ({
    type: actionTypes.JobRoleDeleted,
    payload: { jobRoleId: id },
  }),
  logout: () => ({ type: actionTypes.Logout }),
};

export const reducer = (state = initialJobRolesState, action) => {
  switch (action.type) {
    case actionTypes.CatchError: {
      const newState = { ...state };
      const { error, callType } = action.payload;
      newState.error = error.response
        ? `${error.response.data.message}`
        : "Internal server error";

      if (callType === callTypes.list) {
        newState.listLoading = false;
      } else {
        newState.actionsLoading = false;
      }
      return newState;
    }

    case actionTypes.ResetError: {
      const newState = { ...state };

      newState.error = null;
      return newState;
    }

    case actionTypes.StartCall: {
      const newState = { ...state };
      const { callType } = action.payload;

      newState.error = null;
      if (callType === callTypes.list) {
        newState.listLoading = true;
      } else {
        newState.actionsLoading = true;
      }
      return newState;
    }

    // getJobRoleById
    case actionTypes.JobRoleFetched: {
      const newState = { ...state };
      const { jobRoleForEdit } = action.payload;

      newState.actionsLoading = false;
      newState.jobRoleForEdit = jobRoleForEdit;
      newState.error = null;
      return newState;
    }

    case actionTypes.JobRolesFetched: {
      const newState = { ...state };
      const { totalCount, entities } = action.payload;

      newState.listLoading = false;
      newState.error = null;
      newState.entities = entities;
      newState.totalCount = totalCount;
      return newState;
    }

    case actionTypes.JobRoleCreated: {
      const newState = { ...state };
      const { jobRole, pageSize } = action.payload;

      newState.actionsLoading = false;
      newState.error = null;
      if (newState.entities.length < pageSize)
        newState.entities = [...newState.entities, jobRole];
      return newState;
    }

    case actionTypes.JobRoleUpdated: {
      const newState = { ...state };
      const { jobRole } = action.payload;

      newState.error = null;
      newState.actionsLoading = false;
      newState.entities = newState.entities.map((entity) => {
        if (entity.id === jobRole.id) return jobRole;
        return entity;
      });
      return newState;
    }

    case actionTypes.JobRoleDeleted: {
      const newState = { ...state };
      const { jobRoleId } = action.payload;

      newState.error = null;
      newState.actionsLoading = false;
      newState.entities = newState.entities.filter((el) => el.id !== jobRoleId);
      return newState;
    }

    case actionTypes.Logout: {
      return initialJobRolesState;
    }

    default: {
      return state;
    }
  }
};
