export const App_Urls = {
  // Users Urls
  USERS_URL: "/v1/admin/user",
  USER_REGISTER_URL: "/register",
  // Permissions Url
  PERMISSIONS_URL: "/v1/admin/permission",
  // Roles Urls
  ROLES_URL: "/v1/admin/role",
  // Auth Urls
  LOGIN_URL: "/login",
  REFRESH_URL: "/refresh",
  LOGOUT_URL: "/logout",
  CHANGE_PASSWORD_URL: "/me/change-password",
  //JobRoles Urls
  JOB_ROLES_URL: "/v1/admin/job-role",
  //Profiles Urls
  PROFILES_URL: "/v1/admin/profile",

  //managed by url
  PROFILE_MANAGED_BY:"/v1/admin/profile_managers",
  
  //Skills Urls
  SKILLS_URL: "/v1/admin/skill",
  //Instant Profiles Urls
  INSTANT_PROFILE_URL: "/v1/admin/profile/search",
  //OCR Urls
  OCR_URL: "/v1/admin/profile/ocr",
  //Smart Feedback Url
  SMART_FEEDBACK_URL: "/v1/admin/profile/feedback",
  //questionnaire URLs
  QUESTIONNAIRE_SUBMIT: "/user-preference/submit",
  QUESTIONNAIRE_START: "/user-preference/start",
  QUESTIONNAIRE_SAVE_RESPONSE: "/user-preference/save-response",

};
