import React, { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { SkillsFilter } from "./skills-filter/SkillsFilter";
import { useSkillsUIContext } from "./SkillsUIContext";
import { SkillsTable } from "./skills-table/SkillsTable";
import { NotAuthorizedMessage } from "../../../../../_metronic/_helpers";
import JoyrideTutorial from "../../../../../_metronic/_partials/controls/JoyrideTutorial";

export function SkillsCard() {
  const { allowedPages } = useSelector(
    (state) => ({ allowedPages: state.auth.allowedPages }),
    shallowEqual
  );

  const skillsUIContext = useSkillsUIContext();
  const skillsUIProps = useMemo(() => {
    return {
      newSkillButtonClick: skillsUIContext.newSkillButtonClick,
      uploadSkillsButtonClick: skillsUIContext.uploadSkillsButtonClick,
    };
  }, [skillsUIContext]);
  const steps = [
    {
      target: ".skills",
      content: "Welcome to  skills page , this page contains your list of skills ,we are providing a bunch of skills data.",
      placement: "center",
      disableBeacon: true,
    },
    {
      target: ".skills-upload",
      content: "If you want to add skills by uploading file.",
      placement: "left",
      disableBeacon: true,
    },
    {
      target: ".skills-add",
      content: "If you want to manually add skills.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".skills-search",
      content: "You can search from the list of skills.",
      placement: "right",
      disableBeacon: true,
    },
    {
      target: ".data-view",
      content: "You can view details of skill",
      placement: "left",
      disableBeacon: true,
    },
    {
      target: ".data-edit",
      content: "You can edit details of skill",
      placement: "left",
      disableBeacon: true,
    },
    {
      target: ".data-delete",
      content: "You can delete the skill",
      placement: "left",
      disableBeacon: true,
    },
   
  ];
  const redirectRoute = "/resume/job-roles";
  return (
    <Card className='skills'>
      <CardHeader title="Skills list">
        {allowedPages.skill?.create && (
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-outline-success mr-2 skills-upload"
              onClick={skillsUIProps.uploadSkillsButtonClick}
              
            >
              Upload Skills
            </button>
            <button
              type="button"
              className="btn btn-outline-primary skills-add"
              onClick={skillsUIProps.newSkillButtonClick}
            >
              Add Skill
            </button>
          </CardHeaderToolbar>
        )}
      </CardHeader>
      <CardBody>
        {allowedPages.skill?.read ? (
          <>
            <SkillsFilter />
            <SkillsTable />
            <JoyrideTutorial steps={steps} redirectRoute={redirectRoute}/>
          </>
        ) : (
          <NotAuthorizedMessage />
        )}
      </CardBody>
    </Card>
  );
}
