// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import SVG from "react-inlinesvg";

import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import {
  Input,
  Select,
  Error,
} from "../../../../../../_metronic/_partials/controls";

// Validation schema
const SkillEditSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, "Minimum 1 symbol")
    .max(50, "Maximum 50 symbols")
    .required("Name is required"),
  active: Yup.boolean(),
  variants: Yup.array()
    .of(
      Yup.string()
        .min(1, "Minimum 1 symbol")
        .required("Skill Variant is required")
    )
    .min(1, "Minimum 1 variant required"),
});

export function SkillEditForm({
  saveSkill,
  isEdit = true,
  skill,
  actionsLoading,
  onHide,
}) {
  const { errorMsg } = useSelector(
    (state) => ({ errorMsg: state.skills.error }),
    shallowEqual
  );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={skill}
        validationSchema={SkillEditSchema}
        onSubmit={(values) => {
          saveSkill(values);
        }}
      >
        {({ values, handleSubmit, setFieldValue, dirty }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}

              <Error message={errorMsg} />

              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* Name */}
                  <div className="col-lg-4">
                    <label>{isEdit ? "Enter Skill Name" : "Skill Name"}</label>
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Name"
                      disabled={!isEdit}
                      onChange={(e) => {
                        setFieldValue("name", e.target.value);
                        const newVariants = [...values.variants];
                        newVariants[0] = e.target.value;
                        setFieldValue("variants", newVariants);
                      }}
                    />
                  </div>

                  {/* Active */}
                  <div className="col-lg-4">
                  <label>{isEdit?'Select Active':'Active'}</label>
                    <Select name="active" disabled={!isEdit}>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </Select>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-12">
                    <label>
                      {isEdit ? "Enter Skill Variants" : "Skill Variants"}
                    </label>
                    <FieldArray name="variants">
                      {(arrayHelpers) =>
                        values.variants && values.variants.length > 0 ? (
                          <>
                            {values.variants.map((variant, index) => (
                              <div key={index} className="row my-2">
                                <div className="col-lg-4">
                                  <Field
                                    name={`variants.${index}`}
                                    component={Input}
                                    placeholder="Skill Variant"
                                    disabled={index === 0 || !isEdit}
                                  />
                                </div>
                                {isEdit && index > 0 && (
                                  <div className="d-flex align-items-center">
                                    <button
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                      className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                    >
                                      <span className="svg-icon svg-icon-md svg-icon-danger">
                                        <SVG
                                          src={toAbsoluteUrl(
                                            "/media/svg/icons/General/Trash.svg"
                                          )}
                                        />
                                      </span>
                                    </button>
                                  </div>
                                )}
                              </div>
                            ))}
                            {isEdit &&
                              values.variants[values.variants.length - 1] !==
                                "" && (
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-light btn-elevate"
                                    onClick={() => arrayHelpers.push("")}
                                  >
                                    Add Skill Variant
                                  </button>
                                </div>
                              )}
                          </>
                        ) : isEdit ? (
                          <div>
                            <button
                              type="button"
                              className="btn btn-light btn-elevate"
                              onClick={() => arrayHelpers.push("")}
                            >
                              Add Skill Variant
                            </button>
                          </div>
                        ) : (
                          <></>
                        )
                      }
                    </FieldArray>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            {isEdit && (
              <Modal.Footer>
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate"
                  disabled={!dirty}
                >
                  Save
                </button>
              </Modal.Footer>
            )}
          </>
        )}
      </Formik>
    </>
  );
}
