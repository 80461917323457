import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { NotAuthorizedMessage } from "../../../../../../_metronic/_helpers";
import {
  Error,
  ModalProgressBar,
} from "../../../../../../_metronic/_partials/controls";

import { actions } from "../../../_redux/skills";
import { useSkillsUIContext } from "../SkillsUIContext";

export function SkillDeleteDialog({ id, show, onHide }) {
  // Skills UI Context
  const skillsUIContext = useSkillsUIContext();
  const skillsUIProps = useMemo(() => {
    return {
      queryParams: skillsUIContext.queryParams,
    };
  }, [skillsUIContext]);

  // Skills Redux state
  const dispatch = useDispatch();
  const { isLoading, allowedPages, errorMsg } = useSelector(
    (state) => ({
      isLoading: state.skills.actionsLoading,
      allowedPages: state.auth.allowedPages,
      errorMsg: state.skills.error,
    }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handlePostSkillDelete = () => {
    dispatch(actions.fetchSkills(skillsUIProps.queryParams));
    // closing delete modal
    onHide();
  };

  const deleteSkill = () => {
    // server request for deleting skill by id
    dispatch(actions.deleteSkill(id, handlePostSkillDelete));
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      {allowedPages.skill?.delete ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Skill Delete
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Error message={errorMsg} />
            {!isLoading && (
              <span>Are you sure to permanently delete this skill?</span>
            )}
            {isLoading && <span>Skill is deleting...</span>}
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>

            <button
              type="button"
              onClick={deleteSkill}
              className="btn btn-primary btn-elevate"
            >
              Delete
            </button>
          </Modal.Footer>
        </>
      ) : (
        <NotAuthorizedMessage />
      )}
    </Modal>
  );
}
