import React, { useState } from "react";
import { Formik, FieldArray } from "formik";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { actions as skillsActions } from "../../Resume/_redux/skills";
import { Button, ButtonGroup } from "@material-ui/core";
import { actions as build_team_actions } from "../_redux/buildTeam/buildTeamRedux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

import "./style.css";
import {
  Card,
  CloseableBadges,
  MultiSelect,
  Checkbox,
} from "../../../../_metronic/_partials/controls";

const TechStack = () => {
  const {
    skillListLoading,
    skillEntities,
    selected_profiles,
    currentStack,
    selected_skills,
  } = useSelector(
    (state) => ({
      skillListLoading: state.skills.listLoading,
      skillEntities: state.skills.entities,
      selected_profiles: state?.build_team?.selected_profiles,
      currentStack: state?.build_team?.currentStack,
      selected_skills: state?.build_team?.selected_skills,
    }),
    shallowEqual
  );

  const [dropdownZIndex, setDropdownZIndex] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const [tech, setTech] = useState(
    currentStack !== "" ? currentStack : "backEnd"
  );

  const [techSkills, setTechSkills] = useState(selected_skills);

  const handleSkillFieldClick = () => {
    setDropdownZIndex(2);
  };

  //Debouncing Skills Search
  const handleSkillSearchChange = (text) => {
    const queryParams = { filter: { search: text }, pageSize: 5 };
    dispatch(skillsActions.fetchSkills(queryParams));
  };

  const debouncedLoadOptions = useDebouncedCallback(
    handleSkillSearchChange,
    500
  );

  //tech stack toggle buttons
  const createButton = (key, label, techType, svg) => (
    <Button
      className={tech === techType ? "" : "btn-group"}
      size="large"
      style={{
        fontWeight: 600,
        color: "#2C3E50",
        border: "none",
        padding: "0.5em 3em 0.5em 1em",
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        textTransform: "capitalize",
        transition: "all 0.3s ease-in",
        borderRadius: tech === techType ? "2em" : "",

        background: tech === techType ? "#dcdbd3" : "",
      }}
      key={key}
      onClick={() => setTech(techType)}
    >
      <SVG
        src={toAbsoluteUrl(svg)}
        style={{ width: "8%", height: "8%", margin: "0 10px 0 0" }}
        className="svg-container"
      />
      {label}
    </Button>
  );

  const buttons = [
    createButton("one", "Back End", "backEnd", "/media/new_icons/backend.svg"),
    createButton(
      "two",
      "Front End",
      "frontEnd",
      "/media/new_icons/frontend.svg"
    ),
    createButton("three", "Dev Ops", "devOps", "/media/new_icons/devops.svg"),
    createButton("four", "QA", "qa", "/media/new_icons/qa.svg"),
    createButton(
      "five",
      "Full Stack",
      "fullStack",
      "/media/new_icons/fullstack.svg"
    ),
  ];

  const handleRemoveItem = (index, tech, setTechSkills) => {
    setTechSkills((prev) => ({
      ...prev,
      [tech]: prev[tech].slice(0, index).concat(prev[tech].slice(index + 1)),
    }));
  };

  const handleAddSelectedItem = (item, tech, setTechSkills, techSkills) => {
    const categoryExists = techSkills[tech].some(
      (skill) => skill.id === item.id
    );
    if (!categoryExists) {
      setTechSkills((prev) => ({
        ...prev,
        [tech]: [...prev[tech], item],
      }));
    }
  };

  const handleSelectProfiles = () => {
    dispatch(build_team_actions.UpdateCurrentStack(tech));
    dispatch(build_team_actions.SelectedSkills(techSkills));
    dispatch(
      build_team_actions.FetchProfilesByTechStack(
        techSkills[tech].map((skill) => skill.id)
      )
    );

    history.push("/build-team/select-profiles");
  };

  function handleSubmit(selectedProfiles) {
    try {
      const selectedProfilesJSON = JSON.stringify(selectedProfiles);
      localStorage.setItem("selected_profiles", selectedProfilesJSON);
      history.push("/build-team/thank-you");
    } catch (error) {
      console.error("Error saving selected profiles to local storage:", error);
    }
  }

  return (
    <Card className="build-team-container">
      <div className="row tech-stack-container">
        {/* left-side */}
        <div className="col-lg-4">
          <ButtonGroup
            orientation="vertical"
            style={{ width: "70%", height: "auto", fontSize: "1em" }}
          >
            <h2>Tech Stack</h2>
            <h5 style={{ color: "#515444", marginBottom: "1em" }}>
              Select your skills
            </h5>

            {buttons}
          </ButtonGroup>
        </div>

        {/* right-side */}
        <div className="col-lg-7">
          <Formik
            initialValues={{
              skillSearchText: "",
              skills: [],
            }}
            onSubmit={() => {}}
          >
            {({ values, handleSubmit, handleBlur, setFieldValue }) => (
              <form onSubmit={handleSubmit} className="form form-label-right">
                <div style={{ marginBottom: "0.1em", marginLeft: "3em" }}>
                  <FieldArray name="skills">
                    {(arrayHelpers) => (
                      <MultiSelect
                        className="team_build_autocomplete"
                        inputClassName="col-lg-8"
                        placeholder="Search for skills..."
                        searchValue={values.skillSearchText}
                        handleSearchChange={(text) => {
                          setFieldValue("skillSearchText", text);
                          debouncedLoadOptions(text);
                        }}
                        fetchResults={handleSkillSearchChange}
                        isLoading={skillListLoading}
                        handleItemSelect={(item) =>
                          handleAddSelectedItem(
                            item,
                            tech,
                            setTechSkills,
                            techSkills
                          )
                        }
                        options={skillEntities}
                        badge={false}
                        zIndex={2}
                        handleSkillFieldClick={handleSkillFieldClick}
                      />
                    )}
                  </FieldArray>
                </div>
                {techSkills[tech]?.length > 0 && (
                  <div style={{ marginLeft: "3em" }}>
                    <CloseableBadges
                      values={techSkills[tech]}
                      removeItem={(index) =>
                        handleRemoveItem(index, tech, setTechSkills)
                      }
                      className="build_team_closable"
                    />
                  </div>
                )}
              </form>
            )}
          </Formik>
        </div>
      </div>
      <div className="row tech-stack-btn-container">
        <Button
          size="large"
          className="btn col-sm-1 tech-stack-btn"
          onClick={() => handleSubmit(selected_profiles)}
          disabled={Object.values(selected_profiles).every(array => array.length === 0)}
        >
          Submit
        </Button>
        <Button
          size="large"
          className="btn col-sm-3 tech-stack-btn tech-stack-btn-select"
          onClick={() => handleSelectProfiles()}
          disabled={techSkills[tech]?.length < 1}
        >
          {`Select ${tech} Profiles`}
        </Button>
      </div>
    </Card>
  );
};

export default TechStack;
