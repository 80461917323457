import React from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import { actions } from "../../_redux/skills";
import { SkillsUploadDialog } from "./skills-upload-dialog/SkillsUploadDialog";
import { SkillEditDialog } from "./skill-edit-dialog/SkillEditDialog";
import { SkillDeleteDialog } from "./skill-delete-dialog/SkillDeleteDialog";
import { SkillsLoadingDialog } from "./skills-loading-dialog/SkillsLoadingDialog";
import { SkillsUIProvider } from "./SkillsUIContext";
import { SkillsCard } from "./SkillsCard";
import { actions as profileActions } from "../../_redux/profiles";

export function SkillsPage({ history }) {
  const skillsUIEvents = {
    uploadSkillsButtonClick: () => {
      history.push("/resume/skills/upload");
    },
    newSkillButtonClick: () => {
      history.push("/resume/skills/new");
    },
    openViewSkillDialog: (id) => {
      history.push(`/resume/skills/${id}/view`);
    },
    openEditSkillDialog: (id) => {
      history.push(`/resume/skills/${id}/edit`);
    },
    openDeleteSkillDialog: (id) => {
      history.push(`/resume/skills/${id}/delete`);
    },
  };

  const dispatch = useDispatch();

  //making Profiles table visible if hidden
  dispatch(profileActions.ProfilesTableState());

  const handleHide = () => {
    history.push("/resume/skills");
    dispatch(actions.resetError());
  };

  return (
    <>
      <SkillsUIProvider skillsUIEvents={skillsUIEvents}>
        <SkillsLoadingDialog />

        <Switch>
          <Route exact path="/resume/skills/upload">
            {({ match }) => (
              <SkillsUploadDialog show={match != null} onHide={handleHide} />
            )}
          </Route>

          <Route exact path="/resume/skills/new">
            {({ match }) => (
              <SkillEditDialog show={match != null} onHide={handleHide} />
            )}
          </Route>

          <Route exact path="/resume/skills/:id/view">
            {({ match }) => (
              <SkillEditDialog
                isEdit={false}
                show={match != null}
                id={match && match.params.id}
                onHide={handleHide}
              />
            )}
          </Route>

          <Route exact path="/resume/skills/:id/edit">
            {({ match }) => (
              <SkillEditDialog
                show={match != null}
                id={match && match.params.id}
                onHide={handleHide}
              />
            )}
          </Route>

          <Route exact path="/resume/skills/:id/delete">
            {({ match }) => (
              <SkillDeleteDialog
                show={match != null}
                id={match && match.params.id}
                onHide={handleHide}
              />
            )}
          </Route>
        </Switch>

        <SkillsCard />
      </SkillsUIProvider>
    </>
  );
}
