import React, { useState } from "react";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Modal, ModalBody } from "react-bootstrap";
import { Field, FieldArray } from "formik";
import { Input } from "../../../../../../_metronic/_partials/controls";

export function ProfileDeploymentHistory({
  values,
  setFieldValue,
  isEdit,
  setMessage,
  setShowAlert,
}) {
  const [showModal, setShowModal] = useState(false);
  const [modalRemarks, setModalRemarks] = useState("");

  const handleRemarksModal = (remarks) => {
    // set showModal to true when the "Read more" button is clicked
    setModalRemarks(remarks);
    setShowModal(true);
  };

  const hideModal = () => {
    // set showModal to false when the modal is closed
    setShowModal(false);
  };

  return (
    <div>
      <FieldArray name="deployment_history">
        {(arrayHelpers) => (
          <div>
            {isEdit && (
              <div className="form-group row">
                <div className="col-lg-12 mb-5">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => {
                      const isFirstDeploymentIncomplete =
                        values.deployment_history?.length > 0 &&
                        !values.deployment_history[0].final_completion;
                      if (isFirstDeploymentIncomplete) {
                        setMessage(
                          "Please Enter Final Completion Date of Current Deployment"
                        );
                        setShowAlert(true);
                      } else {
                        arrayHelpers.unshift({
                          end_client: "",
                          service_provider: "",
                          start_date: "",
                          final_completion: "",
                          expected_completion: "",
                          remarks: "",
                        });
                      }
                    }}
                  >
                    Add Deployment
                  </button>
                </div>
              </div>
            )}
            {values.deployment_history?.map((deployment, index) => (
              <div key={index}>
                <div
                  key={index}
                  className="mb-5"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "80%",
                    margin: "5px auto",
                    border: "1px solid #adb5bd",
                    borderRadius: "10px",
                    padding: "15px 50px",
                    justifyContent: "center",
                  }}
                >
                  {index === 0 && (
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "400",
                        marginBottom: "10px",
                        color: "#0a9396",
                      }}
                    >
                      Current deployment
                    </span>
                  )}
                  <div className="form-group row">
                    <div className="col-lg mb-5">
                      <Field
                        name={`deployment_history[${index}].end_client`}
                        placeholder="end client"
                        component={Input}
                        value={deployment.end_client || ""}
                        label="End Client"
                        withFeedbackLabel={false}
                        type="text"
                        className="form-control"
                        onChange={(event) => {
                          setFieldValue(
                            `deployment_history[${index}].end_client`,
                            event.target.value
                          );
                        }}
                        disabled={!isEdit}
                      />
                    </div>
                    <div className="col-lg mb-5">
                      {deployment.service_provider || isEdit ? (
                        <Field
                          name={`deployment_history[${index}].service_provider`}
                          placeholder="service provider Name"
                          component={Input}
                          label="Service Provider"
                          withFeedbackLabel={false}
                          type="text"
                          value={deployment.service_provider || ""}
                          className="form-control"
                          onChange={(event) => {
                            setFieldValue(
                              `deployment_history[${index}].service_provider`,
                              event.target.value
                            );
                          }}
                          disabled={!isEdit}
                        />
                      ) : null}
                    </div>
                    {isEdit && (
                      <div className="col-md-1 mb-5">
                        <button
                          type="button"
                          onClick={() => arrayHelpers.remove(index)}
                          className="btn btn-icon btn-light btn-hover-danger btn-sm"
                        >
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/General/Trash.svg"
                              )}
                            />
                          </span>
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="form-group row">
                    <div className="col-lg mb-5">
                      <label htmlFor="start_date">Start Date</label>
                      <Field
                        name={`deployment_history[${index}].start_date`}
                        placeholder="Tech Stack"
                        component="input"
                        type="date"
                        value={deployment.start_date?.slice(0, 10) || ""}
                        className="form-control"
                        onChange={(event) => {
                          setFieldValue(
                            `deployment_history[${index}].start_date`,
                            event.target.value
                          );
                        }}
                        disabled={!isEdit}
                      />
                    </div>
                    {deployment.final_completion || isEdit ? (
                      <div className="col-lg mb-5">
                        <label htmlFor="final_completion">
                          Final Completion
                        </label>

                        <Field
                          name={`deployment_history[${index}].final_completion`}
                          placeholder="final completion"
                          component="input"
                          type="date"
                          value={
                            deployment.final_completion?.slice(0, 10) || ""
                          }
                          className="form-control"
                          onChange={(event) => {
                            setFieldValue(
                              `deployment_history[${index}].final_completion`,
                              event.target.value
                            );
                          }}
                          max={new Date().toISOString().slice(0, 10)} //max to today's date
                          disabled={!isEdit}
                        />
                      </div>
                    ) : null}
                    <div className="col-lg mb-5">
                      <label htmlFor="expected_completion">
                        Expected Completion
                      </label>
                      <Field
                        name={`deployment_history[${index}].expected_completion`}
                        placeholder="expected completion"
                        component="input"
                        value={
                          deployment.expected_completion?.slice(0, 10) || ""
                        }
                        type="date"
                        className="form-control"
                        onChange={(event) => {
                          setFieldValue(
                            `deployment_history[${index}].expected_completion`,
                            event.target.value
                          );
                        }}
                        disabled={!isEdit}
                      />
                    </div>
                  </div>
                  {deployment.remarks || isEdit ? (
                    <div>
                      <label>Remarks:</label>
                      <Field
                        name={`deployment_history[${index}].remarks`}
                        placeholder="remarks"
                        component="textarea"
                        type="textarea"
                        value={deployment.remarks || ""}
                        className="form-control"
                        onChange={(event) => {
                          setFieldValue(
                            `deployment_history[${index}].remarks`,
                            event.target.value
                          );
                        }}
                        style={{
                          height: "8rem",
                          overflow: isEdit ? "auto" : "hidden",
                          fontSize: "15px",
                          resize: isEdit ? "auto" : "none",
                        }}
                        disabled={!isEdit}
                      />
                      {!isEdit && deployment.remarks?.length >= 20 && (
                        <div>
                          <button
                            onClick={() =>
                              handleRemarksModal(
                                values.deployment_history[index].remarks
                              )
                            }
                            className="btn btn-link p-0"
                          >
                            Read more
                          </button>
                        </div>
                      )}

                      {/* Render the modal */}
                      <Modal show={showModal} size="lg" centered>
                        <Modal.Header
                          style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                          }}
                        >
                          <button
                            className="btn btn-sm btn-danger "
                            onClick={hideModal}
                          >
                            close
                          </button>
                        </Modal.Header>
                        <ModalBody
                          style={{
                            fontSize: "15px",
                            fontWeight: "400",
                          }}
                        >
                          {modalRemarks}
                        </ModalBody>
                      </Modal>
                    </div>
                  ) : null}
                </div>
                {index < values.deployment_history.length - 1 && (
                  <span>
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/down-arrow.svg"
                      )}
                      width="32px"
                      height="32px"
                    />
                  </span>
                )}
              </div>
            ))}
          </div>
        )}
      </FieldArray>
    </div>
  );
}
