import React, { useState } from "react";
import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ProfileDetails } from "./resumeComponents/ProfileDetails";
import { SkillsForm } from "./resumeComponents/SkillsForm";
import { EducationDetailsForm } from "./resumeComponents/EducationDetailsForm";
import { ProfessionalExperienceForm } from "./resumeComponents/ProfessionalExperienceForm";
import { CertificatesForm } from "./resumeComponents/CertificatesForm";
import { Projects } from "./resumeComponents/Projects";
import { UploadResumeForm } from "./resumeComponents/UploadResumeForm";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actions } from "../../_redux/profiles";

export function ResumeFormPage(props) {
  const [activeTab, setActiveTab] = useState("upload");
  const history = useHistory();

  const { resume,resume_path, resumeEdit, ocr } = useSelector(
    (state) => ({
      resume: state?.profiles?.resume,
      resume_path:state?.profiles?.profileForEdit?.resume,
      resumeEdit: state?.profiles?.resumeEdit,
      ocr: state?.auth?.allowedPages?.resume_builder?.ocr,
    }),
    shallowEqual
  );

  
  const default_resume_values = {
    firstname: "",
    lastname: "",
    phone_no: "",
    email: "",
    linkedin: "",
    github: "",
    website: "",
    address: "",
    summary: "",
    skills: [
      {
        skill_type: "",
        skill: "",
      },
    ],
    work_experience: [
      {
        job_title: "",
        organization: "",
        start_date: "",
        end_date: "",
        is_current: "",
        description: "",
      },
    ],
    projects: [
      {
        role: "",
        projectName: "",
        techStack: "",
        start_date: "",
        end_date: "",
        description: "",
      },
    ],
    education: [
      {
        organization: "",
        name: "",
        startDate: "",
        end_date: "",
      },
    ],
    certifications: [],
  };
  const [Values, setValues] = useState({ ...default_resume_values });

  const renderComponent = (values, setFieldValue) => {
    switch (activeTab) {
      case "upload":
        return (
          <UploadResumeForm
            values={values}
            setActiveTab={setActiveTab}
            setValues={setValues}
            resume_path={resume_path}
          />
        );
      case "profile":
        return <ProfileDetails values={values} setFieldValue={setFieldValue} />;
      case "education":
        return (
          <EducationDetailsForm values={values} setFieldValue={setFieldValue} />
        );
      case "professionalExperience":
        return (
          <ProfessionalExperienceForm
            values={values}
            setFieldValue={setFieldValue}
          />
        );
      case "projects":
        return <Projects values={values} setFieldValue={setFieldValue} />;
      case "skills":
        return <SkillsForm values={values} setFieldValue={setFieldValue} />;
      case "certificates":
        return (
          <CertificatesForm values={values} setFieldValue={setFieldValue} />
        );
      default:
        return null;
    }
  };

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const path = "/resume/resume-template";
    dispatch(actions.ResumeBuilder(values));
    history.push(path);
  };

  return (
    <Formik
      initialValues={resumeEdit ? resume : Values}
      enableReinitialize={true}
    >
      {({ values, setFieldValue, resetForm }) => (
        <Form>
          <div
            style={{
              display: "flex",
              gap: "1rem",
              flexWrap: "wrap",
              height: "auto",
              width: "100%",
            }}
          >
            {ocr && (
              <div
                style={{
                  borderRight: "2px solid #ccc",
                  paddingRight: "1rem",
                  width: "15%",
                  height: "content-fit",
                  boxSizing: "border-box",
                }}
              >
                <Button
                  variant={
                    activeTab === "upload"
                      ? "outline-info"
                      : "outline-secondary"
                  }
                  onClick={() => setActiveTab("upload")}
                  style={{
                    boxShadow: "0 0 5px 2px rgba(0,0,0,0.3)",
                    fontSize: "0.8rem",
                    marginTop: "2px",
                  }}
                >
                  Upload Resume
                </Button>
              </div>
            )}
            <div
              style={{
                display: "flex",
                gap: "1rem",
                flexWrap: "wrap",
                width: "80%",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant={activeTab === "profile" ? "primary" : "secondary"}
                onClick={() => setActiveTab("profile")}
                style={{
                  fontSize: "1rem",
                  flex: 1,
                }}
              >
                Profile
              </Button>
              <Button
                variant={activeTab === "education" ? "primary" : "secondary"}
                onClick={() => setActiveTab("education")}
                style={{
                  fontSize: "1rem",
                  flex: 1,
                }}
              >
                Education
              </Button>
              <Button
                variant={
                  activeTab === "professionalExperience"
                    ? "primary"
                    : "secondary"
                }
                onClick={() => setActiveTab("professionalExperience")}
                style={{
                  fontSize: "1rem",
                  flex: 1,
                }}
              >
                Experience
              </Button>
              <Button
                variant={activeTab === "projects" ? "primary" : "secondary"}
                onClick={() => setActiveTab("projects")}
                style={{
                  fontSize: "1rem",
                  flex: 1,
                }}
              >
                Projects
              </Button>
              <Button
                variant={activeTab === "skills" ? "primary" : "secondary"}
                onClick={() => setActiveTab("skills")}
                style={{
                  fontSize: "1rem",
                  flex: 1,
                }}
              >
                Skills
              </Button>
              <Button
                variant={activeTab === "certificates" ? "primary" : "secondary"}
                onClick={() => setActiveTab("certificates")}
                style={{
                  fontSize: "1rem",
                  flex: 1,
                }}
              >
                Certificates
              </Button>
            </div>
          </div>
          <hr
            style={{ marginTop: "20px", borderBottom: "0.25rem dashed grey" }}
          />
          <div
            style={{
              margin: "20px",
              padding: "10px",
              width: "100%",
              height: "100%",
              boxSizing: "border-box",
            }}
          >
            {renderComponent(values, setFieldValue)}
          </div>

          <div
            className="text-center mt-14"
            style={{
              marginTop: "3rem",
              display: "flex",
              gap: "1.5rem",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {activeTab !== "upload" && (
              <>
                <Button
                  onClick={() => {
                    resetForm({ values: { ...default_resume_values } });
                  }}
                  variant="outline-info"
                  className="ml-2 px-20"
                >
                  Reset Data
                </Button>

                <Button
                  onClick={() => {
                    handleSubmit(values);
                  }}
                  variant="outline-success"
                  className="ml-2 px-10"
                >
                  Generate Resume
                </Button>
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
