import React from "react";
import { Form, FormLabel } from "react-bootstrap";
import { Field } from "formik";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export function ProfileDetails({ values, setFieldValue }) {
  return (
    <>
      <div className="row" style={{ marginBottom: "10px" }}>
        <div className="col-lg-6">
          <Form.Label style={{ marginBottom: "0" }}>First name:</Form.Label>
          <Field
            name="firstname"
            type="text"
            placeholder="Your first name"
            value={values.firstname}
            as={Form.Control}
            onChange={(e) => {
              setFieldValue("firstname", e.target.value);
            }}
          />
        </div>
        <div className="col-lg-6">
          <Form.Label style={{ marginBottom: "0" }}>Last name:</Form.Label>
          <Field
            name="lastname"
            type="text"
            placeholder="Your last name"
            value={values.lastname}
            as={Form.Control}
            onChange={(e) => {
              setFieldValue("lastname", e.target.value);
            }}
          />
        </div>
      </div>

      {/* <div className="row" style={{ marginBottom: "10px" }}>
        <div className="col-lg-6">
          <Form.Label style={{ marginBottom: "0" }}>Phone Number:</Form.Label>
          <InputGroup>
            <InputGroup.Text id="inputGroupPrepend">+</InputGroup.Text>
            <Field
              name="phone_no"
              type="tel"
              placeholder="phone number"
              value={values.phone_no}
              maxLength={10}
              as={Form.Control}
              onChange={(e) => {
                setFieldValue("phone_no", e.target.value);
              }}
            />
          </InputGroup>
        </div>
        <div className="col-lg-6">
          <Form.Label style={{ marginBottom: "0" }}>Email address:</Form.Label>
          <Field
            name="email"
            type="email"
            placeholder="Your email address"
            value={values.email}
            as={Form.Control}
            onChange={(e) => {
              setFieldValue("email", e.target.value);
            }}
          />
        </div>
      </div>

      <div className="row" style={{ marginBottom: "10px" }}>
        <div className="col-lg-6">
          <Form.Label style={{ marginBottom: "0" }}>Linkedin:</Form.Label>
          <Field
            name="linkedin"
            type="url"
            placeholder="https://example.com"
            pattern="https://.*"
            as={Form.Control}
            value={values.linkedin}
            onChange={(e) => {
              setFieldValue("linkedin", e.target.value);
            }}
          />
        </div>
        <div className="col-lg-6">
          <Form.Label style={{ marginBottom: "0" }}>Github:</Form.Label>
          <Field
            name="github"
            type="url"
            placeholder="https://example.com"
            pattern="https://.*"
            as={Form.Control}
            value={values.github}
            onChange={(e) => {
              setFieldValue("github", e.target.value);
            }}
          />
        </div>
      </div>

      <div className="row" style={{ marginBottom: "15px" }}>
        <div className="col-lg-6">
          <Form.Label style={{ marginBottom: "0" }}>
            Portfolio or Website:
          </Form.Label>
          <Field
            name="website"
            type="url"
            placeholder="https://example.com"
            pattern="https://.*"
            as={Form.Control}
            value={values.website}
            onChange={(e) => {
              setFieldValue("website", e.target.value);
            }}
          />
        </div>
        <div className="col-lg-6">
          <FormLabel style={{ marginBottom: "0" }}>Address: </FormLabel>
          <Field
            as="input"
            type="text"
            name="address"
            placeholder="city, country"
            value={values.address}
            className="form-control"
            onChange={(e) => {
              setFieldValue("address", e.target.value);
            }}
          />
        </div>
      </div> */}
      <div className="row" style={{ marginBottom: "15px" }}>
        <div className="col-lg-12">
          <FormLabel style={{ marginBottom: "0" }}>
            Professional Summary:
          </FormLabel>
          <ReactQuill
            theme="snow"
            value={values.summary}
            onChange={(value) => setFieldValue("summary", value)}
            style={{ height: "25rem" }}
          />
        </div>
      </div>
    </>
  );
}
