import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  allowedPages: undefined,
  client_logo: undefined,
  subscription_end_time:undefined,
  tutorial_done:undefined,
};

export const reducer = persistReducer(
  { storage, key: "auth", whitelist: ["user", "authToken", "allowedPages","client_logo","subscription_end_time","tutorial_done"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, user, allowedPages, client_logo ,subscription_end_time,tutorial_done} = action.payload;
        return { authToken, user, allowedPages, client_logo ,subscription_end_time,tutorial_done};
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken, user, allowedPages, client_logo,subscription_end_time,tutorial_done) => ({
    type: actionTypes.Login,
    payload: { authToken, user, allowedPages, client_logo,subscription_end_time ,tutorial_done},
  }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
};
