import React from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import { actions } from "../../_redux/jobRoles";
import { JobRoleEditDialog } from "./job-role-edit-dialog/JobRoleEditDialog";
import { JobRoleDeleteDialog } from "./job-role-delete-dialog/JobRoleDeleteDialog";
import { JobRolesLoadingDialog } from "./job-roles-loading-dialog/JobRolesLoadingDialog";
import { JobRolesUIProvider } from "./JobRolesUIContext";
import { JobRolesCard } from "./JobRolesCard";
import { actions as profileActions } from "../../_redux/profiles";

export function JobRolesPage({ history }) {
  const jobRolesUIEvents = {
    newJobRoleButtonClick: () => {
      history.push("/resume/job-roles/new");
    },
    openViewJobRoleDialog: (id) => {
      history.push(`/resume/job-roles/${id}/view`);
    },
    openEditJobRoleDialog: (id) => {
      history.push(`/resume/job-roles/${id}/edit`);
    },
    openDeleteJobRoleDialog: (id) => {
      history.push(`/resume/job-roles/${id}/delete`);
    },
  };

  const dispatch = useDispatch();

  //making Profiles table visible if hidden
  dispatch(profileActions.ProfilesTableState());

  const handleHide = () => {
    history.push("/resume/job-roles");
    dispatch(actions.resetError());
  };

  return (
    <>
      <JobRolesUIProvider jobRolesUIEvents={jobRolesUIEvents}>
        <JobRolesLoadingDialog />

        <Switch>
          <Route exact path="/resume/job-roles/new">
            {({ match }) => (
              <JobRoleEditDialog show={match != null} onHide={handleHide} />
            )}
          </Route>

          <Route exact path="/resume/job-roles/:id/view">
            {({ match }) => (
              <JobRoleEditDialog
                isEdit={false}
                show={match != null}
                id={match && match.params.id}
                onHide={handleHide}
              />
            )}
          </Route>

          <Route exact path="/resume/job-roles/:id/edit">
            {({ match }) => (
              <JobRoleEditDialog
                show={match != null}
                id={match && match.params.id}
                onHide={handleHide}
              />
            )}
          </Route>

          <Route exact path="/resume/job-roles/:id/delete">
            {({ match }) => (
              <JobRoleDeleteDialog
                show={match != null}
                id={match && match.params.id}
                onHide={handleHide}
              />
            )}
          </Route>
        </Switch>

        <JobRolesCard />
      </JobRolesUIProvider>
    </>
  );
}
