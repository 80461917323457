import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { LoadingDialog } from "../../../../_metronic/_partials/controls";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import { Button } from "react-bootstrap";
import {
  PaginatedTable,
  ActionsColumnFormatter,
  BooleanColumnFormatter,
  StarsColumnFormatter,
} from "../../../../_metronic/_partials/controls";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { actions as build_team_actions } from "../_redux/buildTeam/buildTeamRedux";
import { useProfilesUIContext } from "../../Resume/pages/profiles/ProfilesUIContext";

const SelectProfilesToBuildTeam = () => {
  const {
    profiles,
    listLoading,
    allowedPages,
    selected_profiles,
    currentStack,
  } = useSelector(
    (state) => ({
      listLoading: state?.build_team?.listLoading,
      profiles: state?.build_team?.entities,
      allowedPages: state.auth.allowedPages,
      selected_profiles: state?.build_team?.selected_profiles,
      currentStack: state?.build_team?.currentStack,
    }),
    shallowEqual
  );
  const [selectedRows, setSelectedRows] = useState(
    selected_profiles[currentStack] || []
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const profilesUIContext = useProfilesUIContext();
  const profilesUIProps = useMemo(() => {
    return {
      queryParams: profilesUIContext.queryParams,
      setQueryParams: profilesUIContext.setQueryParams,
      openViewProfileDialog: profilesUIContext.openViewProfileDialog,
      openEditProfileDialog: profilesUIContext.openEditProfileDialog,
      openPDFViewerDialog: profilesUIContext.openPDFViewerDialog,
    };
  }, [profilesUIContext]);

  const columns = [
    {
      dataField: "id",
      text: "ID",
      style: {
        color: "#2C3E50",
        fontWeight: "500",
      },
    },
    {
      dataField: "name",
      text: "Name",
      style: {
        maxWidth: "280px",
        color: "#2C3E50",
        fontWeight: "500",
      },
    },
    {
      dataField: "rating",
      text: "Rating",
      formatter: StarsColumnFormatter,
      formatExtraData: { field: "rating", maxCount: 5 },
    },
    {
      dataField: "vendor_name",
      text: "vendor name",
      formatter: (cell, row) => row.vendor_name || "In House",
      style: {
        color: "#2C3E50",
        fontWeight: "500",
      },
    },
    {
      dataField: "approved",
      text: "Approved",
      formatter: BooleanColumnFormatter,
      formatExtraData: { field: "approved" },
    },
    {
      dataField: "is_deployed",
      text: "Deployed",
      formatter: BooleanColumnFormatter,
      formatExtraData: { field: "is_deployed" },
    },
    {
      dataField: "resource_cost ",
      text: "Resource Cost",
      formatter: (cell, row) =>
        row.resource_cost !== null
          ? row.resource_cost?.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            })
          : row.resource_cost,
      formatExtraData: { field: "resource_cost" },
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        viewTitle: allowedPages.profile?.read && "View Profile",
        openViewDialog: profilesUIProps.openViewProfileDialog,
        urlField: "url",
        viewPdf: allowedPages.profile?.read && "View Pdf",
        openPDFViewerDialog: profilesUIProps.openPDFViewerDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "150px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: 10,
    sizePerPageList: [{ text: "10", value: "10" }],
    sizePerPage: profilesUIProps.queryParams.pageSize,
    page: profilesUIProps.queryParams.pageNumber,
  };

  function handleRowSelect(row) {
    if (selectedRows.some((selectedRow) => selectedRow.id === row.id)) {
      setSelectedRows(
        selectedRows.filter((selectedRow) => selectedRow.id !== row.id)
      );
    } else {
      setSelectedRows([...selectedRows, row]);
    }
  }

  useEffect(() => {
    if (selectedRows?.length > 0) {
      dispatch(build_team_actions.UpdateSelectedProfiles(selectedRows));
    }
  }, [selectedRows]);

  const selectRowProps = {
    mode: "checkbox",
    clickToSelect: true,
    hideSelectColumn: false,
    selected: selectedRows.map((profile) => profile.id),
    onSelect: (row) => {
      handleRowSelect(row);
    },
  };

  function handleSelect() {
    history.push("/build-team/tech-stack-known");
  }

  return (
    <Card
      style={{
        height: "auto",
        boxSizing: "border-box",
        padding: "2em",
        minHeight: "90vh",
      }}
    >
      <div className="row">
        <h2
          className="build-team-heading col-sm-10"
          style={{ textAlign: "center" }}
        >
          Select Profiles
        </h2>
        <Button
          className="col-sm-1"
          onClick={() => handleSelect()}
          style={{
            height: "70%",
            marginLeft: "auto",
            marginRight: "3em",
            background: "#029863",
            borderRadius: "2em",
            color: "#fff",
            border: "none",
            fontSize: "1.1em",
            fontWeight: 600,
          }}
        >
          Confirm
        </Button>
      </div>

      <CardBody>
        {listLoading ? (
          <LoadingDialog isLoading={listLoading} text={"Profiles loading"} />
        ) : (
          <PaginatedTable
            paginationOptions={paginationOptions}
            listLoading={listLoading}
            entities={profiles}
            columns={columns}
            setQueryParams={profilesUIProps.setQueryParams}
            selectRowProps={selectRowProps}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default SelectProfilesToBuildTeam;
