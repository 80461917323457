// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import * as profilesRedux from "../../../_redux/profiles";
import * as uiHelpers from "../ProfilesUIHelpers";
import {
  PaginatedTable,
  ActionsColumnFormatter,
  BooleanColumnFormatter,
  StarsColumnFormatter,
} from "../../../../../../_metronic/_partials/controls";
import { useProfilesUIContext } from "../ProfilesUIContext";

export function ProfilesTable() {
  // Profiles UI Context
  const profilesUIContext = useProfilesUIContext();
  const profilesUIProps = useMemo(() => {
    return {
      queryParams: profilesUIContext.queryParams,
      setQueryParams: profilesUIContext.setQueryParams,
      openViewProfileDialog: profilesUIContext.openViewProfileDialog,
      openEditProfileDialog: profilesUIContext.openEditProfileDialog,
      openPDFViewerDialog: profilesUIContext.openPDFViewerDialog,
    };
  }, [profilesUIContext]);

  // Getting current state of profiles list from store (Redux)
  const { currentState, allowedPages } = useSelector(
    (state) => ({
      currentState: state.profiles,
      allowedPages: state.auth.allowedPages,
    }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // Profiles Redux state
  const dispatch = useDispatch();
  useEffect(() => {
   
    // server call by queryParams
    dispatch(profilesRedux.actions.fetchProfiles(profilesUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilesUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "ID",
      style:{
        color:'#2C3E50',
        fontWeight:'500'
      }
    },
    {
      dataField: "name",
      text: "Name",
      style: {
        maxWidth: "280px",
        color:'#2C3E50',
        fontWeight:'500'
      },
    },
    {
      dataField: "rating",
      text: "Rating",
      formatter: StarsColumnFormatter,
      formatExtraData: { field: "rating", maxCount: 5 },
    },
    {
      dataField: "vendor_name",
      text: "vendor name",
      formatter: (cell, row) => row.vendor_name || "In House",
      style:{
        color:'#2C3E50',
        fontWeight:'500'
      }
    },
    {
      dataField: "approved",
      text: "Approved",
      formatter: BooleanColumnFormatter,
      formatExtraData: { field: "approved" },
    },
    {
      dataField: "is_deployed",
      text: "Deployed",
      formatter: BooleanColumnFormatter,
      formatExtraData: { field: "is_deployed" },
    },
    {
      dataField: "resource_cost ",
      text: "Resource Cost",
      formatter: (cell, row) =>(row.resource_cost!==null)?row.resource_cost?.toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
      }):row.resource_cost,
      formatExtraData: { field: "resource_cost" },
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        viewTitle: allowedPages.profile?.read && "View Profile",
        openViewDialog: profilesUIProps.openViewProfileDialog,
        editTitle: allowedPages.profile?.create && "Edit Profile",
        openEditDialog: profilesUIProps.openEditProfileDialog,
        downloadTitle: allowedPages.profile?.read && "Download Resume",
        urlField: "url",
        viewPdf: allowedPages.profile?.read && "View Pdf",
        openPDFViewerDialog: profilesUIProps.openPDFViewerDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "150px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: profilesUIProps.queryParams.pageSize,
    page: profilesUIProps.queryParams.pageNumber,
  };

  return (
    <PaginatedTable
      paginationOptions={paginationOptions}
      listLoading={listLoading}
      entities={entities}
      columns={columns}
      setQueryParams={profilesUIProps.setQueryParams}
    />
  );
}
