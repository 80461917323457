import React from "react";
import { Redirect, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { build_your_own_team } = useSelector(
    (state) => ({
      build_your_own_team: state?.auth.allowedPages?.build_your_own_team,
    }),
    shallowEqual
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        build_your_own_team ? (
          <Component {...props} />
        ) : (
          <Redirect to="/resume" />
        )
      }
    />
  );
};

export default ProtectedRoute;
