import React from "react";
import { Field, FieldArray } from "formik";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export function EducationDetailsForm({ values, setFieldValue }) {
  return (
    <div>
      <FieldArray name="education">
        {(arrayHelpers) => (
          <div>
            {values.education
              .sort((a, b) => new Date(b.end_date) - new Date(a.end_date))
              .map((education, index) => (
                <div
                  key={index}
                  className="mb-15"
                  style={{
                    display: "list-item",
                    listStyle: "decimal",
                    fontSize: "1.2rem",
                  }}
                >
                  <div className="form-group row">
                    <div className="col-lg-4 mb-5">
                      <Field
                        name={`education[${index}].organization`}
                        placeholder="College Name"
                        component="input"
                        type="text"
                        className="form-control"
                        onChange={(event) => {
                          setFieldValue(
                            `education[${index}].organization`,
                            event.target.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-lg-4 mb-5">
                      <Field
                        name={`education[${index}].name`}
                        placeholder="Degree Name"
                        component="input"
                        type="text"
                        className="form-control"
                        onChange={(event) => {
                          setFieldValue(
                            `education[${index}].name`,
                            event.target.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-lg-1 mb-5">
                      <button
                        type="button"
                        onClick={() => arrayHelpers.remove(index)}
                        className="btn btn-icon  btn-sm"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Trash.svg"
                            )}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="form-group row">
                    {/* <div className="col-lg-3">
                    <label>Start Date :</label>
                    <Field
                      name={`education[${index}].startDate`}
                      placeholder="Start Date"
                      component="input"
                      type="date"
                      className="form-control"
                      onChange={(event) => {
                        setFieldValue(
                          `education[${index}].startDate`,
                          event.target.value
                        );
                      }}
                    />
                  </div> */}
                    <div className="col-lg-3 mb-5">
                      <label>End Date :</label>
                      <Field
                        name={`education[${index}].end_date`}
                        placeholder="End Date"
                        component="input"
                        type="date"
                        className="form-control"
                        onChange={(event) => {
                          setFieldValue(
                            `education[${index}].end_date`,
                            event.target.value
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}

            <div className="form-group row">
              <div className="col-lg-12 mb-5">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() =>
                    arrayHelpers.push({
                      organization: "",
                      name: "",
                      startDate: "",
                      end_date: "",
                    })
                  }
                >
                  Add Education
                </button>
              </div>
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
}
