import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Error,
  LoadingDialog,
} from "../../../../_metronic/_partials/controls";
import StepperAndButtons from "./StepperAndButtons";
import FormQuestion from "./FormQuestion";
import FormOption from "./FormOption";
import { actions } from "../_redux/buildTeam/buildTeamRedux";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import "./styles.scss";
import axios from "axios";

import { App_Urls } from "../../../Urls/urlConfig";

const Questionnaire = () => {
  const { userId } = useSelector(
    (state) => ({
      userId: state.auth?.user?.id,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [nextButtonState, setNextButtonState] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleNext = async () => {
    try {
      // Prepare the data for the API request
      setIsLoading(true);
      const requestData = {
        user_token: userId,
        selected_data: selectedOptions
          .filter((option) => option.questionId === questions[activeStep]?.id)
          .map((option) => ({
            question_id: option.questionId,
            selected_option_id: option.id,
          })),
      };

      // Send POST request to the API endpoint
      const response = await axios.post(
        App_Urls.QUESTIONNAIRE_SAVE_RESPONSE,
        requestData,
        {
          server2: true,
        }
      );

      const saveResponse = await response.data;
      // setQuestions((prev) => [...prev, saveResponse]);
      const activeQuestionIndex = questions.findIndex(
        (question) => question === questions[activeStep]
      );

      setQuestions((prev) => [
        ...prev.slice(0, activeQuestionIndex + 1),
        saveResponse,
      ]);
    } catch (error) {
      setErrorMessage(error.message);
      console.error("Error submitting form:", error);
    } finally {
      setIsLoading(false);
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleOptionChange = (option) => {
    const isMultiSelect = questions[activeStep].attributes?.multi_select;

    if (!isMultiSelect) {
      // If not multi-select, clear the selected options array
      const notMultiSelect = selectedOptions.filter(
        (selectedOption) => selectedOption.questionId !== option.questionId
      );
      setSelectedOptions([...notMultiSelect, option]);
    } else {
      // If multi-select, update the selected options array
      const index = selectedOptions.findIndex(
        (selectedOption) =>
          selectedOption.questionId === option.questionId &&
          selectedOption.id === option.id
      );

      if (index !== -1) {
        // Remove if already selected
        setSelectedOptions((prevOptions) => [
          ...prevOptions.slice(0, index),
          ...prevOptions.slice(index + 1),
        ]);
      } else {
        // Add if not selected
        setSelectedOptions((prevOptions) => [...prevOptions, option]);
      }
    }
  };

  useEffect(() => {
    // Check if any option is selected for the current question
    const isAnyOptionSelected = selectedOptions.some(
      (selectedOption) =>
        selectedOption.questionId === questions[activeStep]?.id
    );

    // Update nextButtonState based on whether any option is selected
    setNextButtonState(!isAnyOptionSelected);
  }, [selectedOptions, activeStep, questions]);

  const handleFormSubmit = async () => {
    // Check if it's the last question
    if (questions[activeStep].attributes?.last_question) {
      //api code for submit form
      try {
        const requestData = {
          user_token: userId,
        };

        // Send POST request to the API endpoint
        const response = await axios.post(
          App_Urls.QUESTIONNAIRE_SUBMIT,
          requestData,
          {
            server2: true,
          }
        );

        const submitData = await response.data
          .sort((a, b) => b.match_percent - a.match_percent)
          .map((stack, idx) => (stack = { ...stack, id: idx + 1 }));

        dispatch(actions.UpdateServerResponse(submitData));

        history.push("/build-team/recommended-stack");
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    } else {
      handleNext();
    }
  };

  const fetchQuestionsData = async () => {
    try {
      setIsLoading(true);
      const startEndpoint = App_Urls.QUESTIONNAIRE_START;
      const response = await axios.get(startEndpoint, {
        server2: true,
      });

      const data = await response.data;
      setQuestions([data]);
    } catch (error) {
      setErrorMessage(error.message);
      console.error("Error fetching questions:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchQuestionsData();
  }, []);

  return (
    <Card
      style={{ height: "90vh", boxSizing: "border-box" }}
      className="questionnaire"
    >
      <CardBody
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "100%",
        }}
      >
        {errorMessage ? (
          <Error message={errorMessage} />
        ) : isLoading ? (
          <LoadingDialog isLoading={isLoading} text="Please wait" />
        ) : (
          <>
            <StepperAndButtons
              activeStep={activeStep}
              questions={questions}
              handleBack={handleBack}
              nextButtonState={nextButtonState}
              handleFormSubmit={handleFormSubmit}
            />

            {activeStep < questions.length && (
              <div>
                <FormQuestion questions={questions} activeStep={activeStep} />

                <FormOption
                  questions={questions}
                  selectedOptions={selectedOptions}
                  activeStep={activeStep}
                  handleOptionChange={handleOptionChange}
                />
              </div>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default Questionnaire;
