import axios from "axios";
import { App_Urls } from "../../../../Urls/urlConfig";

export const PERMISSIONS_URL = App_Urls.PERMISSIONS_URL;

// CREATE OR UPDATE=>  PUT: upsert a permission to the server
export function upsertPermission(permission) {
  if (!permission.id) {
    return axios.post(PERMISSIONS_URL, {
      name: permission.name,
      active: permission.active,
    });
  } else {
    if (!permission.deleteData) {
      return axios.put(`${PERMISSIONS_URL}/${permission.id}`, {
        name: permission.name,
        active: permission.active,
      });
    } else {
      return axios.delete(`${PERMISSIONS_URL}/${permission.id}`, {
        name: permission.name,
        active: permission.active,
      });
    }
  }
}

// READ
export function getAllPermissions(queryParams) {
  return axios.get(PERMISSIONS_URL, {
    params: {
      search: queryParams.filter.search,
      limit: queryParams.pageSize,
      offset:
        queryParams.pageNumber &&
        (queryParams.pageNumber - 1) * queryParams.pageSize,
    },
  });
}

export function getPermissionById(permissionId) {
  return axios.get(`${PERMISSIONS_URL}/${permissionId}`);
}
