import { call, put, takeLatest } from "redux-saga/effects";

import * as requestFromServer from "./JobRolesCrud";
import { actionTypes, callTypes, actions } from "./jobRolesRedux";

function* handleUpsertJobRole(action) {
  try {
    yield put(actions.startCall(callTypes.action));
    const response = yield call(
      requestFromServer.upsertJobRole,
      action.payload.jobRole
    );
    const { job_role } = response.data;

    if (action.payload.jobRole.id) {
      yield put(actions.jobRoleUpdated(job_role));
    } else {
      yield put(actions.jobRoleCreated(job_role, action.payload.pageSize));
    }
    // Closes the Edit Modal
    action.payload.onHide();
  } catch (error) {
    error.clientMessage = "Can't create Job Role";
    yield put(actions.catchError(error, callTypes.action));
  }
}

function* handleFetchJobRoles(action) {
  try {
    yield put(actions.startCall(callTypes.list));
    const response = yield call(
      requestFromServer.getAllJobRoles,
      action.payload.queryParams
    );
    const { job_roles } = response.data;
    yield put(actions.jobRolesFetched(job_roles.count, job_roles.rows));
  } catch (error) {
    error.clientMessage = "Can't find job roles";
    yield put(actions.catchError(error, callTypes.list));
  }
}

function* handleFetchJobRoleById(action) {
  try {
    if (!action.payload.jobRoleId) yield put(actions.jobRoleFetched());
    else {
      yield put(actions.startCall(callTypes.action));
      const response = yield call(
        requestFromServer.getJobRoleById,
        action.payload.jobRoleId
      );
      const { job_role } = response.data;
      yield put(actions.jobRoleFetched(job_role));
    }
  } catch (error) {
    error.clientMessage = "Can't find job role";
    yield put(actions.catchError(error, callTypes.action));
  }
}

function* handleDeleteJobRole(action) {
  try {
    yield put(actions.startCall(callTypes.action));
    yield call(requestFromServer.upsertJobRole, {
      id: action.payload.jobRoleId,
      active: action.payload.active,
      deleteData:true,
    });
    yield put(actions.jobRoleDeleted(action.payload.jobRoleId));
    action.payload.handlePostDelete();
  } catch (error) {
    error.clientMessage = "Can't delete job role";
    yield put(actions.catchError(error, callTypes.action));
  }
}

export function* saga() {
  yield takeLatest(actionTypes.UpsertJobRole, handleUpsertJobRole);
  yield takeLatest(actionTypes.FetchJobRoles, handleFetchJobRoles);
  yield takeLatest(actionTypes.FetchJobRole, handleFetchJobRoleById);
  yield takeLatest(actionTypes.DeleteJobRole, handleDeleteJobRole);
}
