import axios from "axios";
import { App_Urls } from "../../../../Urls/urlConfig";

const SKILLS_URL = App_Urls.SKILLS_URL;

// CREATE /UPDATE /DELETE
export function upsertSkill(skill) {
  if (!skill.id) {
    return axios.post(SKILLS_URL, {
      name: skill.name,
      skill_variants: skill.variants,
      active: skill.active,
    });
  } else {
    if (!skill.deleteData) {
      return axios.put(`${SKILLS_URL}/${skill.id}`, {
        name: skill.name,
        skill_variants: skill.variants,
        active: skill.active,
      });
    } else {
      return axios.delete(`${SKILLS_URL}/${skill.id}`, {
        name: skill.name,
        skill_variants: skill.variants,
        active: skill.active,
      });
    }
  }
}

// READ
export function getAllSkills(queryParams) {
  return axios.get(SKILLS_URL, {
    params: {
      search: queryParams.filter.search,
      limit: queryParams.pageSize,
      offset:
        queryParams.pageNumber &&
        (queryParams.pageNumber - 1) * queryParams.pageSize,
    },
  });
}

export function getSkillById(skillId) {
  return axios.get(`${SKILLS_URL}/${skillId}`);
}

export function uploadSkillUsingExcelFile(file) {
  const formData = new FormData();
  formData.append("inputFile", file);

  return axios.post(`${SKILLS_URL}/bulk`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
