import { call, put, takeLatest } from "redux-saga/effects";

import * as requestFromServer from "./skillsCrud";
import { actionTypes, callTypes, actions } from "./skillsRedux";

function* handleUpsertSkill(action) {
  try {
    yield put(actions.startCall(callTypes.action));
    const response = yield call(
      requestFromServer.upsertSkill,
      action.payload.skill
    );
    const { skill } = response.data;

    if (action.payload.skill.id) {
      yield put(actions.skillUpdated(skill));
    } else {
      yield put(actions.skillCreated(skill, action.payload.pageSize));
    }
    // Closes the Edit Modal
    action.payload.onHide();
  } catch (error) {
    error.clientMessage = "Can't create skill";
    yield put(actions.catchError(error, callTypes.action));
  }
}

function* handleFetchSkills(action) {
  try {
    yield put(actions.startCall(callTypes.list));
    const response = yield call(
      requestFromServer.getAllSkills,
      action.payload.queryParams
    );
    const { skills } = response.data;
    yield put(actions.skillsFetched(skills.count, skills.rows));
  } catch (error) {
    error.clientMessage = "Can't find skills";
    yield put(actions.catchError(error, callTypes.list));
  }
}

function* handleFetchSkillById(action) {
  try {
    if (!action.payload.skillId) yield put(actions.skillFetched());
    else {
      yield put(actions.startCall(callTypes.action));
      const response = yield call(
        requestFromServer.getSkillById,
        action.payload.skillId
      );
      const { skill } = response.data;
      yield put(actions.skillFetched(skill));
    }
  } catch (error) {
    error.clientMessage = "Can't find skill";
    yield put(actions.catchError(error, callTypes.action));
  }
}

function* handleDeleteSkill(action) {
  try {
    yield put(actions.startCall(callTypes.action));
    yield call(requestFromServer.upsertSkill, {
      id: action.payload.skillId,
      active: action.payload.active,
      deleteData:true,
    });
    yield put(actions.skillDeleted(action.payload.skillId));
    action.payload.handlePostDelete();
  } catch (error) {
    error.clientMessage = "Can't delete skill";
    yield put(actions.catchError(error, callTypes.action));
  }
}

function* handleUploadSkills(action) {
  try {
    yield put(actions.startCall(callTypes.action));
    yield call(
      requestFromServer.uploadSkillUsingExcelFile,
      action.payload.file
    );
    yield put(actions.skillsUploaded());
    action.payload.handlePostUpload();
  } catch (error) {
    error.clientMessage = "Can't upload skills";
    yield put(actions.catchError(error, callTypes.action));
  }
}

export function* saga() {
  yield takeLatest(actionTypes.UpsertSkill, handleUpsertSkill);
  yield takeLatest(actionTypes.FetchSkills, handleFetchSkills);
  yield takeLatest(actionTypes.FetchSkill, handleFetchSkillById);
  yield takeLatest(actionTypes.DeleteSkill, handleDeleteSkill);
  yield takeLatest(actionTypes.UploadSkills, handleUploadSkills);
}
