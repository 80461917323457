import { call, put, takeLatest } from "redux-saga/effects";

import * as requestFromServer from "./rolesCrud";
import { actionTypes, callTypes, actions } from "./rolesRedux";

function* handleUpsertRole(action) {
  try {
    yield put(actions.startCall(callTypes.action));
    const response = yield call(
      requestFromServer.upsertRole,
      action.payload.role
    );
    const { role } = response.data;

    if (action.payload.role.id) {
      yield put(actions.roleUpdated(role));
    } else {
      yield put(actions.roleCreated(role, action.payload.pageSize));
    }
    // Closes the Edit Modal
    action.payload.onHide();
  } catch (error) {
    error.clientMessage = error.response.data.message;
    yield put(actions.catchError(error, callTypes.action));
  }
}

function* handleFetchRoles(action) {
  try {
    yield put(actions.startCall(callTypes.list));
    const response = yield call(
      requestFromServer.getAllRoles,
      action.payload.queryParams
    );
    const { roles } = response.data;
    yield put(actions.rolesFetched(roles.count, roles.rows));
  } catch (error) {
    error.clientMessage = "Can't find roles";
    yield put(actions.catchError(error, callTypes.list));
  }
}

function* handleFetchRoleById(action) {
  try {
    if (!action.payload.roleId) yield put(actions.roleFetched());
    else {
      yield put(actions.startCall(callTypes.action));
      const response = yield call(
        requestFromServer.getRoleById,
        action.payload.roleId
      );
      
      const { role } = response.data;
      yield put(actions.roleFetched(role));
    }
  } catch (error) {
    error.clientMessage = "Can't find role";
    yield put(actions.catchError(error, callTypes.action));
  }
}

function* handleDeleteRole(action) {
  try {
    yield put(actions.startCall(callTypes.action));
    yield call(requestFromServer.upsertRole, {
      id: action.payload.roleId,
      active: action.payload.active,
      deleteData:true,
    });
    yield put(actions.roleDeleted(action.payload.roleId));
    action.payload.handlePostDelete();
  } catch (error) {
    error.clientMessage = "Can't delete role";
    yield put(actions.catchError(error, callTypes.action));
  }
}

function* handleUploadRoles(action) {
  try {
    yield put(actions.startCall(callTypes.action));
    yield call(requestFromServer.uploadRoleUsingExcelFile, action.payload.file);
    yield put(actions.rolesUploaded());
    action.payload.handlePostUpload();
  } catch (error) {
    error.clientMessage = "Can't upload roles";
    yield put(actions.catchError(error, callTypes.action));
  }
}

export function* saga() {
  yield takeLatest(actionTypes.UpsertRole, handleUpsertRole);
  yield takeLatest(actionTypes.FetchRoles, handleFetchRoles);
  yield takeLatest(actionTypes.FetchRole, handleFetchRoleById);
  yield takeLatest(actionTypes.DeleteRole, handleDeleteRole);
  yield takeLatest(actionTypes.UploadRoles, handleUploadRoles);
}
