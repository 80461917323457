import axios from "axios";
import { App_Urls } from "../../../../Urls/urlConfig";

const PROFILES_URL = App_Urls.PROFILES_URL;

export function getAllProfilesByTechStack(skill_ids) {
  return axios.get(PROFILES_URL, {
    params: {
      search: "",
      skill_ids: skill_ids,
      job_role_ids: [],
      exact_match: true,
      approved: true,
      available: "",
      max_resource_cost: null,
      is_deployed: false,
      limit: 10,
      offset: 0,
    },
  });
}
