import axios from "axios";
import { App_Urls } from "../../../../Urls/urlConfig";

const JOB_ROLES_URL = App_Urls.JOB_ROLES_URL;

// CREATE OR UPDATE=>  PUT: upsert a job role to the server
export function upsertJobRole(jobRole) {
 
  if (!jobRole.id) {
    return axios.post(JOB_ROLES_URL, {
      job_role_id: jobRole.id,
      name: jobRole.name,
      active: jobRole.active,
      skill_ids: jobRole.skills?.map((skill) => skill.id),
    });
  } else {
    if (!jobRole.deleteData) {
      return axios.put(`${JOB_ROLES_URL}/${jobRole.id}`, {
        name: jobRole.name,
        active: jobRole.active,
        skill_ids: jobRole.skills?.map((skill) => skill.id),
      });
    } else {
      return axios.delete(`${JOB_ROLES_URL}/${jobRole.id}`, {
        name: jobRole.name,
        active: jobRole.active,
        skill_ids: jobRole.skills?.map((skill) => skill.id),
      });
    }
  }
}

// READ
export function getAllJobRoles(queryParams) {
  return axios.get(JOB_ROLES_URL, {
    params: {
      search: queryParams.filter.search,
      limit: queryParams.pageSize,
      offset: (queryParams.pageNumber - 1) * queryParams.pageSize,
    },
  });
}

export function getJobRoleById(jobRoleId) {
  return axios.get(`${JOB_ROLES_URL}/${jobRoleId}`);
}
