import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Card, CardBody } from "../../../../../_metronic/_partials/controls";
import { NotAuthorizedMessage } from "../../../../../_metronic/_helpers";
import { ResumeFormPage } from "./ResumeFormPage";

export function ResumeCard() {
  const { allowedPages } = useSelector(
    (state) => ({
      allowedPages: state.auth.allowedPages,
    }),
    shallowEqual
  );

  return (
    <Card
      style={{
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      <CardBody>
        {allowedPages.resume_builder?.create ? (
          <>
            <ResumeFormPage />
          </>
        ) : (
          <NotAuthorizedMessage />
        )}
      </CardBody>
    </Card>
  );
}
