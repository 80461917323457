import { Field, FieldArray } from "formik";
import React from "react";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Checkbox } from "../../../../../../_metronic/_partials/controls";

export function ProfessionalExperienceForm({ values, setFieldValue }) {
  return (
    <div>
      <FieldArray name="work_experience">
        {(arrayHelpers) => (
          <div>
            {values.work_experience
              .sort((a, b) => new Date(b.end_date) - new Date(a.end_date))
              .map((experience, index) => (
                <div
                  key={index}
                  className="mb-20"
                  style={{
                    display: "list-item",
                    listStyle: "decimal",
                    fontSize: "1.2rem",
                  }}
                >
                  <div className="form-group row">
                    <div className="col-lg-4 mb-5">
                      <Field
                        name={`work_experience[${index}].job_title`}
                        placeholder="Job Title"
                        component="input"
                        type="text"
                        className="form-control"
                        onChange={(event) => {
                          setFieldValue(
                            `work_experience[${index}].job_title`,
                            event.target.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-lg-4 mb-5">
                      <Field
                        name={`work_experience[${index}].organization`}
                        placeholder="Organization"
                        component="input"
                        type="text"
                        className="form-control"
                        onChange={(event) => {
                          setFieldValue(
                            `work_experience[${index}].organization`,
                            event.target.value
                          );
                        }}
                      />
                    </div>

                    <div className="col-lg-1 mb-5">
                      <button
                        type="button"
                        onClick={() => arrayHelpers.remove(index)}
                        className="btn btn-icon  btn-sm"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Trash.svg"
                            )}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-4 mb-5">
                      <label>Experience Start Date :</label>
                      <Field
                        name={`work_experience[${index}].start_date`}
                        placeholder="Start Date"
                        component="input"
                        type="date"
                        className="form-control"
                        onChange={(event) => {
                          setFieldValue(
                            `work_experience[${index}].start_date`,
                            event.target.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label>Experience End Date :</label>
                      <Field
                        name={`work_experience[${index}].end_date`}
                        placeholder="End Date"
                        component="input"
                        type="date"
                        className="form-control"
                        onChange={(event) => {
                          if (experience.is_current) {
                            setFieldValue(
                              `work_experience[${index}].end_date`,
                              ` Present`
                            );
                          } else {
                            setFieldValue(
                              `work_experience[${index}].end_date`,
                              event.target.value
                            );
                          }
                        }}
                        disabled={
                          values.work_experience[index].currentCompany ||
                          experience.is_current
                        }
                      />
                    </div>
                    <div className="col-lg-1 mt-9">
                      <Checkbox
                        name={`work_experience[${index}].is_current`}
                        className="form-control"
                        isSelected={
                          values.work_experience[`${index}`].is_current
                        }
                        onChange={(event) => {
                          setFieldValue(
                            `work_experience[${index}].is_current`,
                            !values.work_experience[`${index}`].is_current
                          );
                          if (values.work_experience[`${index}`].is_current) {
                            setFieldValue(
                              `work_experience[${index}].end_date`,
                              "Till-Present"
                            );
                          }
                        }}
                      >
                        Current Company
                      </Checkbox>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-12 mb-5">
                      <ReactQuill
                        value={values.work_experience[index].description}
                        onChange={(value) =>
                          setFieldValue(
                            `work_experience[${index}].description`,
                            value
                          )
                        }
                        placeholder="Job Description"
                        style={{ height: "25rem" }}
                      />
                    </div>
                  </div>
                </div>
              ))}

            <div className="form-group row">
              <div className="col-lg-12">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => {
                    arrayHelpers.push({
                      job_title: "",
                      organization: "",
                      start_date: "",
                      end_date: "",
                      description: "",
                    });
                  }}
                >
                  Add Experience
                </button>
              </div>
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
}
