import React, { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { ProfilesFilter } from "./profiles-filter/ProfilesFilter";
import { useProfilesUIContext } from "./ProfilesUIContext";
import { ProfilesTable } from "./profiles-table/ProfilesTable";
import { NotAuthorizedMessage } from "../../../../../_metronic/_helpers";
import JoyrideTutorial from "../../../../../_metronic/_partials/controls/JoyrideTutorial";

export function ProfilesCard() {
  const { allowedPages, actionsLoading } = useSelector(
    (state) => ({
      allowedPages: state.auth.allowedPages,
      actionsLoading: state.profiles.actionsLoading,
    }),
    shallowEqual
  );

  const profilesUIContext = useProfilesUIContext();
  const profilesUIProps = useMemo(() => {
    return {
      queryParams: profilesUIContext.queryParams,
      newProfileButtonClick: profilesUIContext.newProfileButtonClick,
      exportProfilesButtonClick: profilesUIContext.exportProfilesButtonClick,
      uploadProfilesButtonClick: profilesUIContext.uploadProfilesButtonClick,
    };
  }, [profilesUIContext]);
  const steps = [
    {
      target: ".profile",
      content:
        "Welcome to  Profiles page , this page contains your list of profiles.",
      placement: "center",
      disableBeacon: true,
    },
    {
      target: ".profile-export",
      content: "To download profiles in excel file.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".profile-add",
      content: "You can add profile.",
      placement: "right",
      disableBeacon: true,
    },
    {
      target: ".profile-search",
      content: "You can search from the list of profiles using name or email.",
      placement: "right",
      disableBeacon: true,
    },
    {
      target: ".profile-skill",
      content: "You can search profiles by skills.",
      placement: "right",
      disableBeacon: true,
    },
    {
      target: ".profile-jobrole",
      content: "You can search profiles by job roles.",
      placement: "right",
      disableBeacon: true,
    },
    {
      target: ".profile-approved",
      content: "You can search profiles by approval.",
      placement: "right",
      disableBeacon: true,
    },
    {
      target: ".profile-deployed",
      content: "You can search profiles by deployment.",
      placement: "right",
      disableBeacon: true,
    },
    {
      target: ".profile-exact",
      content: "check box to search only with exact match of search text.",
      placement: "right",
      disableBeacon: true,
    },
    {
      target: ".data-view",
      content: "You can view details of profile",
      placement: "left",
      disableBeacon: true,
    },
    {
      target: ".data-download",
      content: "You can download resume of profile",
      placement: "left",
      disableBeacon: true,
    },
    {
      target: ".data-resume",
      content: "You can view resume of profile",
      placement: "left",
      disableBeacon: true,
    },
  ];
  const redirectRoute = "/access/roles";
  return (
    <>
      <Card className="profile">
        <CardHeader title="Profiles list">
          <CardHeaderToolbar>
            {allowedPages.profile?.read && (
              <button
                type="button"
                className="btn btn-outline-success mr-5 mb-2 px-10 profile-export"
                onClick={() =>
                  profilesUIProps.exportProfilesButtonClick(
                    profilesUIProps.queryParams
                  )
                }
                disabled={actionsLoading}
              >
                {actionsLoading ? "Exporting..." : "Export Profiles"}
              </button>
            )}

            {allowedPages.profile?.create && (
              <>
                {/* <button
                  type="button"
                  className="btn btn-outline-info mr-2 mb-2"
                  onClick={profilesUIProps.uploadProfilesButtonClick}
                >
                  Upload from drive
                </button> */}
                <button
                  type="button"
                  className="btn btn-outline-primary mb-2 profile-add"
                  onClick={profilesUIProps.newProfileButtonClick}
                >
                  Create Profile
                </button>
              </>
            )}
          </CardHeaderToolbar>
        </CardHeader>

        <CardBody>
          {allowedPages.profile?.read ? (
            <>
              <ProfilesFilter />
              <ProfilesTable />
              <JoyrideTutorial steps={steps} redirectRoute={redirectRoute}/>
            </>
          ) : (
            <NotAuthorizedMessage />
          )}
        </CardBody>
      </Card>
    </>
  );
}
