import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actions } from "./_redux/buildTeam/buildTeamRedux";
function TeamBuildHeader() {
  const history = useHistory();
  const dispatch = useDispatch();
  function handleExit() {
    dispatch(actions.ToggleBuildMode(false));
    history.push("/resume");
  }
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 5em",
      }}
    >
      <h3 style={{ fontWeight: "600", fontSize: "1.5em" }}>TeamBuilder</h3>
      <button
        className="btn btn-success"
        onClick={handleExit}
        style={{
          bottom: 0,
          left: 0,
          padding: "0.5em 2em",
          background: "#029863",
          fontWeight: 600,
          border: "none",
          fontSize: "1.2em",
          borderRadius: "2em",
        }}
      >
        Exit
      </button>
    </div>
  );
}

const BuildTeamLayout = ({ children }) => {
  return (
    <div style={{ width: "100%", height: "100vh", boxSizing: "border-box" }}>
      <div
        style={{
          height: "10%",
          background: "#fefdfd",
          padding: "0 1em",
          borderBottom: "2px solid #dcdbd3",
        }}
      >
        <TeamBuildHeader />
      </div>
      <div style={{ height: "90%" }}>{children}</div>
    </div>
  );
};

export default BuildTeamLayout;
