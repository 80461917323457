import React, { useState } from "react";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import JoyrideTutorial from "../../../_metronic/_partials/controls/JoyrideTutorial";
import GoogleDrive from "../Drive/GoogleDrive";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { shallowEqual, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Avatar } from "@material-ui/core";
import dayjs from "dayjs";
import "./usercard.css";

const UserCard = () => {
  const { primary_vendor, user, subscription } = useSelector(
    (state) => ({
      primary_vendor: state.auth.user?.is_primary_vendor,
      user: state.auth.user,
      subscription: state.auth?.subscription_end_time,
    }),
    shallowEqual
  );
  const [connect, setConnect] = useState(false);
  const formattedDate = dayjs(subscription).format("DD MMMM, YYYY");

  const steps = [
    {
      target: ".user-card",
      content:
        "Welcome to our app demo tour 🙌! we will guide you through its features and functionality.",
      placement: "center",
      disableBeacon: true,
    },
    {
      target: ".user-card-name",
      content: "Your registered name.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".user-card-email",
      content: "Your registered email.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".user-card-subscription",
      content: "Your Subscription validity.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".user-card-drive",
      content: "Please connect your drive by clicking on this button.",
      placement: "top",
      disableBeacon: true,
    },
  ];
  const redirectRoute = "/resume/skills";
  return (
    <Card
      style={{
        width: "100%",
        height: "auto",
        boxSizing: "border-box",
        color: "#2C3E50",
      }}
      className="user-card"
    >
      <CardBody
        style={{ display: "flex", gap: "2rem" }}
        className="user-card-body"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "30%",
            height: "30%",
            textAlign: "center",
            marginTop: "1rem",
          }}
          className="user-left"
        >
          <Avatar
            style={{
              margin: "auto",
              width: "5rem",
              height: "5rem",
              background: "#D35400",
            }}
            className="user-card-avatar"
          />
          <span
            style={{
              fontSize: "1.5rem",
              color: "#2C3E50",
              fontWeight: 500,
              textTransform: "capitalize",
            }}
            className="user-card-name"
          >
            {user.name}
          </span>
          <span
            style={{ fontSize: "1.2rem", color: "#566573" }}
            className="user-card-email"
          >
            {user.email}
          </span>
          {primary_vendor && (
            <span
              style={{ color: "#28B463", fontSize: "1rem", marginTop: "1rem" }}
              className="user-card-subscription"
            >{`Subscription upto  ${formattedDate}`}</span>
          )}
        </div>
        {primary_vendor && (
          <div
            style={{
              width: "60%",
              height: "auto",
              margin: "auto",
              borderLeft: "1px solid #ABB2B9",
              padding: "1rem 2rem",
              display: "flex",
              flexDirection: "column",
              rowGap: "0.5rem",
              justifyContent: "center",
              alignItems: "center",
              color: "#2C3E50",
            }}
            className="user-right"
          >
            <div>
              <h3 style={{ color: "#34495E", fontWeight: 500 }}>Disclosure:</h3>
              <p style={{ color: "#808B96" }}>
                At parser.muoro.io, we value your privacy and are committed to
                transparently handling your data. Our app utilizes Google Drive
                access to provide you with the convenience of bulk uploading
                resumes directly from your Google Drive to our secure database.
              </p>
              <p style={{ color: "#808B96" }}>
                {" "}
                parser.muoro.io's use and transfer of information received from
                Google APIs to any other app will adhere to{" "}
                <a
                  href="https://developers.google.com/terms/api-services-user-data-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google API Services User Data Policy
                </a>
                , including the{" "}
                <a
                  href="https://support.google.com/cloud/answer/9110914#explain-types"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Limited Use Requirements section
                </a>
                .
              </p>
              <p style={{ color: "#808B96" }}>
                Rest assured, any data received from Google APIs is used solely
                for the purpose of enhancing your experience with
                parser.muoro.io. We do not share or transfer your information to
                any other app or third party. Your trust is important to us, and
                we take all necessary measures to protect your data
              </p>
              <p style={{ color: "#808B96" }}>
                Should you have any inquiries or concerns about how we use
                Google Drive access or handle your data, please do not hesitate
                to contact us directly{" "}
                <a
                  href="mailto:developer@muoro.com"
                  style={{ fontWeight: "bold" }}
                >
                  developer@muoro.com
                </a>
                . We are here to address any questions you may have.
              </p>
            </div>
            <div
              style={{
                width: "60%",
                height: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                boxSizing: "border-box",
              }}
            >
              <h3 style={{ fontSize: "1.2rem", color: "#34495E" }}>
                {connect
                  ? "Your Google Drive is now connected!"
                  : "Connect Your Google Drive"}
              </h3>
              <span>
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/down-arrow.svg")}
                  width="25px"
                  height="25px"
                />
              </span>
              <GoogleDrive setConnect={setConnect} toast={toast} />
            </div>
          </div>
        )}
        <ToastContainer position="top-center" autoClose={5000} theme="light" />
        <JoyrideTutorial steps={steps} redirectRoute={redirectRoute} />
      </CardBody>
    </Card>
  );
};

export default UserCard;
