import React, { useEffect } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import './responsive.css'

const HomePage = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  
  return (
    <div
      style={{
        background: "#f6f4ff",
        boxSizing: "border-box",
      }}
    >
      {/* header section */}

      <div
        style={{
          width: "100%",
          height: "90px",
          background: "#f6f4ff",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "5px 85px",
          boxSizing: "border-box",
          position: "fixed",
          zIndex: "1000",
        }}
        className="header"
      >
        <img src={toAbsoluteUrl("/media/homepage/muoro-logo.svg")} alt="" width={"60px"} height={'auto'} />

        <Link
          to={{
            pathname: `/auth/login`,
          }}
          style={{
            borderRadius: "25px",
            background: "#3498DB",
            padding: "5px 25px",
            color: "white",
            fontSize: "15px",
            fontWeight: "500",
            textDecoration: "none",
          }}
        >
          Login
        </Link>
      </div>

      {/* Hero Section */}

      <section
        style={{
          width: "100%",
          height: "500px",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          padding: "0px 30px",
          paddingTop: "80px",
          boxSizing: "border-box",
          marginTop: "100px",
         
        }}
        className="hero"
      >
        {/* left */}
        <div
          style={{
            width: "50%",
            padding: "10px",
            boxSizing: "border-box",
            height: "100%",
            textAlign: "left",
           }}
          data-aos="fade-up"
          className="hero-left"
        >
          <h3
            style={{ color: "#32236f", fontSize: "20px", marginBottom: "0px" }}
          >
            Powerful, easier and faster
          </h3>
          <h1
            style={{
              color: "#6a49f2",
              fontSize: "46px",
              width: "80%",
              marginTop: "10px",
            }}
          >
            Make Your Recruitment More Efficient Through AI 
          </h1>
          <p
            style={{
              color: "#3e3f66",
              fontSize: "20px",
              width: "80%",
              marginTop: "15px",
            }}
          >
            Say goodbye to manual screening and embrace efficiency as you effortlessly match the perfect candidates for every role with the resume parser. Accelerate your hiring process and make data-driven decisions with ease!
          </p>
        </div>

        {/* right */}
        <div
          style={{
            width: "50%",
            padding: "20px",
            boxSizing: "border-box",
            height: "100%",
          }}
          className="hero-right"
        >
          <img
            src={toAbsoluteUrl("/media/homepage/new-profile.png")}
            alt=""
            width={"100%"}
            height={"80%"}
            style={{ border: "3px solid #6a49f2", borderRadius: "25px",animation: "moveUpDown 4s infinite", }}

          />
        </div>
      </section>

      {/* Features section */}

      <section
        style={{
          width: "100%",
          height: "800px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        data-aos="fade-up"
        className="feature"
      >
        <h2
          style={{ color: "#32236f", fontSize: "40px", marginBottom: "10px" }}
        >
          Features
        </h2>
        <p
          style={{
            color: "#3e3f66",
            fontSize: "17px",
            marginBottom: "50px",
            width: "90%",
            textAlign: "center",
          }}
        >
          Unleash the Future of Hiring: Effortless Resume Parsing, Instant Integration, and Enhanced Efficiency – All with Smart AI
        </p>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
          data-aos="fade-up"
          className="feature-card-wrapper"
        >
          <div
            style={{
              width: "28%",
              height: "500px",
              borderRadius: "20px",
              background: "#ffffff",
              display: "flex",
              padding: "10px",
              boxSizing: "border-box",
              flexDirection: "column",
              alignItems: "center",
            }}
            className="feature-card"
          >
            <img
              src={toAbsoluteUrl("/media/homepage/custom.svg")}
              alt=""
              width="80px"
              height="80px"
              style={{ paddingTop: "20px", marginBottom: "20px" }}
            />
            <p
              style={{ color: "#391e6c", fontSize: "24px", fontWeight: "500" }}
            >
              Highly Customized 
            </p>
            <p
              style={{
                color: "#3e3f66",
                width: "80%",
                margin: "0 auto",
                lineHeight: "30px",
                fontSize: "17px",
              }}
            >
              seamlessly integration with existing HR/Recruitment Apps, Google Sheets, and Excel! ur custom plugins and backend team ensure the tool is productive from day 1!
            </p>
          </div>
          <div
            style={{
              width: "28%",
              height: "500px",
              borderRadius: "20px",
              background: "#ffffff",
              display: "flex",
              padding: "10px",
              boxSizing: "border-box",
              flexDirection: "column",
              alignItems: "center",
            }}
            className="feature-card"
          >
            <img
              src={toAbsoluteUrl("/media/homepage/design.svg")}
              alt=""
              width="80px"
              height="80px"
              style={{ paddingTop: "20px", marginBottom: "20px" }}
            />
            <p
              style={{ color: "#391e6c", fontSize: "24px", fontWeight: "500" }}
            >
              Personalized Jobs 
            </p>
            <p
              style={{
                color: "#3e3f66",
                width: "80%",
                margin: "0 auto",
                lineHeight: "30px",
                fontSize: "17px",
              }}
            >
              Create and manage job roles that you think are regularly in demand by your business team
            </p>
          </div>
          <div
            style={{
              width: "28%",
              height: "500px",
              borderRadius: "20px",
              background: "#ffffff",
              display: "flex",
              padding: "10px",
              boxSizing: "border-box",
              flexDirection: "column",
              alignItems: "center",
            }}
            className="feature-card"
          >
            <img
              src={toAbsoluteUrl("/media/homepage/new-ai.jpeg")}
              alt=""
              width="100px"
              height="auto"
              style={{ paddingTop: "0px",  }}
            />
            <p
              style={{ color: "#391e6c", fontSize: "24px", fontWeight: "500" }}
            >
              Ai Integration
            </p>
            <p
              style={{
                color: "#3e3f66",
                width: "80%",
                margin: "0 auto",
                lineHeight: "30px",
                fontSize: "17px",
              }}
            >
              Artificial Intelligence (AI), our resume parser dashboard takes
              resume processing to new heights. With intelligent algorithms at
              its core, it swiftly extracts and analyzes key information from
              resumes, empowering you to make data-driven decisions in a
              fraction of the time.
            </p>
          </div>
        </div>
      </section>

      {/* Ai tool section */}

      <section
        style={{
          width: "100%",
          height: "800px",
          display: "flex",
          justifyContent: "space-around",
          padding: "20px",
        }}
        data-aos="fade-in"
        className="ai-section"
      >
        <div
          style={{
            width: "50%",
            height: "100%",
            padding: "20px",
            boxSizing: "border-box",
          }}
          className="ai-left"
        >
          <div style={{ width: "70%", paddingLeft: "10px" }} className="ai-left-section">
            <span>
              <img
                src={toAbsoluteUrl("/media/homepage/Analyze_Icon.svg")}
                alt=""
              />
            </span>
            <h2
              style={{ color: "#32236f", fontSize: "40px", marginTop: "30px" }}
            >
              Analyze your data with powerful AI tool
            </h2>
            <p style={{ color: "#3e3f66", fontSize: "20px" }}>
              Analyze Resume quickly and efficiently with our AI tool against
              the job description for better decision making .
            </p>
          </div>

          <ul>
            <li style={{ listStyleType: "none" }} data-aos="fade-up">
              <h3
                style={{
                  color: "#391e6c",
                  fontSize: "24px",
                  fontWeight: "500",
                }}
              >
                {" "}
                Enhanced Candidate Evaluation
              </h3>
              <p style={{ color: "#3e3f66", fontSize: "20px" }}>
                Our powerful AI tool for resume parsing, extracts and analyzes
                key information from resumes, enabling you to make data-driven
                decisions and find the perfect candidates quickly.
              </p>
            </li>
            <li style={{ listStyleType: "none" }} data-aos="fade-up">
              <h3
                style={{
                  color: "#391e6c",
                  fontSize: "24px",
                  fontWeight: "500",
                }}
              >
                Time and Cost Efficiency
              </h3>
              <p style={{ color: "#3e3f66", fontSize: "20px" }}>
                AI-powered resume parsing dramatically reduces the time spent on
                manually screening resumes, allowing you to process a large
                volume of applications swiftly.
              </p>
            </li>
          </ul>
        </div>

        <div style={{ width: "50%", height: "100%" }}  className="ai-right">
          <img
            src={toAbsoluteUrl("/media/homepage/ai_feedback.png")}
            alt=""
            width={"90%"}
            height={"25%"}
            style={{
              marginTop: "100px",
              paddingLeft: "20px",
              marginBottom: "-15px",
              animation: "moveUpDown 4s infinite",
            }}
            data-aos="fade-up"
          />
          <img
            src={toAbsoluteUrl("/media/homepage/ai_feedback.png")}
            alt=""
            width={"90%"}
            height={"25%"}
            style={{ paddingLeft: "35px", marginBottom: "-15px" }}
            data-aos="fade-up"
          />
          <img
            src={toAbsoluteUrl("/media/homepage/ai_feedback.png")}
            alt=""
            width={"90%"}
            height={"25%"}
            style={{ paddingLeft: "50px",animation: "moveDownUp 4s infinite", }}
            data-aos="fade-up"
          />
        </div>
      </section>

      {/* security section */}

      <section
        style={{
          width: "100%",
          height: "600px",
          display: "flex",
          justifyContent: "space-around",
          padding: "20px",
          marginBottom:'20px !important'
        }}
        data-aos="fade-up"
        className="security"
      >
        <div style={{ width: "50%", height: "100%" }} data-aos="fade-in" className="security-left">
          <img
            src={toAbsoluteUrl("/media/homepage/security.jpg")}
            alt=""
            width="80%"
            height="auto"
            style={{
              marginLeft: "10px",
              border: "3px solid #6a49f2",
              borderRadius: "25px",
              marginTop: "30px",
            }}
          />
        </div>

        <div style={{ width: "50%", height: "100%" }} className="security-right">
          <h2
            style={{ color: "#32236f", fontSize: "40px", marginTop: "50px" }}
            data-aos="fade-in"
          >
            Manage your profiles data securely
          </h2>
          <p style={{ color: "#3e3f66", fontSize: "20px" }}>
            We take the security of your data very seriously. Your resume and
            other personal information is stored in a secure database and is
            only accessible to authorized users. We use industry-standard
            encryption to protect your data from unauthorized access.
          </p>

          <ul>
            <li style={{ listStyleType: "none" }} data-aos="fade-up">
              <h3 style={{ color: "#391e6c", fontSize: "24px" }}>
                Secure Sync
              </h3>
              <p style={{ color: "#3e3f66", fontSize: "20px" }}>
              Our Resume parser can be synced with multiple third-party applications. We make sure to provide a seamless plug-and-play environment to make your recruitment smarter and more effective and provide you with the utmost data security
              </p>
            </li>
          </ul>
        </div>
      </section>

      {/* Advanced Features section */}

      <section
        style={{
          width: "95%",
          margin: "0 auto",
          height: "800px",
          border: "1px solid",
          borderRadius: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "#6a49f2",
        }}
        data-aos="fade-in"
        className="advanced"
      >
        <h2 style={{ fontSize: "40px", color: "#fff", margin: "30px 0" }}>
          Advanced Features
        </h2>
        <div
          style={{
            width: "100%",
            height: "50%",
            display: "flex",
            justifyContent: "space-around",
          }}
          data-aos="fade-up"
          className="advanced-row"
        >
          <div
            style={{
              width: "40%",
              height: "80%",
              borderRadius: "25px",
              color: "white",
              border: "1px solid",
              display: "flex",
              background: "#fff",
              justifyContent: "space-around",
            }}
            className="advanced-card"
          >
            <div
              style={{
                width: "20%",
                height: "100%",
                padding: "5px",
                boxSizing: "border-box",
                marginTop: "30px",
              }}
            >
              <img
                src={toAbsoluteUrl("/media/homepage/search.svg")}
                alt=""
                width={"100%"}
                height={"auto"}
              />
            </div>

            <div
              style={{
                width: "70%",
                height: "100%",
                padding: "15px",
                boxSizing: "border-box",
              }}
             
            >
              <h2
                style={{
                  color: "#391e6c",
                  fontSize: "20px",
                  marginBottom: "20px",
                }}
              >
                Powerful Search
              </h2>
              <p
                style={{
                  color: "#3e3f66",
                  fontSize: "17px",
                  lineHeight: "30px",
                }}
                className="advanced-card-para"
              >
                Our powerful search allows users to search for resumes by name,
                skills, job title, approved status, deployed status, and exact
                match ,easy to find the resumes
              </p>
            </div>
          </div>
          <div
            style={{
              width: "40%",
              height: "80%",
              borderRadius: "25px",
              color: "white",
              border: "1px solid",
              display: "flex",
              background: "#fff",
              justifyContent: "space-around",
            }}
            className="advanced-card"
          >
            <div
              style={{
                width: "20%",
                height: "100%",
                padding: "5px",
                boxSizing: "border-box",
                marginTop: "30px",
              }}
            >
              <img
                src={toAbsoluteUrl("/media/homepage/settings.svg")}
                alt=""
                width={"100%"}
                height={"auto"}
              />
            </div>

            <div
              style={{
                width: "70%",
                height: "100%",
                padding: "15px",
                boxSizing: "border-box",
              }}
            >
              <h2
                style={{
                  color: "#391e6c",
                  fontSize: "20px",
                  marginBottom: "20px",
                }}
              >
                Dynamic Permissions
              </h2>
              <p
                style={{
                  color: "#3e3f66",
                  fontSize: "17px",
                  lineHeight: "30px",
                }}
                className="advanced-card-para"
              >
                You have the ability to create roles with different permissions,
                roles that have access to all of the features, or that have
                access to only a select few features
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "50%",
            display: "flex",
            justifyContent: "space-around",
          }}
          data-aos="fade-up"
          className="advanced-row"
        >
          <div
            style={{
              width: "40%",
              height: "80%",
              borderRadius: "25px",
              color: "white",
              border: "1px solid",
              display: "flex",
              background: "#fff",
              justifyContent: "space-around",
            }}
            className="advanced-card"
          >
            <div
              style={{
                width: "20%",
                height: "100%",
                padding: "5px",
                boxSizing: "border-box",
                marginTop: "30px",
              }}
            >
              <img
                src={toAbsoluteUrl("/media/homepage/multiple users.svg")}
                alt=""
                width={"100%"}
                height={"auto"}
              />
            </div>

            <div
              style={{
                width: "70%",
                height: "100%",
                padding: "15px",
                boxSizing: "border-box",
              }}
            >
              <h2
                style={{
                  color: "#391e6c",
                  fontSize: "20px",
                  marginBottom: "20px",
                }}
              >
                Unlock Unlimited Job Roles and Skills
              </h2>
              <p
                style={{
                  color: "#3e3f66",
                  fontSize: "17px",
                  lineHeight: "30px",
                }}
                className="advanced-card-para"
              >
                Empower Your Hiring Process by creating custom databases of essential Skillsets and discover perfectly matched candidates instantly!
              </p>
            </div>
          </div>
          <div
            style={{
              width: "40%",
              height: "80%",
              borderRadius: "25px",
              color: "white",
              border: "1px solid",
              display: "flex",
              background: "#fff",
              justifyContent: "space-around",
            }}
            className="advanced-card"
          >
            <div
              style={{
                width: "20%",
                height: "100%",
                padding: "5px",
                boxSizing: "border-box",
                marginTop: "30px",
              }}
            >
              <img
                src={toAbsoluteUrl("/media/homepage/export.svg")}
                alt=""
                width={"100%"}
                height={"auto"}
              />
            </div>

            <div
              style={{
                width: "70%",
                height: "100%",
                padding: "15px",
                boxSizing: "border-box",
              }}
            >
              <h2
                style={{
                  color: "#391e6c",
                  fontSize: "20px",
                  marginBottom: "20px",
                }}
              >
                Export Profiles
              </h2>
              <p
                style={{
                  color: "#3e3f66",
                  fontSize: "17px",
                  lineHeight: "30px",
                }}
                className="advanced-card-para"
              >
                By using our powerful search you can fetch profiles from the
                pool of your profiles data and easily download them by using our
                export profiles feature for the ease of sharing
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Easy integration */}

      <section
        style={{
          width: "100%",
          height: "400px",

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          textAlign: "center",
        }}
        data-aos="fade-up"
        className="easy-integration"
      >
        <h2
          style={{
            color: "#32236f",
            fontSize: "40px",
            marginBottom: "15px",
            marginTop: "20px",
          }}
        >
          Easy integration
        </h2>
        <p
          style={{
            fontSize: "20px",
            color: "#3e3f66",
            width: "60%",
            lineHeight: "30px",
          }}
        >
          Integration with Google Drive offers our users a seamless and
          efficient way to manage their profile data,Users can easily upload
          their profile data in bulk using the Google Drive
        </p>
        <div
          style={{
            width: "30%",
            background: "#ffffff",
            height: "150px",
            borderRadius: "25px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "15px",
          }}
          data-aos="fade-up"
          className="easy-integration-drive"
        >
          <img
            src={toAbsoluteUrl("/media/homepage/platform.png")}
            alt=""
            width={"20%"}
            height={"auto"}
          />
          <h3 style={{ color: "#3e3f66", fontSize: "20px", marginTop: "15px" }}>
            Google Drive
          </h3>
        </div>
      </section>

      {/* interface overview */}

      <section
        style={{
          width: "100%",
          height: "900px",

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        data-aos="fade-up"
        className="interface"
      >
        <h2 style={{ color: "#32236f", fontSize: "40px", marginBottom: "5px" }}>
          Interface overview
        </h2>
        <p style={{ color: "#3e3f66", fontSize: "20px" }}>
          A small glimpse of our dashboard interface.
        </p>
        <div
          style={{
            width: "100%",
            height: "70%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <img
            src={toAbsoluteUrl("/media/homepage/new-profile.png")}
            alt=""
            width="90%"
            height="auto"
            style={{ border: "3px solid #6a49f2", borderRadius: "25px" }}
          />
        </div>
      </section>

      {/* contact us section */}
      <section
        style={{
          width: "100%",
          height: "150px",
          borderRadius: "25px 25px 0 0",
          background: "#583bd3",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
        data-aos="fade-up"
        className="need-support"
      >
        <h2 style={{ fontSize: "40px", color: "#fff" }}>
          Need support ? contact our team
        </h2>
        <span
          style={{
            background: "#ff923d",
            width: "22%",
            borderRadius: "25px",
            padding: "10px 35px",
            textAlign: "center",
          }}
        >
          <a
            href="mailto:developer@muoro.com"
            style={{
              textDecoration: "none",
              color: "#fff",
              fontSize: "17px",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            developer@muoro.com
          </a>
        </span>
      </section>

      {/* Footer section */}

      <footer
        style={{
          width: "100%",
          height: "600px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "#583bd3",
        }}
        
      >
        <section
          style={{
            width: "100%",
            height: "90%",

            display: "flex",
            justifyContent: "space-around",
          }}
          className="footer-section"
        >
          <div
            style={{
              width: "50%",
              height: "100%",
              padding: "45px",
              boxSizing: "border-box",
            }}
          >
            <img
              src={toAbsoluteUrl("/media/homepage/Muoro_footer.png")}
              alt=""
              width={"30%"}
              height={"auto"}
              style={{ marginBottom: "30px" }}
            />
            <p
              style={{
                color: "#fff",
                fontSize: "17px",
                lineHeight: "30px",
                width: "60%",
              }}
            >
              Build remote engineering teams with Muoro providing dedicated
              remote software engineer & development teams, offshore engineers
              and hire remote software developers.
            </p>
          </div>

          <div
            style={{
              width: "50%",
              height: "100%",
              padding: "45px",
              boxSizing: "border-box",
              textAlign: "center",
            }}
          >
            <h3
              style={{ color: "#fff", fontSize: "24px", marginBottom: "50px" }}
            >
              Help &amp; Suport
            </h3>
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <li
                style={{
                  listStyle: "none",
                  color: "#fff",
                  fontSize: "17px",
                  marginBottom: "30px",
                }}
              >
                <a
                  href="https://www.muoro.io/contact"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  Contact us
                </a>
              </li>
              <li
                style={{
                  listStyle: "none",
                  color: "#fff",
                  fontSize: "17px",
                  marginBottom: "30px",
                }}
              >
                <a
                  href="https://muoro.io/terms-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  Terms & conditions
                </a>
              </li>
              <li
                style={{
                  listStyle: "none",
                  color: "#fff",
                  fontSize: "17px",
                  marginBottom: "30px",
                }}
              >
                <a
                  href="https://muoro.io/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  Privacy policy
                </a>
              </li>
            </ul>
          </div>
        </section>
        <div
          style={{
            width: "100%",
            height: "10%",
            borderTop: "1px solid #3e3f66",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p style={{ fontSize: "17px", color: "#fff" }}>
            © Muoro 2023. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
