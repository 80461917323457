import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Card } from "../../../../../../_metronic/_partials/controls";
import { actions } from "../../../_redux/profiles";
import { ProfileEditForm } from "./ProfileEditForm";
import { useProfilesUIContext } from "../ProfilesUIContext";
import { NotAuthorizedMessage } from "../../../../../../_metronic/_helpers";

export function ProfileEditDialog({ id, isEdit = true, show, onHide }) {
  // Profiles UI Context
  const profilesUIContext = useProfilesUIContext();
  const profilesUIProps = useMemo(() => {
    return {
      queryParams: profilesUIContext.queryParams,
      initProfile: profilesUIContext.initProfile,
    };
  }, [profilesUIContext]);

  // Profiles Redux state
  const dispatch = useDispatch();
  const { actionsLoading, profileForEdit, allowedPages } = useSelector(
    (state) => ({
      actionsLoading: state.profiles.actionsLoading,
      profileForEdit: state.profiles.profileForEdit,
      allowedPages: state.auth.allowedPages,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Profile by id
    dispatch(actions.fetchProfile(id));
  }, [id, dispatch]);

  // server request for saving profile
  const saveProfile = (profile) => {
    dispatch(actions.upsertProfile(profile, profilesUIProps.queryParams));
  };
  
  return (
    <Card
      style={{
        height: "auto",
        boxSizing: "border-box",
      }}
    >
      {(isEdit && allowedPages.profile?.create) ||
      (!isEdit && allowedPages.profile?.read) ? (
        <>
          {/* <ProfileEditDialogHeader id={id} isEdit={isEdit} onHide={onHide} /> */}
          <ProfileEditForm
            saveProfile={saveProfile}
            actionsLoading={actionsLoading}
            profile={{ ...profilesUIProps.initProfile, ...profileForEdit }}
            onHide={onHide}
            isEdit={isEdit}
            id={id}
          />
        </>
      ) : (
        <NotAuthorizedMessage />
      )}
    </Card>
  );
}
