import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { actions } from "../../../_redux/skills";
import { SkillEditDialogHeader } from "./SkillEditDialogHeader";
import { SkillEditForm } from "./SkillEditForm";
import { useSkillsUIContext } from "../SkillsUIContext";
import { NotAuthorizedMessage } from "../../../../../../_metronic/_helpers";

export function SkillEditDialog({ id, isEdit = true, show, onHide }) {
  // Skills UI Context
  const skillsUIContext = useSkillsUIContext();
  const skillsUIProps = useMemo(() => {
    return {
      queryParams: skillsUIContext.queryParams,
      initSkill: skillsUIContext.initSkill,
    };
  }, [skillsUIContext]);

  // Skills Redux state
  const dispatch = useDispatch();
  const { actionsLoading, skillForEdit, allowedPages } = useSelector(
    (state) => ({
      actionsLoading: state.skills.actionsLoading,
      skillForEdit: state.skills.skillForEdit,
      allowedPages: state.auth.allowedPages,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Skill by id
    dispatch(actions.fetchSkill(id));
  }, [id, dispatch]);

  // server request for saving skill
  const saveSkill = (skill) => {
    dispatch(
      actions.upsertSkill(skill, skillsUIProps.queryParams.pageSize, onHide)
    );
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {(isEdit && (allowedPages.skill?.create || allowedPages.skill?.update)) ||
      (!isEdit && allowedPages.skill?.read) ? (
        <>
          <SkillEditDialogHeader id={id} isEdit={isEdit} onHide={onHide} />
          <SkillEditForm
            saveSkill={saveSkill}
            actionsLoading={actionsLoading}
            skill={skillForEdit || skillsUIProps.initSkill}
            onHide={onHide}
            isEdit={isEdit}
          />
        </>
      ) : (
        <NotAuthorizedMessage />
      )}
    </Modal>
  );
}
