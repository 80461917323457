import React from "react";

import { AutocompleteSelect } from "./AutocompleteSelect";
import { CloseableBadges } from "./CloseableBadges";

export function MultiSelect({
  isEdit = true,
  badge = true,
  removeItem,
  variant,
  selected,
  ...props
}) {
  return (
    <>
    {isEdit && (
      <AutocompleteSelect {...props} isEdit={isEdit} />
      )}
      {badge && (
        <CloseableBadges
          values={selected}
          removeItem={removeItem}
          variant={variant}
          close={isEdit}
        />
      )}
    </>
  );
}
