import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";

import { initialFilter } from "./ProfilesUIHelpers";

const ProfilesUIContext = createContext();

export function useProfilesUIContext() {
  return useContext(ProfilesUIContext);
}

export const ProfilesUIConsumer = ProfilesUIContext.Consumer;

export function ProfilesUIProvider({ profilesUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initProfile = {
    id: undefined,
    name: "",
    rating: 0,
    result: "",
    resume: null,
    approved: false,
    is_deployed: false,
    resource_cost: null,
    skillSearchText: "",
    deployment_history: [],
    skills: [],
    email: "",
    phone_no: "",
    country_code: "",
    resource_type: "",
    experience: {
      in_yrs: 0,
      in_months: 0,
    },
    managed_by: "",
    interviewer: "",
    interview_date: "",
    available: false,
    muoro_resume_link: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
    initProfile,
    uploadProfilesButtonClick: profilesUIEvents.uploadProfilesButtonClick,
    newProfileButtonClick: profilesUIEvents.newProfileButtonClick,
    openViewProfileDialog: profilesUIEvents.openViewProfileDialog,
    openEditProfileDialog: profilesUIEvents.openEditProfileDialog,
    exportProfilesButtonClick: profilesUIEvents.exportProfilesButtonClick,
    openPDFViewerDialog: profilesUIEvents.openPDFViewerDialog,
  };

  return (
    <ProfilesUIContext.Provider value={value}>
      {children}
    </ProfilesUIContext.Provider>
  );
}
