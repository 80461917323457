export default [
  // {
  //   href: "/dashboard",
  //   svgSrc: "/media/svg/icons/Design/Layers.svg",
  //   text: "Dashboard",
  //   key: "dashboard"
  // },
  {
    section: true,
    text: "Resume",
    key: "resume",
  },
  {
    href: "/resume/skills",
    svgSrc: "/media/svg/icons/Design/Layers.svg",
    text: "Skills",
    key: "skill",
  },
  {
    href: "/resume/job-roles",
    svgSrc: "/media/svg/icons/Design/Layers.svg",
    text: "Job Roles",
    key: "job_role",
  },
  {
    href: "/resume/profiles",
    svgSrc: "/media/svg/icons/Design/Layers.svg",
    text: "Profiles",
    key: "profile",
  },
  {
    href: "/resume/instant-profiles",
    svgSrc: "/media/svg/icons/Design/Layers.svg",
    text: "Instant Profiles Finder",
    key: "instant_profile",
  },
  {
    href: "/resume/resume-builder",
    svgSrc: "/media/svg/icons/Design/Layers.svg",
    text: "Resume Generator",
    key: "resume_builder",
  },
  {
    href: "/resume/smart-feedback",
    svgSrc: "/media/svg/icons/Design/Layers.svg",
    text: "AI Resume Feedback",
    key: "profile_feedback",
  },
  {
    section: true,
    text: "Build Team",
    key: "build_your_own_team",
  },

  {
    href: "/build-team/home",
    svgSrc: "/media/svg/icons/Communication/Write.svg",
    text: "Build Your Team",
    key: "build_your_own_team",
  },
  {
    section: true,
    text: "Access",
    key: "access",
  },
  {
    href: "/access/permissions",
    svgSrc: "/media/svg/icons/Design/Layers.svg",
    text: "Permissions",
    key: "permission",
  },
  {
    href: "/access/roles",
    svgSrc: "/media/svg/icons/Design/Layers.svg",
    text: "Roles",
    key: "role",
  },
  {
    href: "/access/users",
    svgSrc: "/media/svg/icons/Design/Layers.svg",
    text: "Users",
    key: "user",
  },
  {
    href: "/access/employees",
    svgSrc: "/media/svg/icons/Design/Layers.svg",
    text: "Employees",
    key: "employee",
  },
  {
    section: true,
    text: "Account Settings",
    key: "user_settings",
  },

  {
    href: "/user-profile",
    svgSrc: "/media/svg/icons/General/User.svg",
    text: "Account Settings",
    key: "account_settings",
    submenu: [
      {
        href: "/user-profile",
        text: "User Profile",
        key: "account_settings",
      },
      {
        href: "/user-change-password",
        text: "Change Password",
        key: "account_settings",
      },
    ],
  },
  {
    section: true,
    text: "Communication",
    key: "communication",
  },

  {
    href: "/contact-us",
    svgSrc: "/media/svg/icons/Communication/Write.svg",
    text: "Contact Us",
    key: "contact_us",
  },
];
