import { call, put, takeLatest } from "redux-saga/effects";
import * as requestFromServer from "./profilesCrud";
import { actionTypes, callTypes, actions } from "./profilesRedux";
import { ProfileDownload } from "../../../../util/profileDownload";

function* handleUpsertProfile(action) {
  try {
    yield put(actions.startCall(callTypes.action));
    const response = yield call(
      requestFromServer.upsertProfile,
      action.payload.profile
    );

    yield put(actions.endCall(callTypes.action));

    if (response && response.data) {
      yield put(actions.CatchServer(response.data));
    }

    yield put(actions.fetchProfiles(action.payload.queryParams));
  } catch (error) {
    error.clientMessage = "Can't create profile";
    yield put(actions.catchError(error, callTypes.action));
  }
}

function* handleFetchProfiles(action) {
  try {
    yield put(actions.startCall(callTypes.list));
    const response = yield call(
      requestFromServer.getAllProfiles,
      action.payload.queryParams
    );
    const newResponse = yield call(requestFromServer.getProfileManagers);
    const { profile_managers } = newResponse.data;
    const { profiles } = response.data;

    yield put(
      actions.profilesFetched(profiles.count, profiles.rows, profile_managers)
    );
  } catch (error) {
    error.clientMessage = "Can't find profiles";
    yield put(actions.catchError(error, callTypes.list));
  }
}

function* handleFetchProfileById(action) {
  try {
    if (!action.payload.profileId) yield put(actions.profileFetched());
    else {
      yield put(actions.startCall(callTypes.action));
      const response = yield call(
        requestFromServer.getProfileById,
        action.payload.profileId
      );
      const { profile } = response.data;

      yield put(actions.profileFetched(profile));
    }
  } catch (error) {
    error.clientMessage = "Can't find profile";
    yield put(actions.catchError(error, callTypes.action));
  }
}

function* handleExportProfiles(action) {
  try {
    yield put(actions.startCall(callTypes.action));
    const response = yield call(
      requestFromServer.exportProfilesToFile,
      action.payload.queryParams
    );
    yield put(actions.endCall());

    ProfileDownload(response);
  } catch (error) {
    error.clientMessage = "Can't export profiles";
    yield put(actions.catchError(error, callTypes.action));
  }
}

function* handleUploadProfiles(action) {
  try {
    yield put(actions.startCall(callTypes.action));
    yield call(requestFromServer.uploadProfilesFromDrive);
    yield put(actions.endCall());
    // Closes the Modal
    action.payload.onHide();
  } catch (error) {
    error.clientMessage = "Can't upload profiles from drive";
    yield put(actions.catchError(error, callTypes.action));
  }
}

export function* saga() {
  yield takeLatest(actionTypes.UpsertProfile, handleUpsertProfile);
  yield takeLatest(actionTypes.FetchProfiles, handleFetchProfiles);
  yield takeLatest(actionTypes.FetchProfile, handleFetchProfileById);
  yield takeLatest(actionTypes.ExportProfiles, handleExportProfiles);
  yield takeLatest(actionTypes.UploadProfiles, handleUploadProfiles);
}
