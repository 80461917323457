import React, { Suspense } from "react";
import BuildYourTeamHome from "./home/BuildYourTeamHome";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import ProtectedRoute from "../../../_metronic/_partials/controls/ProtectedRoute";
import { ProfilesUIProvider } from "../Resume/pages/profiles/ProfilesUIContext";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actions } from "../Resume/_redux/profiles";
import TechStack from "./techStack/TechStack";
import SelectProfilesToBuildTeam from "./techStack/SelectProfilesToBuildTeam";
import BuildTeamThankYouPage from "./thankYou/BuildTeamThankYouPage";
import UnknownTechStackHome from "./unKnownTechStack/UnknownTechStackHome";
import Questionnaire from "./unKnownTechStack/Questionnaire";
import RecommendedStack from "./unKnownTechStack/recommendedStack/RecommendedStack";
import { ProfileEditDialog } from "../Resume/pages/profiles/profile-edit-dialog/ProfileEditDialog";
import ProfilePDFViewer from "../Resume/pages/profiles/pdf-viewer/ProfilePDFViewer";

const BuildTeamPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const profilesUIEvents = {
    uploadProfilesButtonClick: () => {
      history.push("/resume/profiles/upload");
    },
    exportProfilesButtonClick: (queryParams) => {
      dispatch(actions.exportProfiles(queryParams));
    },
    newProfileButtonClick: () => {
      history.push("/resume/profiles/new");
    },
    openViewProfileDialog: (id) => {
      history.push(`/build-team/${id}/view-profile`);
    },
    openEditProfileDialog: (id) => {
      history.push(`/resume/profiles/${id}/edit`);
    },
    openPDFViewerDialog: (row) => {
      history.push(`/build-team/${row.id}/view-resume`);
    },
  };

  function handleClose() {
    history.push("/build-team/select-profiles");
  }
  return (
    <div>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          <ProfilesUIProvider profilesUIEvents={profilesUIEvents}>
            <ProtectedRoute
              exact
              path="/build-team/home"
              component={BuildYourTeamHome}
            />
            <ProtectedRoute
              exact
              path="/build-team/tech-stack-known"
              component={TechStack}
            />
            <ProtectedRoute
              exact
              path="/build-team/select-profiles"
              component={SelectProfilesToBuildTeam}
            />
            <ProtectedRoute
              exact
              path="/build-team/thank-you"
              component={BuildTeamThankYouPage}
            />
            <ProtectedRoute
              exact
              path="/build-team/no-tech-stack-known"
              component={UnknownTechStackHome}
            />
            <ProtectedRoute
              exact
              path="/build-team/questionnaire"
              component={Questionnaire}
            />
            <ProtectedRoute
              exact
              path="/build-team/recommended-stack"
              component={RecommendedStack}
            />
            <ProtectedRoute
              exact
              path="/build-team/:id/view-profile"
              component={({ match }) => (
                <ProfileEditDialog
                  isEdit={true}
                  show={match != null}
                  id={match && match.params.id}
                  onHide={handleClose}
                />
              )}
            />
            <ProtectedRoute
              exact
              path="/build-team/:profileId/view-resume"
              component={({ match }) => (
                <ProfilePDFViewer
                  show={match != null}
                  id={match && match.params.profileId}
                  onHide={handleClose}
                />
              )}
            />

            <Redirect exact from="/" to="/build-team/home" />
          </ProfilesUIProvider>

          <Redirect to="/error/error-v4" />
        </Switch>
      </Suspense>
    </div>
  );
};

export default BuildTeamPage;
