import _ from "lodash";

export const actionTypes = {
  CatchError: "[Catch Profile Error] Action",
  CatchServer: "[Catch Profile Server Response] Action",
  ResetError: "[Reset Profile Error] Action",
  StartCall: "[Start Profile Call] Action",
  EndCall: "[End Profile Call] Action",
  SelectedSkills: "[Save Selected Skills]",
  FetchProfilesByTechStack: "[Fetch Profiles By Tech Stack]",
  ProfilesFetchedByTechStack: "[Profiles fetched By Tech Stack]",
  UpdateCurrentStack: "[Update the current tech Stack]",
  UpdateSelectedProfiles: "[Update the Selected Profiles]",
  UpdateServerResponse: "[Update server response]",
  ResetBuildTeam: "[Reset the data in build state]",
  ToggleBuildMode: "[Toggle the team build mode]",
};

export const callTypes = {
  list: "list",
  action: "action",
};

const initialBuildTeamState = {
  listLoading: false,
  actionsLoading: false,
  currentStack: "",
  server_response: [],
  entities: [],
  build_team_mode: false,
  selected_skills: {
    backEnd: [],
    frontEnd: [],
    devOps: [],
    qa: [],
    fullStack: [],
  },
  selected_profiles: {
    backEnd: [],
    frontEnd: [],
    devOps: [],
    qa: [],
    fullStack: [],
  },
  error: null,
};

export const actions = {
  catchError: (error, callType) => ({
    type: actionTypes.CatchError,
    payload: { error, callType },
  }),
  CatchServer: (response) => ({
    type: actionTypes.CatchServer,
    payload: { response },
  }),
  resetError: () => ({ type: actionTypes.ResetError }),
  startCall: (callType) => ({
    type: actionTypes.StartCall,
    payload: { callType },
  }),
  endCall: (callType) => ({ type: actionTypes.EndCall, payload: { callType } }),

  FetchProfilesByTechStack: (skill_ids) => ({
    type: actionTypes.FetchProfilesByTechStack,
    payload: { skill_ids },
  }),
  SelectedSkills: (skills) => ({
    type: actionTypes.SelectedSkills,
    payload: { skills },
  }),
  ProfilesFetchedByTechStack: (profiles) => ({
    type: actionTypes.ProfilesFetchedByTechStack,
    payload: { profiles },
  }),
  UpdateCurrentStack: (techStack) => ({
    type: actionTypes.UpdateCurrentStack,
    payload: { techStack },
  }),
  UpdateSelectedProfiles: (profiles) => ({
    type: actionTypes.UpdateSelectedProfiles,
    payload: { profiles },
  }),
  UpdateServerResponse: (response) => ({
    type: actionTypes.UpdateServerResponse,
    payload: { response },
  }),
  ResetBuildTeam: () => ({
    type: actionTypes.ResetBuildTeam,
    payload: {},
  }),
  ToggleBuildMode: (mode) => ({
    type: actionTypes.ToggleBuildMode,
    payload: { mode },
  }),
};

export const reducer = (state = initialBuildTeamState, action) => {
  switch (action.type) {
    case actionTypes.CatchError: {
      const newState = { ...state };
      const { error, callType } = action.payload;
      newState.error = error.response
        ? `${error.response.data.message}`
        : "Internal server error";

      if (callType === callTypes.list) {
        newState.listLoading = false;
      } else {
        newState.actionsLoading = false;
      }
      return newState;
    }

    case actionTypes.ResetError: {
      const newState = { ...state };

      newState.error = null;
      return newState;
    }

    case actionTypes.StartCall: {
      const newState = { ...state };
      const { callType } = action.payload;

      newState.error = null;
      if (callType === callTypes.list) {
        newState.listLoading = true;
      } else {
        newState.actionsLoading = true;
      }
      return newState;
    }

    //save selected skills
    case actionTypes.SelectedSkills: {
      const newState = { ...state };
      newState.actionsLoading = false;
      newState.selected_skills = action.payload.skills;
      newState.error = null;
      return newState;
    }
    //toggle build mode
    case actionTypes.ToggleBuildMode: {
      const newState = { ...state };
      newState.actionsLoading = false;
      newState.build_team_mode = action.payload.mode;
      newState.error = null;
      return newState;
    }

    //updating profiles to store as per tech stack
    case actionTypes.ProfilesFetchedByTechStack: {
      const newState = { ...state };
      newState.listLoading = false;
      newState.error = null;
      newState.entities = action.payload.profiles;
      return newState;
    }
    //updating selected profiles
    case actionTypes.UpdateSelectedProfiles: {
      const newState = { ...state };
      newState.listLoading = false;
      newState.error = null;
      newState.selected_profiles[newState.currentStack] =
        action.payload.profiles;

      return newState;
    }

    //update current tech stack
    case actionTypes.UpdateCurrentStack: {
      const newState = { ...state };
      newState.listLoading = false;
      newState.error = null;
      newState.currentStack = action.payload.techStack;

      return newState;
    }
    //update server response
    case actionTypes.UpdateServerResponse: {
      const newState = { ...state };
      newState.listLoading = false;
      newState.error = null;
      newState.server_response = action.payload.response;

      return newState;
    }
    //reset the state
    case actionTypes.ResetBuildTeam: {
      const newState = _.cloneDeep(initialBuildTeamState);
      return newState;
    }

    default: {
      return state;
    }
  }
};
