// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import Stars from "react-stars";

export function StarsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { field, maxCount }
) {
  const defaultRating = row[field] || 0;

  return (
    <Stars count={maxCount} value={defaultRating} edit={false} size={18} />
  );
}
