import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  CatchError: "[Catch Profile Error] Action",
  CatchServer: "[Catch Profile Server Response] Action",
  ResetError: "[Reset Profile Error] Action",
  StartCall: "[Start Profile Call] Action",
  EndCall: "[End Profile Call] Action",
  FetchProfile: "[Fetch Profile] Action",
  ProfileFetched: "[Profile Fetched] Action",
  FetchProfiles: "[Fetch Profiles] Action",
  ProfilesFetched: "[Profiles Fetched] Action",
  UpsertProfile: "[Upsert Profile] Action",
  ProfileCreated: "[Profile Created] Action",
  ProfileUpdated: "[Profile Updated] Action",
  ExportProfiles: "[Export Profiles] Action",
  ProfilesExported: "[Profiles Exported] Action",
  UploadProfiles: "[Upload Profiles] Action",
  ProfilesUploaded: "[Profiles Uploaded] Action",
  ProfilesTableState: "[Profiles Table State] Action",
  ResumeBuilder: "[Resume Data] Action",
  ResumeForEdit: "[Resume Edit] Action",
  Logout: "[Logout] Action",
};

export const callTypes = {
  list: "list",
  action: "action",
};

const initialProfilesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  profile_managers: [],
  profileForEdit: undefined,
  showProfilesTable: true,
  error: null,
  serverResponse: null,
  resumeEdit: false,
  resume: {
    firstname: "",
    lastname: "",
    phone_no: "",
    email: "",
    linkedin: "",
    github: "",
    website: "",
    address: "",
    summary: "",
    skills: [
      {
        skill_type: "",
        skill: [],
      },
    ],
    work_experience: [
      {
        job_title: "",
        organization: "",
        start_date: "",
        end_date: "",
        is_current: "",
        description: "",
      },
    ],
    projects: [
      {
        role: "",
        projectName: "",
        techStack: "",
        description: "",
      },
    ],
    education: [
      {
        organization: "",
        name: "",
        startDate: "",
        end_date: "",
      },
    ],
    certifications: [],
  },
};

export const formatServerDate = (serverDate) => {
  const date = new Date(serverDate);
  return date.toISOString().split("T")[0];
};

export const actions = {
  catchError: (error, callType) => ({
    type: actionTypes.CatchError,
    payload: { error, callType },
  }),
  CatchServer: (response) => ({
    type: actionTypes.CatchServer,
    payload: { response },
  }),
  resetError: () => ({ type: actionTypes.ResetError }),
  startCall: (callType) => ({
    type: actionTypes.StartCall,
    payload: { callType },
  }),
  endCall: (callType) => ({ type: actionTypes.EndCall, payload: { callType } }),
  fetchProfile: (id) => ({
    type: actionTypes.FetchProfile,
    payload: { profileId: id },
  }),
  profileFetched: (profile) => ({
    type: actionTypes.ProfileFetched,
    payload: { profileForEdit: profile },
  }),
  fetchProfiles: (queryParams) => ({
    type: actionTypes.FetchProfiles,
    payload: { queryParams },
  }),
  profilesFetched: (totalCount, profiles, profile_managers) => ({
    type: actionTypes.ProfilesFetched,
    payload: {
      totalCount,
      entities: profiles,
      ...(profile_managers.length > 0 && {
        profile_managers: profile_managers,
      }),
    },
  }),
  upsertProfile: (profile, queryParams) => ({
    type: actionTypes.UpsertProfile,
    payload: { profile, queryParams },
  }),
  exportProfiles: (queryParams) => ({
    type: actionTypes.ExportProfiles,
    payload: { queryParams },
  }),
  uploadProfiles: (onHide) => ({
    type: actionTypes.UploadProfiles,
    payload: { onHide },
  }),
  ProfilesTableState: () => ({
    type: actionTypes.ProfilesTableState,
    payload: {},
  }),
  ResumeBuilder: (resumeData) => ({
    type: actionTypes.ResumeBuilder,
    payload: { resume: resumeData },
  }),
  ResumeForEdit: (editState) => ({
    type: actionTypes.ResumeForEdit,
    payload: { resumeEdit: editState },
  }),
  logout: () => ({ type: actionTypes.Logout }),
};

const persistConfig = {
  storage,
  key: "profiles",
  whitelist: ["profile_managers"],
};

export const reducer = persistReducer(
  persistConfig,
  (state = initialProfilesState, action) => {
    switch (action.type) {
      case actionTypes.CatchError: {
        const newState = { ...state };
        const { error, callType } = action.payload;

        newState.error = error.response
          ? error.response.data.message
          : "Internal server error";

        if (callType === callTypes.list) {
          newState.listLoading = false;
        } else {
          newState.actionsLoading = false;
        }
        return newState;
      }

      case actionTypes.ResetError: {
        const newState = { ...state };

        newState.error = null;
        newState.serverResponse = null;
        return newState;
      }
      case actionTypes.CatchServer: {
        const newState = { ...state };

        const { response } = action.payload;

        newState.serverResponse = response.message
          ? response.message
          : "Updated Successfully";

        return newState;
      }

      case actionTypes.StartCall: {
        const newState = { ...state };
        const { callType } = action.payload;

        newState.error = null;
        if (callType === callTypes.list) {
          newState.listLoading = true;
        } else {
          newState.actionsLoading = true;
        }
        return newState;
      }

      case actionTypes.EndCall: {
        const newState = { ...state };
        const { callType } = action.payload;

        newState.error = null;
        if (callType === callTypes.list) {
          newState.listLoading = false;
        } else {
          newState.actionsLoading = false;
        }
        return newState;
      }

      // getProfileById
      case actionTypes.ProfileFetched: {
        const newState = { ...state };
        newState.listLoading = false;
        const { profileForEdit } = action.payload;

        if (profileForEdit) {
          profileForEdit.rating = profileForEdit.rating || 0;
          profileForEdit.result = profileForEdit.profile_result?.result || "";
          profileForEdit.resume = profileForEdit.url?.url;
          profileForEdit.managed_by = profileForEdit.managed_by || "";
          profileForEdit.country_code =
            profileForEdit.country_code !== "undefined" &&
            profileForEdit.country_code
              ? `+${profileForEdit.country_code}`
              : "+91";

          profileForEdit.resource_cost = profileForEdit.resource_cost || "";
          profileForEdit.resource_type = profileForEdit.resource_type || "";
          profileForEdit.interviewer = profileForEdit.interviewer || "";
          profileForEdit.interview_date = profileForEdit.interview_date
            ? formatServerDate(profileForEdit.interview_date)
            : "";
          profileForEdit.available = profileForEdit.available || false;
          profileForEdit.muoro_resume_link =
            profileForEdit.muoro_resume_link || "";
          profileForEdit.skills = profileForEdit?.skills?.map((skillObj) => ({
            id: skillObj.skill_id,
            name: skillObj.skill.name,
            active: skillObj.skill.active,
            profileSkillActive: skillObj.active,
          }));
          delete profileForEdit.url;
          delete profileForEdit.profile_result;
        }

        newState.actionsLoading = false;
        newState.profileForEdit = profileForEdit;
        newState.showProfilesTable = false;
        newState.error = null;
        return newState;
      }

      case actionTypes.ProfilesFetched: {
        const newState = { ...state };
        const { totalCount, entities, profile_managers } = action.payload;

        newState.listLoading = false;
        newState.error = null;
        newState.entities = entities;
        newState.totalCount = totalCount;
        newState.profile_managers = profile_managers;

        return newState;
      }

      case actionTypes.ProfilesTableState: {
        const newState = { ...state };
        newState.showProfilesTable = true;
        return newState;
      }
      //Resume Builder
      case actionTypes.ResumeBuilder: {
        const newState = { ...state };
        const { resume } = action.payload;
        newState.resume = resume;
        return newState;
      }
      case actionTypes.ResumeForEdit: {
        const newState = { ...state };
        newState.resumeEdit = action.payload.resumeEdit;
        return newState;
      }

      case actionTypes.Logout: {
        return initialProfilesState;
      }

      default: {
        return state;
      }
    }
  }
);
