import React from "react";
import { Field, FieldArray } from "formik";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export function CertificatesForm({ values, setFieldValue }) {
  return (
    <div>
      <FieldArray name="certifications">
        {(arrayHelpers) => (
          <div>
            {values.certifications.map((certificate, index) => (
              <div
                key={index}
                style={{
                  display: "list-item",
                  listStyle: "decimal",
                  fontSize: "1.2rem",
                }}
              >
                <div className="form-group row">
                  <div className="col-lg-8 mb-5">
                    <Field
                      name={`certifications[${index}]`}
                      placeholder="Certificate"
                      component="input"
                      type="text"
                      className="form-control"
                      onChange={(event) => {
                        setFieldValue(
                          `certifications[${index}]`,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                  {/* <div className="col-lg-4">
                    <Field
                      name={`certificates[${index}].certificateLink`}
                      placeholder="Certificate Link"
                      component="input"
                      type="text"
                      className="form-control"
                      onChange={(event) => {
                        setFieldValue(
                          `certificates[${index}].certificateLink`,
                          event.target.value
                        );
                      }}
                    />
                  </div> */}
                  <div className="col-lg-2">
                    <button
                      type="button"
                      onClick={() => arrayHelpers.remove(index)}
                      className="btn btn-icon  btn-sm"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Trash.svg"
                          )}
                        />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            ))}

            <div className="form-group row">
              <div className="col-lg-12">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => arrayHelpers.push()}
                >
                  Add Certificate
                </button>
              </div>
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
}
