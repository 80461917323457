import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card } from "../../../../_metronic/_partials/controls";
import { useDispatch } from "react-redux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import "./style.css";
import { actions } from "../_redux/buildTeam/buildTeamRedux";
const BuildYourTeamHome = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(actions.ResetBuildTeam());
    dispatch(actions.ToggleBuildMode(true));
  }, []);
  return (
    <Card className="build-team-container">
      <h2 className="uTSHome__largeBlackText" style={{ marginBottom: "1em" }}>
        Let's Build a Team!
      </h2>
      <div className="row build-team-inner-container">
        <button
          className="col-md-4 build-btn"
          onClick={() => history.push("/build-team/tech-stack-known")}
        >
          <img alt="" src={toAbsoluteUrl("/media/new_icons/confirm.jpg")} />
          <p>Let's go!</p>
        </button>
        <button
          className="col-md-4 build-btn"
          onClick={() => history.push("/build-team/no-tech-stack-known")}
        >
          <img alt="" src={toAbsoluteUrl("/media/new_icons/help.jpg")} />
          <p>Guide me through!</p>
        </button>
      </div>
    </Card>
  );
};

export default BuildYourTeamHome;
