import React, { useState, useEffect, useMemo } from "react";
import "./style.css"
import { Document, Page } from "react-pdf/dist/entry.webpack";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "../../../../../../_metronic/_partials/controls";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { actions } from "../../../_redux/profiles";
import { useProfilesUIContext } from "../ProfilesUIContext";
import { useLocation, useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
DocViewerRenderers.fileTypes = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

function ProfilePDFViewer({ id, show, onHide }) {
  // Profiles UI Context
  const profilesUIContext = useProfilesUIContext();
  const profilesUIProps = useMemo(() => {
    return {
      queryParams: profilesUIContext.queryParams,
      initProfile: profilesUIContext.initProfile,
    };
  }, [profilesUIContext]);

  // Profiles Redux state
  const dispatch = useDispatch();

  const { actionsLoading, profileForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.profiles.actionsLoading,
      profileForEdit: state.profiles.profileForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Profile by id

    dispatch(actions.fetchProfile(id));
  }, [id, dispatch]);

  const profile = { ...profilesUIProps.initProfile, ...profileForEdit };

  let file, fileExtension;

  if (!actionsLoading && profile.resume !== "" && profile.resume_path) {
    file = profile.resume;
    fileExtension = profile.resume_path.split(".").pop();
  }

  const location = useLocation();
  const resume = location?.state?.resume;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.5);

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  function handleZoomIn() {
    setScale((prevScale) => prevScale + 0.1);
  }

  function handleZoomOut() {
    setScale((prevScale) => prevScale - 0.1);
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const history = useHistory();
  const handleClose = (resume) => {
    if (resume) {
      history.push(`/resume/profiles/${id}/view`);
    } else {
      onHide();
    }
  };

  return (
    <>
      <Card
        style={{
          height: "100%",
          boxSizing: "border-box",
        }}
      >
        <CardHeader
          style={{
            backgroundColor: "#f8f9fa",
            borderBottom: "none",
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
          }}
        >
          <div className="d-flex justify-content-around align-items-center w-100">
            <button
              title="zoom in"
              type="button"
              className="btn btn-outline-info btn-md mr-2 px-5"
              onClick={handleZoomIn}
              disabled={fileExtension === "docx" || fileExtension === "doc"}
            >
              <i className="fa fa-plus"></i>
            </button>
            <button
              title="zoom out"
              type="button"
              className="btn btn-outline-info btn-md mr-2 px-5"
              style={{ marginLeft: "5rem" }}
              onClick={handleZoomOut}
              disabled={fileExtension === "docx" || fileExtension === "doc"}
            >
              <i className="fa fa-minus"></i>
            </button>

            <a
              target="_blank"
              rel="noopener noreferrer"
              download
              href={profile.resume}
              title={`${profile.name}_muoro`}
              className="btn btn-icon btn-outline-success btn-hover-success btn-sm mx-1 ml-auto"
            >
              <span className="svg-icon svg-icon-md svg-icon-success">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Files/NewDownload1.svg")}
                />
              </span>
            </a>

            <button
              type="button"
              className="btn btn-outline-danger btn-md ml-5 px-10"
              title="close"
              onClick={() => handleClose(resume)}
            >
              close
            </button>
          </div>
        </CardHeader>
        <CardBody
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            overflow: "auto",
            maxWidth: "100%",
            boxSizing: "borderBox",
          }}
        >
          {!actionsLoading && fileExtension === "pdf" && (
            <div style={{ width: "100%" }}>
              <Document file={file} onLoadSuccess={onDocumentLoadSuccess} style={{display:'flex',justifyContent:'center'}}>
                <Page pageNumber={pageNumber} scale={scale} />
              </Document>
              <p style={{ textAlign: "center" }}>
                Page {pageNumber} of {numPages}
              </p>
            </div>
          )}

          {!actionsLoading &&
            (fileExtension === "doc" || fileExtension === "docx") && (
              <DocViewer
                documents={[{ uri: file }]}
                theme={{
                  primary: "#5296d8",
                  textPrimary: "#ffffff",
                }}
                pluginRenderers={DocViewerRenderers}
                prefetchMethod="GET"
                style={{
                  width: "90%",
                  height: "100vh",
                  margin: "auto",
                  padding: "0",
                }}
              />
            )}
        </CardBody>
        <CardFooter
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
          }}
        >
          <button
            title="previous page"
            className="btn btn-outline-primary btn-md px-5"
            onClick={handlePrevPage}
            style={{ marginRight: "2rem" }}
            disabled={
              pageNumber === 1 ||
              fileExtension === "docx" ||
              fileExtension === "doc"
            }
          >
            Prev
          </button>

          <button
            title="next page"
            className="btn btn-outline-primary btn-md px-5"
            onClick={handleNextPage}
            disabled={
              pageNumber === numPages ||
              fileExtension === "docx" ||
              fileExtension === "doc"
            }
          >
            Next
          </button>
        </CardFooter>
      </Card>
    </>
  );
}

export default ProfilePDFViewer;
