import React from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { actions } from "../../_redux/profiles";
import { Card, CardBody } from "../../../../../_metronic/_partials/controls";

import { NotAuthorizedMessage } from "../../../../../_metronic/_helpers";
import { InstantProfilesFilter } from "./InstantProfilesFilter";
export function InstantProfilesCard() {
  const { allowedPages } = useSelector(
    (state) => ({
      allowedPages: state.auth.allowedPages,
    }),
    shallowEqual
  );

  //making Profiles table visible if hidden
  const dispatch = useDispatch();
  dispatch(actions.ProfilesTableState());

  return (
    <Card style={{ height: "100%", boxSizing: "border-box" }}>
      <CardBody>
        {allowedPages.instant_profile?.read ? (
          <>
            <InstantProfilesFilter />
          </>
        ) : (
          <NotAuthorizedMessage />
        )}
      </CardBody>
    </Card>
  );
}
