import axios from "axios";
import { App_Urls } from "../../../../Urls/urlConfig";
export const USERS_URL = App_Urls.USERS_URL;
export const REGISTER_URL = App_Urls.USER_REGISTER_URL;

// CREATE =>  POST: registers a new user
export function registerUser(user) {
  return axios.post(REGISTER_URL, {
    name: user.name,
    email: user.email,
    role_id: user.role.id,
    active: user.active,
  });
}

export function registerEmployee(user) {
  
  return axios.post(REGISTER_URL, {
    name: user.name,
    email: user.email,
    recipient_email: user.recipient_email,
    active: user.active,
    alpha: true,
  });
}

export function udpateUser(user) {
  console.log(user)
 
  if (!user.deleteData) {
    return axios.put(`${USERS_URL}/${user.id}`, {
      name: user.name,
      email: user.email,
      recipient_email: user.recipient_email,
      role_id: user.role?.id,
      active: user.active,
    });
  } else {
    return axios.delete(`${USERS_URL}/${user.id}`, {
      active: user.active,
    });
  }
}
export function updateEmployee(user) {
  if (!user.deleteData) {
    return axios.put(`${USERS_URL}/${user.id}`, {
      name: user.name,
      email: user.email,
      recipient_email: user.recipient_email,
      alpha: true,
      active: user.active,
    });
  } else {
    return axios.delete(`${USERS_URL}/${user.id}`, {
      active: user.active,
    });
  }
}


// READ
export function getAllUsers(queryParams) {
  const { filter } = queryParams;

  if (filter.alpha) {
    return axios.get(USERS_URL, {
      params: {
        search: queryParams.filter.search,
        limit: queryParams.pageSize,
        alpha: true,
        offset:
          queryParams.pageNumber &&
          (queryParams.pageNumber - 1) * queryParams.pageSize,
      },
    });
  } else {
    return axios.get(USERS_URL, {
      params: {
        search: queryParams.filter.search,
        limit: queryParams.pageSize,
        offset:
          queryParams.pageNumber &&
          (queryParams.pageNumber - 1) * queryParams.pageSize,
      },
    });
  }
}

export function getUserById(userId) {
  return axios.get(`${USERS_URL}/${userId}`);
}
export function getEmployeeById(userId) {
  return axios.get(`${USERS_URL}/${userId}`);
}
