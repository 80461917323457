import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";

import { initialFilter } from "./SkillsUIHelpers";

const SkillsUIContext = createContext();

export function useSkillsUIContext() {
  return useContext(SkillsUIContext);
}

export const SkillsUIConsumer = SkillsUIContext.Consumer;

export function SkillsUIProvider({ skillsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initSkill = {
    id: undefined,
    name: "",
    variants: [""],
    active: true,
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
    initSkill,
    uploadSkillsButtonClick: skillsUIEvents.uploadSkillsButtonClick,
    newSkillButtonClick: skillsUIEvents.newSkillButtonClick,
    openViewSkillDialog: skillsUIEvents.openViewSkillDialog,
    openEditSkillDialog: skillsUIEvents.openEditSkillDialog,
    openDeleteSkillDialog: skillsUIEvents.openDeleteSkillDialog,
  };

  return (
    <SkillsUIContext.Provider value={value}>
      {children}
    </SkillsUIContext.Provider>
  );
}
