export const actionTypes = {
  CatchError: "[Catch Role Error] Action",
  ResetError: "[Reset Role Error] Action",
  StartCall: "[Start Role Call] Action",
  FetchRole: "[Fetch Role] Action",
  RoleFetched: "[Role Fetched] Action",
  FetchRoles: "[Fetch Roles] Action",
  RolesFetched: "[Roles Fetched] Action",
  UpsertRole: "[Upsert Role] Action",
  RoleCreated: "[Role Created] Action",
  RoleUpdated: "[Role Updated] Action",
  DeleteRole: "[Delete Role] Action",
  RoleDeleted: "[Role Deleted] Action",
  UploadRoles: "[Upload Roles] Action",
  RolesUploaded: "[Roles Uploaded] Action",
  Logout: "[Logout] Action",
};

export const callTypes = {
  list: "list",
  action: "action",
};

const initialRolesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  roleForEdit: undefined,
  error: null,
};

export const actions = {
  catchError: (error, callType) => ({
    type: actionTypes.CatchError,
    payload: { error, callType },
  }),
  resetError: () => ({ type: actionTypes.ResetError }),
  startCall: (callType) => ({
    type: actionTypes.StartCall,
    payload: { callType },
  }),
  fetchRole: (id) => ({ type: actionTypes.FetchRole, payload: { roleId: id } }),
  roleFetched: (role) => ({
    type: actionTypes.RoleFetched,
    payload: { roleForEdit: role },
  }),
  fetchRoles: (queryParams) => ({
    type: actionTypes.FetchRoles,
    payload: { queryParams },
  }),
  rolesFetched: (totalCount, roles) => ({
    type: actionTypes.RolesFetched,
    payload: { totalCount, entities: roles },
  }),
  upsertRole: (role, pageSize, onHide) => ({
    type: actionTypes.UpsertRole,
    payload: { role, pageSize, onHide },
  }),
  roleCreated: (role, pageSize) => ({
    type: actionTypes.RoleCreated,
    payload: { role, pageSize },
  }),
  roleUpdated: (role) => ({
    type: actionTypes.RoleUpdated,
    payload: { role },
  }),
  deleteRole: (id, handlePostDelete) => ({
    type: actionTypes.DeleteRole,
    payload: { roleId: id, active: false, handlePostDelete },
  }),
  roleDeleted: (id) => ({
    type: actionTypes.RoleDeleted,
    payload: { roleId: id },
  }),
  uploadRoles: (file, handlePostUpload) => ({
    type: actionTypes.UploadRoles,
    payload: { file, handlePostUpload },
  }),
  rolesUploaded: () => ({ type: actionTypes.RolesUploaded }),
  logout: () => ({ type: actionTypes.Logout }),
};

export const reducer = (state = initialRolesState, action) => {
  switch (action.type) {
    case actionTypes.CatchError: {
      const newState = { ...state };
      const { error, callType } = action.payload;

      newState.error = error.response
        ? `${error.response.data.message}`
        : "Internal server error";
      if (callType === callTypes.list) {
        newState.listLoading = false;
      } else {
        newState.actionsLoading = false;
      }
      return newState;
    }

    case actionTypes.ResetError: {
      const newState = { ...state };

      newState.error = null;
      return newState;
    }

    case actionTypes.StartCall: {
      const newState = { ...state };
      const { callType } = action.payload;

      newState.error = null;
      if (callType === callTypes.list) {
        newState.listLoading = true;
      } else {
        newState.actionsLoading = true;
      }
      return newState;
    }

    // getRoleById
    case actionTypes.RoleFetched: {
      const newState = { ...state };
      const { roleForEdit } = action.payload;

      newState.actionsLoading = false;
      newState.roleForEdit = roleForEdit;
      newState.error = null;
      return newState;
    }

    case actionTypes.RolesFetched: {
      const newState = { ...state };
      const { totalCount, entities } = action.payload;

      newState.listLoading = false;
      newState.error = null;
      newState.entities = entities;
      newState.totalCount = totalCount;
      return newState;
    }

    case actionTypes.RoleCreated: {
      const newState = { ...state };
      const { role, pageSize } = action.payload;

      newState.actionsLoading = false;
      newState.error = null;
      if (newState.entities.length < pageSize)
        newState.entities = [...newState.entities, role];
      return newState;
    }

    case actionTypes.RoleUpdated: {
      const newState = { ...state };
      const { role } = action.payload;

      newState.error = null;
      newState.actionsLoading = false;
      newState.entities = newState.entities.map((entity) => {
        if (entity.id === role.id) return role;
        return entity;
      });
      return newState;
    }

    case actionTypes.RoleDeleted: {
      const newState = { ...state };
      const { roleId } = action.payload;

      newState.error = null;
      newState.actionsLoading = false;
      newState.entities = newState.entities.filter((el) => el.id !== roleId);
      return newState;
    }

    case actionTypes.RolesUploaded: {
      const newState = { ...state };

      newState.error = null;
      newState.actionsLoading = false;
      return newState;
    }

    case actionTypes.Logout: {
      return initialRolesState;
    }

    default: {
      return state;
    }
  }
};
