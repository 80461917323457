import { Field, FieldArray } from "formik";
import React from "react";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export function Projects({ values, setFieldValue }) {
  return (
    <div>
      <FieldArray name="projects">
        {(arrayHelpers) => (
          <div>
            {values.projects.map((project, index) => (
              <div
                key={index}
                className="mb-15"
                style={{
                  display: "list-item",
                  listStyle: "decimal",
                  fontSize: "1.2rem",
                }}
              >
                <div className="form-group row">
                  <div className="col-lg-4 mb-5">
                    <Field
                      name={`projects[${index}].projectName`}
                      placeholder="Project Name"
                      component="input"
                      type="text"
                      className="form-control"
                      onChange={(event) => {
                        setFieldValue(
                          `projects[${index}].projectName`,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                  <div className="col-lg-4 mb-5">
                    <Field
                      name={`projects[${index}].role`}
                      placeholder="Project Role"
                      component="input"
                      type="text"
                      className="form-control"
                      onChange={(event) => {
                        setFieldValue(
                          `projects[${index}].role`,
                          event.target.value
                        );
                      }}
                    />
                  </div>

                  <div className="col-lg-1 mb-5">
                    <button
                      type="button"
                      onClick={() => arrayHelpers.remove(index)}
                      className="btn btn-icon  btn-sm"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Trash.svg"
                          )}
                        />
                      </span>
                    </button>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4 mb-3">
                    <Field
                      name={`projects[${index}].companyName`}
                      placeholder="Company Name"
                      component="input"
                      type="text"
                      className="form-control"
                      onChange={(event) => {
                        setFieldValue(
                          `projects[${index}].companyName`,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                  <div className="col-lg-8 mb-3">
                    <Field
                      name={`projects[${index}].techStack`}
                      placeholder="Tech Stack"
                      component="input"
                      type="textArea"
                      className="form-control"
                      onChange={(event) => {
                        setFieldValue(
                          `projects[${index}].techStack`,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4 mb-5">
                    <label>Project Start Date :</label>
                    <Field
                      name={`projects[${index}].start_date`}
                      placeholder="Start Date"
                      component="input"
                      type="date"
                      className="form-control"
                      onChange={(event) => {
                        setFieldValue(
                          `projects[${index}].start_date`,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                  <div className="col-lg-4">
                    <label>Project End Date :</label>
                    <Field
                      name={`projects[${index}].end_date`}
                      placeholder="End Date"
                      component="input"
                      type="date"
                      className="form-control"
                      onChange={(event) => {
                        setFieldValue(
                          `projects[${index}].end_date`,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12 mb-5">
                    <ReactQuill
                      value={values.projects[index].description}
                      onChange={(value) =>
                        setFieldValue(`projects[${index}].description`, value)
                      }
                      placeholder="Project Description"
                      style={{ height: "25rem" }}
                    />
                  </div>
                </div>
              </div>
            ))}
            <div className="form-group row">
              <div className="col-lg-12 mb-5">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => {
                    arrayHelpers.push({
                      role: "",
                      projectName: "",
                      companyName: "",
                      techStack: "",
                      start_date: "",
                      end_date: "",
                      description: "",
                    });
                  }}
                >
                  Add Projects
                </button>
              </div>
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
}
