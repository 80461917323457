import React from "react";
import { FormControl, FormControlLabel } from "@material-ui/core";

const FormOption = ({
  questions,
  selectedOptions,
  activeStep,
  handleOptionChange,
}) => {
  const isIconAvailable = questions[activeStep].attributes?.image_as_options;
  return (
    <form className="formOptions">
      {questions[activeStep].options.map((option) => (
        <FormControl key={option.id} component="fieldset">
          <FormControlLabel
            control={
              <div
                className={`formOptions__optionTag ${
                  selectedOptions.some(
                    (selectedOption) =>
                      selectedOption.questionId === questions[activeStep].id &&
                      selectedOption?.id === option.id
                  )
                    ? " formOptions__optionTag--optionTagSelected"
                    : ""
                }`}
                onClick={() =>
                  handleOptionChange({
                    questionId: questions[activeStep].id,
                    ...option,
                  })
                }
                name={`question_${questions[activeStep].id}_option_${option.id}`}
              >
                <div className="formOptions__optionTag__optionIcon">
                  <img
                    src={
                      isIconAvailable && option?.image_url
                        ? option?.image_url
                        : "/media/svg/icons/Shopping/Sort1.svg" // Default OptionIcon
                    }
                    alt={option.body}
                  />
                </div>
                <div className="formOptions__optionTag__optionText">
                  {option?.body}
                </div>
              </div>
            }
          />
        </FormControl>
      ))}
    </form>
  );
};

export default FormOption;
