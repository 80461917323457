import axios from "axios";
import { App_Urls } from "../../../../Urls/urlConfig";

export const ROLES_URL = App_Urls.ROLES_URL;

// CREATE OR UPDATE=>  PUT: upsert a role to the server
export function upsertRole(role) {
  if (!role.id) {
    return axios.post(ROLES_URL, {
      name: role.name,
      active: role.active,
      permission_ids: role.permissions?.map((permission) => permission.id),
    });
  } else {
    if (!role.deleteData) {
      return axios.put(`${ROLES_URL}/${role.id}`, {
        name: role.name,
        active: role.active,
        permission_ids: role.permissions?.map((permission) => permission.id),
      });
    } else {
      return axios.delete(`${ROLES_URL}/${role.id}`, {
        name: role.name,
        active: role.active,
        permission_ids: role.permissions?.map((permission) => permission.id),
      });
    }
  }
}

// READ
export function getAllRoles(queryParams) {
  return axios.get(ROLES_URL, {
    params: {
      search: queryParams.filter.search,
      limit: queryParams.pageSize,
      offset:
        queryParams.pageNumber &&
        (queryParams.pageNumber - 1) * queryParams.pageSize,
    },
  });
}

export function getRoleById(roleId) {
  return axios.get(`${ROLES_URL}/${roleId}`);
}

export function uploadRoleUsingExcelFile(file) {
  const formData = new FormData();
  formData.append("inputFile", file);

  return axios.post(`${ROLES_URL}/bulk`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
