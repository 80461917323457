import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Alert } from "react-bootstrap";
import { actions } from "../../../../app/modules/Resume/_redux/profiles";
export function Error({ message = "", type = "" }) {
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    setShowAlert(true);
  }, [message]);

  if (!message || !showAlert) return <></>;

  const handleClose = () => {
    setShowAlert(false);
    dispatch(actions.resetError());
  };
  return (
    <>
      <Alert
        variant={type !== "" ? "success" : "danger"}
        style={{
          fontSize: "1.3rem",
          textAlign: "center",
          borderRadius: "2rem 0 2rem 0",
        }}
        onClick={handleClose}
        dismissible={true}
      >
        {message}
      </Alert>
    </>
  );
}
