import React from "react";
import {
    Button,
    Stepper,
    Step,
    StepLabel
  } from "@material-ui/core";
const StepperAndButtons = ({
  activeStep,
  questions,
  handleBack,
  nextButtonState,
  handleFormSubmit,
}) => {
  return (
    <div className="questionnaire__stepperAndButtons">
      <Button disabled={activeStep === 0} onClick={handleBack}>
        &lt; Back
      </Button>
      <Stepper activeStep={activeStep}>
        {questions.map((question, index) => (
          <Step key={index}>
            <StepLabel></StepLabel>
          </Step>
        ))}
      </Stepper>
      <Button disabled={nextButtonState} onClick={handleFormSubmit}>
        {activeStep === questions.length - 1 &&
        questions[activeStep].attributes?.last_question
          ? "Finish >"
          : "Next >"}
      </Button>
    </div>
  );
};

export default StepperAndButtons;
