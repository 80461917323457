import React from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { MdClose } from "react-icons/md";
import { NotAuthorizedMessage } from "../../../../../../_metronic/_helpers";
import {
  Error,
  ModalProgressBar,
} from "../../../../../../_metronic/_partials/controls";
import { actions } from "../../../_redux/profiles";

export function ProfilesUploadDialog({ show, onHide }) {
  // Profiles Redux state
  const dispatch = useDispatch();
  const { isLoading, allowedPages, errorMsg } = useSelector(
    (state) => ({
      isLoading: state.profiles.actionsLoading,
      allowedPages: state.auth.allowedPages,
      errorMsg: state.profiles.error,
    }),
    shallowEqual
  );

  const uploadProfiles = () => {
    // server request for uploading profiles
    dispatch(actions.uploadProfiles(onHide));
  };

  return (
    <Modal
      show={show}
      centered
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      <MdClose
        onClick={onHide}
        className="text-right"
        style={{
          margin: "5 15 0 auto",
          fontSize: "2rem",
          cursor: "pointer",
        }}
      />
      {/*end::Loading*/}
      {allowedPages.profile?.create ? (
        <>
          <Modal.Body
            style={{
              fontSize: "1.6rem",
              display: "flex",
              flexDirection: "column",
              rowGap: "2rem",
            }}
          >
            <Error message={errorMsg} />

            {!isLoading && (
              <span>Do you want to upload profiles from Drive ?</span>
            )}
            {isLoading && <span>Profiles upload in process...</span>}

            <button
              type="button"
              onClick={uploadProfiles}
              className="btn btn-success btn-elevate px-20"
            >
              Upload
            </button>
          </Modal.Body>
        </>
      ) : (
        <NotAuthorizedMessage />
      )}
    </Modal>
  );
}
