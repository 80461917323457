import { call, put, takeLatest } from "redux-saga/effects";

import * as requestFromServer from "./permissionsCrud";
import { actionTypes, callTypes, actions } from "./permissionsRedux";

function* handleUpsertPermission(action) {
  try {
    yield put(actions.startCall(callTypes.action));
    const response = yield call(
      requestFromServer.upsertPermission,
      action.payload.permission
    );
    const { permission } = response.data;

    if (action.payload.permission.id) {
      yield put(actions.permissionUpdated(permission));
    } else {
      yield put(actions.permissionCreated(permission, action.payload.pageSize));
    }
    // Closes the Edit Modal
    action.payload.onHide();
  } catch (error) {
    error.clientMessage = "Can't create permission";
    yield put(actions.catchError(error, callTypes.action));
  }
}

function* handleFetchPermissions(action) {
  try {
    yield put(actions.startCall(callTypes.list));
    const response = yield call(
      requestFromServer.getAllPermissions,
      action.payload.queryParams
    );
    const { permissions } = response.data;
    yield put(actions.permissionsFetched(permissions.count, permissions.rows));
  } catch (error) {
    error.clientMessage = "Can't find permissions";
    yield put(actions.catchError(error, callTypes.list));
  }
}

function* handleFetchPermissionById(action) {
  try {
    if (!action.payload.permissionId) yield put(actions.permissionFetched());
    else {
      yield put(actions.startCall(callTypes.action));
      const response = yield call(
        requestFromServer.getPermissionById,
        action.payload.permissionId
      );
      const { permission } = response.data;
      yield put(actions.permissionFetched(permission));
    }
  } catch (error) {
    error.clientMessage = "Can't find permission";
    yield put(actions.catchError(error, callTypes.action));
  }
}

function* handleDeletePermission(action) {
  try {
    yield put(actions.startCall(callTypes.action));
    yield call(requestFromServer.upsertPermission, {
      id: action.payload.permissionId,
      active: action.payload.active,
      deleteData:true,
    });
    yield put(actions.permissionDeleted(action.payload.permissionId));
    action.payload.handlePostDelete();
  } catch (error) {
    error.clientMessage = "Can't delete permission";
    yield put(actions.catchError(error, callTypes.action));
  }
}

export function* saga() {
  yield takeLatest(actionTypes.UpsertPermission, handleUpsertPermission);
  yield takeLatest(actionTypes.FetchPermissions, handleFetchPermissions);
  yield takeLatest(actionTypes.FetchPermission, handleFetchPermissionById);
  yield takeLatest(actionTypes.DeletePermission, handleDeletePermission);
}
