import { actions } from "../app/modules/Auth/_redux/authRedux";
import { App_Urls } from "../app/Urls/urlConfig";
import { refreshToken } from "../app/modules/Auth/_redux/authCrud";

const LOGIN_URL = App_Urls.LOGIN_URL;
const REFRESH_URL = App_Urls.REFRESH_URL;

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      // Check the API endpoint and set the base URL accordingly
      switch (true) {
        case config.server1 || config.headers.server1:
          config.baseURL = process.env.REACT_APP_API_BASE_URL1;
          config.withCredentials = true;
          break;
        case config.server2 || config.headers.server2:
          config.baseURL = process.env.REACT_APP_API_BASE_URL2;
          config.withCredentials = false;
          break;
        default:
          config.baseURL = process.env.REACT_APP_API_BASE_URL;
          config.withCredentials = true;
      }

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response) => response,
    async (err) => {
      const originalConfig = err.config;
      if (
        originalConfig.url !== LOGIN_URL &&
        originalConfig.url !== REFRESH_URL &&
        err.response?.status === 401
      ) {
        try {
          const rs = await refreshToken();
          const { access_token, user, allowed_pages } = rs.data;
          store.dispatch(actions.login(access_token, user, allowed_pages));

          return axios(originalConfig);
        } catch (error) {
          if (error.response.status === 401) store.dispatch(actions.logout());
          return Promise.reject(error);
        }
      }

      return Promise.reject(err);
    }
  );
}
