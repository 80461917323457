import axios from "axios";
import { App_Urls } from "../../../Urls/urlConfig";

const LOGIN_URL = App_Urls.LOGIN_URL;
const REFRESH_URL = App_Urls.REFRESH_URL;
const LOGOUT_URL = App_Urls.LOGOUT_URL;
const CHANGE_PASSWORD_URL = App_Urls.CHANGE_PASSWORD_URL;

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function logout() {
  return axios.get(LOGOUT_URL);
}

export function refreshToken() {
  return axios.get(REFRESH_URL);
}

export function changePassword(old_password, new_password) {
  return axios.put(CHANGE_PASSWORD_URL, { old_password, new_password });
}
