import React from "react";
import ResumeTemplate2 from "./resumeComponents/Templates/Template2/ResumeTemplate2";
import { shallowEqual, useSelector } from "react-redux";
import { Card, CardBody } from "../../../../../_metronic/_partials/controls";

const TemplatePage = (props) => {
  // const [selectedTemplate, setSelectedTemplate] = useState(null);

  const { resume } = useSelector(
    (state) => ({
      resume: state.profiles.resume,
    }),
    shallowEqual
  );

  // const handleTemplateSelect = (template) => {
  //   setSelectedTemplate(template);
  // };

  // const renderTemplate = () => {
  //   switch (selectedTemplate) {
  //     case "template1":
  //       return <ResumeTemplate values={resume} />;
  //     case "template2":
  //       return <ResumeTemplate2 values={resume} />;
  //     default:
  //       return null;
  //   }
  // };

  return (
    <Card
      style={{
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      <CardBody>
        <ResumeTemplate2 values={resume} />
      </CardBody>
    </Card>
  );
};

export default TemplatePage;
