/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Formik, Form } from "formik";
import { injectIntl } from "react-intl";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { Alert, Header, Input } from "./components";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import { changePassword } from "../Auth/_redux/authCrud";
import { actions } from "../Auth/_redux/authRedux";

function ChangePassword(props) {
  const dispatch = useDispatch();
  // Fields
  const { intl } = props;
  const [loading, setloading] = useState(false);
  // Methods
  const handleChangePassword = (values, setStatus, setSubmitting) => {
    setloading(true);
    setStatus({});

    changePassword(values.currentPassword, values.password)
      .then(() => {
        setStatus({
          variant: "success",
          message: intl.formatMessage({ id: "AUTH.PASSWORD.UPDATE.SUCCESS" }),
        });
        setTimeout(() => {
          dispatch(actions.logout());
        }, 3000);
      })
      .catch(() => {
        setStatus({
          variant: "danger",
          message: intl.formatMessage({ id: "GENERAL.ERROR" }),
        });
      })
      .finally(() => {
        setloading(false);
        setSubmitting(false);
      });
  };
  // UI Helpers
  const initialValues = {
    currentPassword: "",
    password: "",
    cPassword: "",
  };

  const Schema = Yup.object().shape({
    currentPassword: Yup.string().required("Current password is required"),
    password: Yup.string()
      .min(8, "Minimum 8 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    cPassword: Yup.string()
      .required("Password confirmation is required")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });

  const formikProps = {
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      handleChangePassword(values, setStatus, setSubmitting);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  };

  return (
    <Formik {...formikProps}>
      {({ status, setStatus }) => (
        <Form className="card card-custom">
          {loading && <ModalProgressBar />}

          <Header
            heading="Change Password"
            subText="Change your account password"
          />

          {/* begin::Form */}
          <div className="form">
            <div className="card-body">
              {/* begin::Alert */}
              <Alert
                message={status?.message}
                variant={status?.variant}
                closeAlert={() => setStatus({})}
              />
              {/* end::Alert */}

              <Input
                type="password"
                label="Current Password"
                placeholder="Current Password"
                name="currentPassword"
              />

              <Input
                type="password"
                label="New Password"
                placeholder="New Password"
                name="password"
              />

              <Input
                type="password"
                label="Verify Password"
                placeholder="Verify Password"
                name="cPassword"
              />
            </div>
          </div>
          {/* end::Form */}
        </Form>
      )}
    </Formik>
  );
}

export default injectIntl(ChangePassword);
