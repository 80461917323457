import React from "react";
import { Badge } from "react-bootstrap";

export function CloseableBadges({ values, removeItem, variant, close = true ,className}) {
  return (
    <div className="d-flex flex-wrap my-2">
      {values?.map((selectedOption, index) => (
        <Badge
        
          key={selectedOption.id}
          variant={
            typeof variant === "function" ? variant(selectedOption) : "primary"
          }
          title={
            !selectedOption.active && typeof variant === "function"
              ? "Inactive skill"
              : ""
          }
          className={`d-flex align-items-center mr-2 mb-2 ${className}`}
        >
          {selectedOption.name}
          {close && (
            <button
              type="button"
              className="close d-flex align-items-center m-1 ml-2"
              data-dismiss="alert"
              aria-label="Delete"
              onClick={() => removeItem(index, selectedOption)}
              style={{
                backgroundColor: "transparent",
                border: "none",
                fontSize: "1.4rem",
                color: "#333333",
                cursor: "pointer",
              }}
            >
              &times;
            </button>
          )}
        </Badge>
      ))}
    </div>
  );
}
