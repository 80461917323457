import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as skills from "../app/modules/Resume/_redux/skills";
import * as jobRoles from "../app/modules/Resume/_redux/jobRoles";
import * as profiles from "../app/modules/Resume/_redux/profiles";
import * as permissions from "../app/modules/Access/_redux/permissions";
import * as roles from "../app/modules/Access/_redux/roles";
import * as users from "../app/modules/Access/_redux/users";
import * as build_Team from "../app/modules/Build team/_redux/buildTeam";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  skills: skills.reducer,
  jobRoles: jobRoles.reducer,
  profiles: profiles.reducer,
  permissions: permissions.reducer,
  roles: roles.reducer,
  users: users.reducer,
  build_team: build_Team.reducer,
});

export function* rootSaga() {
  yield all([
    skills.saga(),
    jobRoles.saga(),
    profiles.saga(),
    permissions.saga(),
    roles.saga(),
    users.saga(),
    build_Team.saga(),
  ]);
}
