import React from "react";
import { useField } from "formik";

export function Input({ children, label, ...restProps }) {
  const [field, meta] = useField(restProps);

  const getInputClasses = () => {
    if (meta.touched && meta.error) {
      return "is-invalid";
    }

    if (meta.touched && !meta.error) {
      return "is-valid";
    }

    return "";
  };

  return (
    <div className="form-group row">
      <label className="col-xl-3 col-lg-3 col-form-label text-alert">
        {label}
      </label>
      <div className="col-lg-9 col-xl-6">
        <input
          className={`form-control form-control-lg form-control-solid mb-2 ${getInputClasses()}`}
          {...field}
          {...restProps}
        />
        {meta.touched && meta.error ? (
          <div className="invalid-feedback">{meta.error}</div>
        ) : null}
        {children}
      </div>
    </div>
  );
}
