import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { actions } from "../../../_redux/jobRoles";
import { JobRoleEditDialogHeader } from "./JobRoleEditDialogHeader";
import { JobRoleEditForm } from "./JobRoleEditForm";
import { useJobRolesUIContext } from "../JobRolesUIContext";
import { NotAuthorizedMessage } from "../../../../../../_metronic/_helpers";

export function JobRoleEditDialog({ id, isEdit = true, show, onHide }) {
  // Job Roles UI Context
  const jobRolesUIContext = useJobRolesUIContext();
  const jobRolesUIProps = useMemo(() => {
    return {
      queryParams: jobRolesUIContext.queryParams,
      initJobRole: jobRolesUIContext.initJobRole,
    };
  }, [jobRolesUIContext]);

  // Job Roles Redux state
  const dispatch = useDispatch();
  const { actionsLoading, jobRoleForEdit, allowedPages } = useSelector(
    (state) => ({
      actionsLoading: state.jobRoles.actionsLoading,
      jobRoleForEdit: state.jobRoles.jobRoleForEdit,
      allowedPages: state.auth.allowedPages,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Job Role by id
    dispatch(actions.fetchJobRole(id));
  }, [id, dispatch]);

  // server request for saving job role
  const saveJobRole = (jobRole) => {
    dispatch(
      actions.upsertJobRole(
        jobRole,
        jobRolesUIProps.queryParams.pageSize,
        onHide
      )
    );
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {(isEdit &&
        (allowedPages.job_role?.create || allowedPages.job_role?.delete)) ||
      (!isEdit && allowedPages.job_role?.read) ? (
        <>
          <JobRoleEditDialogHeader id={id} isEdit={isEdit} onHide={onHide} />
          <JobRoleEditForm
            saveJobRole={saveJobRole}
            actionsLoading={actionsLoading}
            jobRole={{ ...jobRolesUIProps.initJobRole, ...jobRoleForEdit }}
            onHide={onHide}
            isEdit={isEdit}
          />
        </>
      ) : (
        <NotAuthorizedMessage />
      )}
    </Modal>
  );
}
