import React from "react";
import { useLocation } from "react-router";
import { shallowEqual, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";

import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuItem({ menuItem }) {
  const location = useLocation();
  const { allowedPages } = useSelector(
    (state) => ({ allowedPages: state.auth.allowedPages }),
    shallowEqual
  );

  if (!allowedPages[menuItem.key]) return <></>;

  const hasSubmenu = menuItem.submenu?.length > 0;
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
      ? ` ${
          !hasSubmenu && "menu-item-active"
        } menu-item-open menu-item-not-hightlighted`
      : "";
  };

  if (menuItem.section)
    return (
      <li className="menu-section">
        <h4 className="menu-text">{menuItem.text}</h4>
        <i className="menu-icon flaticon-more-v2"></i>
      </li>
    );

  return (
    <li
      className={`menu-item ${
        hasSubmenu ? "menu-item-submenu" : ""
      } ${getMenuItemActive(menuItem.href)}`}
      aria-haspopup="true"
      data-menu-toggle={hasSubmenu ? "hover" : undefined}
    >
      <NavLink
        className={`menu-link ${hasSubmenu ? "menu-toggle" : ""}`}
        to={menuItem.href}
      >
        <span className="svg-icon menu-icon">
          <SVG src={toAbsoluteUrl(menuItem.svgSrc)} />
        </span>
        <span className="menu-text">{menuItem.text}</span>
        {hasSubmenu && <i className="menu-arrow" />}
      </NavLink>

      {hasSubmenu && (
        <div className="menu-submenu ">
          <ul className="menu-subnav">
            <li className="menu-item  menu-item-parent" aria-haspopup="true">
              <span className="menu-link">
                <span className="menu-text">{menuItem.text}</span>
              </span>
            </li>

            {/*begin::2 Level*/}
            {menuItem.submenu.map((submenuItem, index) => (
              <li
                key={index}
                className={`menu-item ${getMenuItemActive(submenuItem.href)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to={submenuItem.href}>
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{submenuItem.text}</span>
                </NavLink>
              </li>
            ))}
            {/*end::2 Level*/}
          </ul>
        </div>
      )}
    </li>
  );
}
