import React from "react";
import { Typography } from "@material-ui/core";

const FormQuestion = ({ questions, activeStep }) => {
  return (
    <div className="questionnaire__questionTypography">
      <Typography>{questions[activeStep].body}</Typography>
    </div>
  );
};

export default FormQuestion;
