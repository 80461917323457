import React, { Suspense, useState, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { SkillsPage } from "./skills/SkillsPage";
import { JobRolesPage } from "./jobRoles/JobRolesPage";
import { ProfilesPage } from "./profiles/ProfilesPage";
import { InstantProfilesCard } from "../pages/instantProfiles/InstantProfilesCard";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import { ResumeCard } from "./resumeBuilder/ResumeCard";
import { SmartFeedbackCard } from "./smartFeedback/SmartFeedbackCard";
import TemplatePage from "./resumeBuilder/TemplatePage";
import { ProfileEditDialog } from "./profiles/profile-edit-dialog/ProfileEditDialog";
import { shallowEqual, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ResumePage() {
  const [showAlertBanner, setShowAlertBanner] = useState(false);
  const [remainingDays, setRemainingDays] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const { allowedPages, user, subscription_end_time } = useSelector(
    (state) => ({
      allowedPages: state.auth.allowedPages,
      user: state.auth.user,
      subscription_end_time: state.auth?.subscription_end_time,
    }),
    shallowEqual
  );

  const vendor = user?.is_primary_vendor;
  useEffect(() => {
    if (subscription_end_time) {
      const today = new Date();
      const expiryDate = new Date(subscription_end_time);

      const daysRemaining = Math.ceil(
        (expiryDate - today) / (1000 * 60 * 60 * 24)
      );

      if (vendor && daysRemaining <= 10 && daysRemaining >= 0) {
        setRemainingDays(daysRemaining);
        const lastShownDate = localStorage.getItem("lastShownDate");
        const today = new Date();

        if (
          !lastShownDate ||
          new Date(lastShownDate).getDate() !== today.getDate()
        ) {
          setShowAlertBanner(true);
        }
      } else {
        setShowAlertBanner(false);
      }
    }
  }, [subscription_end_time, vendor]);

  const handleAlertBannerClose = () => {
    setShowAlertBanner(false);
    setShowToast(false);
    // Update the last shown date in session or local storage
    const lastShownDate = new Date();
    localStorage.setItem("lastShownDate", lastShownDate);
  };

  const redirectToFirstAllowedPage = () => {
    const availablePages = {
      profile: "/resume/profiles",
      skill: "/resume/skills",
      job_role: "/resume/job-roles",
      instant_profile: "/resume/instant-profiles",
      profile_feedback: "/resume/smart-feedback",
      permission: "/access/permissions",
      role: "/access/roles",
      user: "/access/users",
      employee: "/access/employees",
    };

    for (const key of Object.keys(availablePages)) {
      if (allowedPages[key]?.read) {
        return (
          <Redirect exact={true} from="/resume" to={availablePages[key]} />
        );
      }
    }

    return <Redirect exact={true} from="/resume" to="/user-profile" />;
  };
  if (showAlertBanner && !showToast) {
    setShowToast(true);
    toast.info(`${remainingDays} days left until subscription expires!`, {
      autoClose: 8000,
      onClose: handleAlertBannerClose,
    });
  }
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <ToastContainer />

      <Switch>
        {redirectToFirstAllowedPage()}

        <Route path="/resume/skills" component={SkillsPage} />
        <Route path="/resume/job-roles" component={JobRolesPage} />
        <Route path="/resume/profiles" component={ProfilesPage} />
        <Route
          path="/resume/profiles-view-page/"
          component={ProfileEditDialog}
        />
        <Route
          path="/resume/instant-profiles"
          component={InstantProfilesCard}
        />
        <Route path="/resume/resume-builder" component={ResumeCard} />
        <Route path="/resume/resume-template" component={TemplatePage} />
        <Route path="/resume/smart-feedback" component={SmartFeedbackCard} />
      </Switch>
    </Suspense>
  );
}
