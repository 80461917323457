export const actionTypes = {
  CatchError: "[Catch User Error] Action",
  ResetError: "[Reset User Error] Action",
  StartCall: "[Start User Call] Action",
  FetchUser: "[Fetch User] Action",
  UserFetched: "[User Fetched] Action",
  EmployeeFetched: "[Employee Fetched] Action",
  FetchUsers: "[Fetch Users] Action",
  UsersFetched: "[Users Fetched] Action",
  RegisterUser: "[Register User] Action",
  RegisterEmployee: "[Register Employee] Action",
  FetchEmployee: "[Fetch Employee]Action",
  UserRegistered: "[User Registered] Action",
  UpdateUser: "[Update User] Action",
  UpdateEmployee: "[Update Employee] Action",
  UserUpdated: "[User Updated] Action",
  DeleteUser: "[Delete User] Action",
  UserDeleted: "[User Deleted] Action",
  Logout: "[Logout] Action",
};

export const callTypes = {
  list: "list",
  action: "action",
};

const initialUsersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  userForEdit: undefined,
  error: null,
};

export const actions = {
  catchError: (error, callType) => ({
    type: actionTypes.CatchError,
    payload: { error, callType },
  }),
  resetError: () => ({ type: actionTypes.ResetError }),
  startCall: (callType) => ({
    type: actionTypes.StartCall,
    payload: { callType },
  }),
  fetchUser: (id) => ({ type: actionTypes.FetchUser, payload: { userId: id } }),
  fetchEmployee: (id) => ({
    type: actionTypes.FetchEmployee,
    payload: { userId: id },
  }),
  userFetched: (user) => ({
    type: actionTypes.UserFetched,
    payload: { userForEdit: user },
  }),
  employeeFetched: (user) => ({
    type: actionTypes.EmployeeFetched,
    payload: { userForEdit: user },
  }),
  fetchUsers: (queryParams) => ({
    type: actionTypes.FetchUsers,
    payload: { queryParams },
  }),
  usersFetched: (totalCount, users) => ({
    type: actionTypes.UsersFetched,
    payload: { totalCount, entities: users },
  }),
  registerUser: (user, pageSize, onHide) => ({
    type: actionTypes.RegisterUser,
    payload: { user, pageSize, onHide },
  }),
  registerEmployee: (user, pageSize, onHide) => ({
    type: actionTypes.RegisterEmployee,
    payload: { user, pageSize, onHide },
  }),
  userRegistered: (user, pageSize) => ({
    type: actionTypes.UserRegistered,
    payload: { user, pageSize },
  }),
  updateUser: (user, onHide) => ({
    type: actionTypes.UpdateUser,
    payload: { user, onHide },
  }),
  updateEmployee: (user, onHide) => ({
    type: actionTypes.UpdateEmployee,
    payload: { user, onHide },
  }),
  userUpdated: (user) => ({
    type: actionTypes.UserUpdated,
    payload: { user },
  }),
  deleteUser: (id, handlePostDelete) => ({
    type: actionTypes.DeleteUser,
    payload: { userId: id, active: false, handlePostDelete },
  }),
  userDeleted: (id) => ({
    type: actionTypes.UserDeleted,
    payload: { userId: id },
  }),
  logout: () => ({ type: actionTypes.Logout }),
};

export const reducer = (state = initialUsersState, action) => {
  switch (action.type) {
    case actionTypes.CatchError: {
      const newState = { ...state };
      const { error, callType } = action.payload;

      newState.error = error.response
        ? `${error.response.data.message}`
        : "Internal server error";
      if (callType === callTypes.list) {
        newState.listLoading = false;
      } else {
        newState.actionsLoading = false;
      }
      return newState;
    }

    case actionTypes.ResetError: {
      const newState = { ...state };

      newState.error = null;
      return newState;
    }

    case actionTypes.StartCall: {
      const newState = { ...state };
      const { callType } = action.payload;

      newState.error = null;
      if (callType === callTypes.list) {
        newState.listLoading = true;
      } else {
        newState.actionsLoading = true;
      }
      return newState;
    }

    // getUserById
    case actionTypes.UserFetched: {
      const newState = { ...state };
      const { userForEdit } = action.payload;

      if (userForEdit) {
        userForEdit.role = {
          id: userForEdit.role.id,
          name: userForEdit.role.name,
        };
        userForEdit.roleSearchText = userForEdit.role.name;
      }

      newState.actionsLoading = false;
      newState.userForEdit = userForEdit;
      newState.error = null;

      return newState;
    }
    // getEmployeeById
    case actionTypes.EmployeeFetched: {
      const newState = { ...state };
      let { userForEdit } = action.payload;
      if (userForEdit.recipient_email === null) {
        userForEdit.recipient_email = "";
      }
      newState.actionsLoading = false;
      newState.userForEdit = userForEdit;
      newState.error = null;

      return newState;
    }

    case actionTypes.UsersFetched: {
      const newState = { ...state };
      const { totalCount, entities } = action.payload;

      newState.listLoading = false;
      newState.error = null;
      newState.entities = entities;
      newState.totalCount = totalCount;
      return newState;
    }

    case actionTypes.UserRegistered: {
      const newState = { ...state };
      const { user, pageSize } = action.payload;

      newState.actionsLoading = false;
      newState.error = null;
      if (newState.entities.length < pageSize)
        newState.entities = [...newState.entities, user];
      return newState;
    }

    case actionTypes.UserUpdated: {
      const newState = { ...state };
      const { user } = action.payload;

      newState.error = null;
      newState.actionsLoading = false;
      newState.entities = newState.entities.map((entity) => {
        if (entity.id === user.id) return user;
        return entity;
      });
      return newState;
    }

    case actionTypes.UserDeleted: {
      const newState = { ...state };
      const { userId } = action.payload;

      newState.error = null;
      newState.actionsLoading = false;
      newState.entities = newState.entities.filter((el) => el.id !== userId);
      return newState;
    }

    case actionTypes.Logout: {
      return initialUsersState;
    }

    default: {
      return state;
    }
  }
};
