// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Formik, Field, FieldArray } from "formik";
import * as Yup from "yup";
import Stars from "react-stars";
import { useDebouncedCallback } from "use-debounce";
import { actions as skillsActions } from "../../../_redux/skills";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { Modal, ModalBody } from "react-bootstrap";
import { ProfileDeploymentHistory } from "./ProfileDeploymentHistory";
import { Error } from "../../../../../../_metronic/_partials/controls";
import { BeatLoader } from "react-spinners";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardHeaderTitle,
} from "../../../../../../_metronic/_partials/controls";
import {
  Input,
  Select,
  MultiSelect,
  CloseableBadges,
} from "../../../../../../_metronic/_partials/controls";
import { QRCodeSVG } from "qrcode.react";
import copy from "clipboard-copy";
import { FaShare, FaTimes, FaDownload } from "react-icons/fa";
import { MdContentCopy } from "react-icons/md";

import domtoimage from "dom-to-image";

// Validation schema
const ProfileEditSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, "Minimum 1 symbol")
    .max(50, "Maximum 50 symbols")
    .required("Name is required"),
  approved: Yup.boolean(),
  rating: Yup.number()
    .min(0, "Rating must be atleast 1")
    .max(5, "Rating cannot be more than 5")
    .required("Rating is required"),
  skills: Yup.array().of(
    Yup.object().shape({
      id: Yup.number(),
      name: Yup.string(),
      active: Yup.boolean(),
      profileSkillActive: Yup.boolean().nullable(),
    })
  ),
  result: Yup.string(),
  resume: Yup.mixed().nullable().required("Resume is required"),
  deployment_history: Yup.array().of(
    Yup.object().shape({
      end_client: Yup.string().required("End client is required"),
      service_provider: Yup.string().nullable(),
      start_date: Yup.date().required("Start date is required"),
      final_completion: Yup.date().nullable(),
      expected_completion: Yup.date().required("Expected date is required"),
    })
  ),
  resource_type: Yup.string("Please Select Resource Type").required(
    "Resource Type is required"
  ),
  experience: Yup.object().shape({
    in_yrs: Yup.number()
      .required("Years of experience is required")
      .min(0, "Years of experience must be greater than or equal to 0"),
    in_months: Yup.number()
      .required("Months of experience is required")
      .min(0, "Months of experience must be greater than or equal to 0")
      .max(12, "Months of experience must be less than or equal to 12"),
  }),
  managed_by: Yup.string("Please Select Managed By").required(
    "Managed By is required"
  ),
  muoro_resume_link: Yup.string().url("Invalid URL format"),
});

export function ProfileEditForm({
  saveProfile,
  profile,
  actionsLoading,
  onHide,
  id,
}) {
  const {
    skillEntities,
    skillListLoading,
    editProfile,
    createProfile,
    error,
    serverResponse,
    profile_managers,
    build_team_mode,
  } = useSelector(
    (state) => ({
      skillEntities: state.skills.entities,
      skillListLoading: state.skills.listLoading,
      editProfile: state.auth.allowedPages.profile.update,
      createProfile: state.auth.allowedPages.profile.create,
      error: state.profiles.error,
      serverResponse: state.profiles.serverResponse,
      profile_managers: state.profiles.profile_managers,
      build_team_mode: state.build_team?.build_team_mode,
    }),
    shallowEqual
  );

  const [dropdownZIndex, setDropdownZIndex] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const handleSkillFieldClick = () => {
    setDropdownZIndex(1);
  };

  const dispatch = useDispatch();
  const history = useHistory();
  //Debouncing Skills Search
  const handleSkillSearchChange = (text) => {
    const queryParams = { filter: { search: text }, pageSize: 5 };
    dispatch(skillsActions.fetchSkills(queryParams));
  };

  const debouncedLoadOptions = useDebouncedCallback(
    handleSkillSearchChange,
    500
  );

  const handleAddSelectedItem = (selectedItem, skills, push) => {
    const existingSkillIndex = skills.findIndex(
      (skill) => skill.id === selectedItem.id
    );

    if (existingSkillIndex >= 0) {
      const existingSkill = skills[existingSkillIndex];

      if (existingSkill.profileSkillActive === false) {
        skills.splice(existingSkillIndex, 1);
        const newSkill = { ...selectedItem, profileSkillActive: true };
        push(newSkill);
      }
    } else {
      const newSkill = { ...selectedItem, profileSkillActive: true };
      push(newSkill);
    }
  };

  const handleRemoveItem = (skills, name, removedSkill, setFieldValue) => {
    const newItems = [...skills];

    const index = newItems.findIndex((item) => item.id === removedSkill);
    if (index !== -1) {
      const removedItem = newItems[index];
      newItems.splice(index, 1);
      const newSkill = { ...removedItem, profileSkillActive: false };
      newItems.push(newSkill);
      setFieldValue(name, newItems);
    }
  };

  function getBadgeVariant(selectedOption) {
    return selectedOption.active ? "primary" : "danger";
  }
  profile.email = profile.email === null ? "" : profile.email;
  profile.resource_type = profile.email === null ? "" : profile.resource_type;
  profile.phone_no = profile.phone_no === null ? "" : profile.phone_no;

  const handleClose = () => {
    if (build_team_mode) {
      history.push("/build-team/select-profiles");
      return;
    }
    onHide();
  };

  const [title, setTitle] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  // Title couting
  useEffect(() => {
    let _title = id ? "" : "New Profile";
    if (profile && id) {
      _title = ` ${profile.name}`;
    }
    if (_title === "New Profile") {
      setIsEdit(true);
    }
    setTitle(_title);

    // eslint-disable-next-line
  }, [profile, actionsLoading]);

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleProfileSave = (values, dirty, errors, setTouched) => {
    const touchedFields = {};
    Object.keys(errors).forEach((key) => {
      touchedFields[key] = true;
    });
    setTouched(touchedFields);

    if (Object.keys(errors).length > 0) return;
    if (dirty) {
      if (values.country_code.includes("+")) {
        const code = values.country_code.split("+");
        values.country_code = code[1];
      }
      saveProfile(values);

      if (!id) {
        setTimeout(() => {
          if (serverResponse) onHide();
        }, 5000);
      } else {
        setIsEdit(false);
      }
    } else {
      setIsEdit(false);
    }
  };

  const [message, setMessage] = useState("");

  const handleCancel = (dirty, resetForm) => {
    if (dirty && id) {
      resetForm();
      setIsEdit(false);
    } else {
      if (id) {
        setIsEdit(false);
      } else {
        handleClose();
      }
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const copyUrlToClipboard = () => {
    const currentUrl = window.location.href;
    copy(currentUrl);
  };

  function downloadQRCode() {
    const container = document.getElementById("qrcode-container");

    domtoimage
      .toPng(container)
      .then(function (dataUrl) {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `${profile.name}.png`;
        link.click();
      })
      .catch(function (error) {
        console.error("Error generating QR code PNG:", error);
      });
  }

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  };

  return (
    <Card>
      <Formik
        enableReinitialize={true}
        initialValues={profile}
        validationSchema={ProfileEditSchema}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          dirty,
          resetForm,
          errors,
          isValid,
          setTouched,
        }) => (
          <>
            {console.log(errors)}
            <CardHeader
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <CardHeaderTitle
                id="example-modal-sizes-title-lg"
                style={{ display: "flex", alignItems: "center" }}
              >
                {title}
              </CardHeaderTitle>
              {!build_team_mode ? (
                <div
                  style={{
                    display: "flex",
                    gap: "1.5rem",
                    flexDirection: "row-reverse",
                    padding: "5px",
                    alignItems: "center",
                    flexWrap: "wrap-reverse",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-md px-10"
                    title="close"
                    onClick={
                      !isEdit
                        ? handleClose
                        : () => handleCancel(dirty, resetForm)
                    }
                  >
                    {!isEdit ? "Close" : "Cancel"}
                  </button>
                  {(id && editProfile) || (!id && createProfile) ? (
                    <button
                      type="submit"
                      className="btn btn-outline-primary btn-md px-10"
                      title="close"
                      onClick={
                        !isEdit
                          ? handleEdit
                          : () =>
                              handleProfileSave(
                                values,
                                dirty,
                                errors,
                                setTouched
                              )
                      }
                      disabled={!id && !dirty}
                    >
                      {!isEdit ? "Edit" : "Save"}
                    </button>
                  ) : null}
                  {id && (
                    <Link
                      to={{
                        pathname: `/resume/profiles/${id}/view_resume`,
                        state: { resume: true },
                      }}
                      className="btn btn-outline-info"
                    >
                      View Resume
                    </Link>
                  )}
                  {id && (
                    <Link
                      to={{
                        pathname: `/resume/resume-builder`,
                        state: { resume: true },
                      }}
                      className="btn btn-outline-success"
                    >
                      Generate Resume
                    </Link>
                  )}
                  {id && (
                    <FaShare
                      onClick={openModal}
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                        color: "#007bff",
                      }}
                      title="Share Button"
                    />
                  )}
                </div>
              ) : (
                <button
                  type="button"
                  className="btn btn-outline-danger btn-md px-10"
                  title="back"
                  style={{height:'60%',margin:'auto 0 auto auto'}}
                  onClick={handleClose}
                >
                  back
                </button>
              )}
            </CardHeader>
            <CardBody className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: "5px",
                  }}
                >
                  <BeatLoader
                    size={15}
                    color={"#0099ff"}
                    loading={actionsLoading}
                  />
                </div>
              )}
              <Error
                message={error ? error : serverResponse}
                type={serverResponse ? "success" : ""}
              />
              <Modal show={isOpen} centered size="lg" onHide={closeModal}>
                <FaTimes
                  onClick={closeModal}
                  style={{
                    margin: "5 15 0 auto",
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                  }}
                />

                <ModalBody>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "1.5rem",
                        gap: "1rem",
                      }}
                    >
                      <a
                        style={{ fontSize: "1.2rem" }}
                        href={window.location.href}
                      >
                        {window.location.href}
                      </a>
                      <span onClick={copyUrlToClipboard}>
                        <MdContentCopy
                          style={{
                            width: "18px",
                            height: "18px",
                            cursor: "pointer",
                          }}
                        />
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "1rem",
                      }}
                    >
                      <div id="qrcode-container">
                        <QRCodeSVG value={window.location.href} size={200} />
                      </div>
                      <FaDownload
                        style={{
                          width: "15px",
                          height: "15px",
                          cursor: "pointer",
                        }}
                        onClick={downloadQRCode}
                      />
                    </div>
                  </div>
                </ModalBody>
              </Modal>
              <div className="form-group row mb-5">
                {/* Name */}
                <div className="col-lg-3 mb-5 mt-2">
                  {isEdit ? (
                    <span>
                      Enter Name
                      <span style={{ color: "red" }}> *</span>
                    </span>
                  ) : (
                    "Name"
                  )}

                  <Field
                    name="name"
                    component={Input}
                    placeholder="Name"
                    withFeedbackLabel={false}
                    disabled={!isEdit}
                  />
                </div>

                {/* Approved */}
                <div className="col-lg-3 mb-5">
                  <label>{isEdit ? "Select Approved" : "Approved"}</label>
                  <Select name="approved" disabled={!isEdit}>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Select>
                </div>

                <div className="col-lg-3 mb-5">
                  <label>{isEdit ? "Select Rating" : "Rating"}</label>
                  <div style={{ pointerEvents: isEdit ? "auto" : "none" }}>
                    <Stars
                      count={5}
                      value={values.rating}
                      onChange={(newRating) =>
                        setFieldValue("rating", newRating)
                      }
                      size={25}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group row mb-5">
                <div className="col-lg-3 mb-5">
                  <label>
                    {isEdit ? (
                      <span>
                        Select Resource Type
                        <span style={{ color: "red" }}> *</span>
                      </span>
                    ) : (
                      "Resource Type"
                    )}
                  </label>
                  <Select
                    name="resource_type"
                    disabled={!isEdit}
                    withFeedbackLabel={true}
                  >
                    <option value="" disabled hidden>
                      Select Resource Type
                    </option>
                    <option value="fulltime">Full Time</option>
                    <option value="consultant">Consultant </option>
                    <option value="freelancer">Freelancer </option>
                  </Select>
                </div>

                <div className="col-lg-6 mb-5">
                  <label style={{ marginLeft: "auto" }}>
                    {isEdit ? (
                      <span>
                        Enter Overall experience
                        <span style={{ color: "red" }}> *</span>
                      </span>
                    ) : (
                      "Overall experience"
                    )}
                  </label>
                  <div className="row">
                    <div
                      className="col-sm-6 d-flex "
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <Field
                        type="number"
                        name="experience.in_yrs"
                        component={Input}
                        placeholder="years"
                        withFeedbackLabel={false}
                        disabled={!isEdit}
                      />
                      <span>years</span>
                    </div>
                    <div
                      className="col-sm-6 d-flex"
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <Field
                        type="number"
                        name="experience.in_months"
                        component={Input}
                        placeholder="months"
                        withFeedbackLabel={false}
                        disabled={!isEdit}
                      />
                      <span>months</span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 mb-5">
                  <label>
                    {isEdit ? (
                      <span>
                        Select Managed By
                        <span style={{ color: "red" }}> *</span>
                      </span>
                    ) : (
                      "Managed By"
                    )}
                  </label>

                  <Select
                    name="managed_by"
                    disabled={!isEdit}
                    options={[
                      { id: "", name: "Please select", disabled: true },
                      ...(profile_managers && profile_managers.length > 0
                        ? profile_managers
                        : []),
                    ]}
                    withFeedbackLabel={true}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3">
                  <label>
                    {isEdit ? <span>Select Interviewer</span> : "Interviewer"}
                  </label>
                  <Select
                    name="interviewer"
                    disabled={!isEdit}
                    withFeedbackLabel={true}
                  >
                    <option value="" disabled hidden>
                      Select Interviewer
                    </option>
                    <option value="mukul">Mukul</option>
                    <option value="vyom">Vyom </option>
                    <option value="shubham">Shubham </option>
                  </Select>
                </div>

                <div className="col-lg-3">
                  <label>
                    {isEdit ? "Select Interview Date" : "Interview Date"}
                  </label>
                  <Field
                    name="interview_date"
                    type="date"
                    value={values.interview_date}
                    component={Input}
                    withFeedbackLabel={false}
                    onChange={(e) => {
                      setFieldValue("interview_date", e.target.value);
                    }}
                    disabled={!isEdit}
                    min={getCurrentDate()}
                  />
                </div>

                <div className="col-lg-3 mb-5">
                  <label>{isEdit ? "Select Available" : "Available"}</label>
                  <Select name="available" disabled={!isEdit}>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Select>
                </div>

                <div className="col-lg-3 mt-2">
                  {isEdit ? (
                    <span>Enter Muoro Resume Link</span>
                  ) : (
                    "Muoro Resume Link"
                  )}

                  <Field
                    name="muoro_resume_link"
                    component={Input}
                    placeholder="Muoro Resume Link"
                    withFeedbackLabel={true}
                    disabled={!isEdit}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3">
                  <label>
                    {isEdit ? (
                      <span>
                        Upload Resume
                        <span style={{ color: "red" }}> *</span>
                      </span>
                    ) : (
                      "Resume"
                    )}
                  </label>
                  <Field
                    name="resume"
                    component={Input}
                    type="file"
                    placeholder="Upload Resume"
                    accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                    disabled={!isEdit}
                  />
                </div>

                <div className="col-lg-3">
                  <label>{isEdit ? "Enter Email" : "Email"}</label>
                  <Field
                    name="email"
                    type="email"
                    value={values.email}
                    component={Input}
                    placeholder="Email"
                    withFeedbackLabel={false}
                    onChange={(e) => {
                      setFieldValue("email", e.target.value);
                    }}
                    disabled={!isEdit}
                  />
                </div>

                <div className="col-lg-3">
                  <label htmlFor="phone_no">
                    {isEdit ? "Enter Phone" : "Phone"}
                  </label>
                  <div style={{ display: "flex" }}>
                    <input
                      id="country_code"
                      name="country_code"
                      type="tel"
                      value={values.country_code}
                      placeholder="Code"
                      onChange={(e) => {
                        setFieldValue("country_code", e.target.value);
                      }}
                      disabled={!isEdit}
                      style={{
                        width: "50px",
                        fontSize: "14px",
                        backgroundColor: "#DCD9D9",
                        padding: "1px",
                        borderRadius: "5px 0 0 5px ",
                        border: "none",
                        textAlign: "center",
                      }}
                    />
                    <Field
                      id="phone_no"
                      name="phone_no"
                      type="tel"
                      component={Input}
                      value={values.phone_no}
                      placeholder="Phone"
                      onChange={(e) => {
                        setFieldValue("phone_no", e.target.value);
                      }}
                      disabled={!isEdit}
                      style={{
                        borderRadius: "0 5px 5px 0",
                      }}
                    />
                  </div>
                </div>

                <div className="col-lg-3">
                  <label>
                    {isEdit ? "Enter Resource Cost" : "Resource Cost"}
                  </label>
                  <Field
                    name="resource_cost"
                    type="number"
                    value={values.resource_cost}
                    component={Input}
                    placeholder="Resource Cost"
                    withFeedbackLabel={false}
                    onChange={(e) => {
                      setFieldValue("resource_cost", e.target.value);
                    }}
                    disabled={!isEdit}
                  />
                </div>
              </div>

              <div className="col-lg-3" style={{ padding: 0 }}>
                <label>{isEdit ? "Add Skills" : "Skills"}</label>
                {isEdit && (
                  <FieldArray name="skills">
                    {(arrayHelpers) => (
                      <MultiSelect
                        inputClassName="col-lg-12"
                        placeholder="Skills..."
                        searchValue={values.skillSearchText}
                        handleSearchChange={(text) => {
                          setFieldValue("skillSearchText", text);
                          debouncedLoadOptions(text);
                        }}
                        fetchResults={handleSkillSearchChange}
                        isLoading={skillListLoading}
                        handleItemSelect={(item) =>
                          handleAddSelectedItem(
                            item,
                            values.skills,
                            arrayHelpers.push,
                            handleSubmit
                          )
                        }
                        options={skillEntities}
                        badge={false}
                        zIndex={dropdownZIndex}
                        handleSkillFieldClick={handleSkillFieldClick}
                      />
                    )}
                  </FieldArray>
                )}
              </div>

              {values.skills?.length > 0 && (
                <div className="form-group row">
                  <div className="col-lg-12">
                    <CloseableBadges
                      key={values.skills.id}
                      values={values.skills.filter(
                        (skill) => skill.profileSkillActive !== false
                      )}
                      close={isEdit ? true : false}
                      variant={getBadgeVariant ? getBadgeVariant : "primary"}
                      removeItem={(index, removedOption) =>
                        handleRemoveItem(
                          values.skills,
                          "skills",
                          removedOption.id,
                          setFieldValue
                        )
                      }
                    />
                  </div>
                </div>
              )}

              <div
                className="form-group row"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginBottom: "20px",
                }}
              >
                {values.result || isEdit ? (
                  <div className="col-lg-12" style={{ width: "100%" }}>
                    <Field
                      name="result"
                      component={Input}
                      type="textarea"
                      placeholder="Result"
                      label="Result"
                      withFeedbackLabel={false}
                      disabled={!isEdit}
                      style={{
                        width: "100%",
                        minHeight: "100px",
                        padding: "10px",
                        fontSize: "16px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        backgroundColor: "#fff",

                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                        resize: "vertical",
                      }}
                    />
                  </div>
                ) : null}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    margin: "20px auto",
                    textAlign: "center",
                  }}
                >
                  {values.deployment_history?.length > 0 || isEdit ? (
                    <label
                      style={{
                        fontSize: "1.8rem",
                        fontWeight: "500",
                        marginBottom: "1rem",
                      }}
                    >
                      Deployment History
                    </label>
                  ) : null}
                  {showAlert && (
                    <Alert
                      variant={"info"}
                      dismissible
                      onClick={() => setShowAlert(false)}
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "400",
                        borderRadius: "1.5rem 0 1.5rem 0",
                      }}
                    >
                      {message}
                    </Alert>
                  )}
                  <ProfileDeploymentHistory
                    values={values}
                    setFieldValue={setFieldValue}
                    isEdit={isEdit}
                    setMessage={setMessage}
                    setShowAlert={setShowAlert}
                  />
                </div>
              </div>
            </CardBody>
          </>
        )}
      </Formik>
    </Card>
  );
}
