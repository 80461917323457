import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { LoadingDialog } from "../../../../../../_metronic/_partials/controls";

export function SkillsLoadingDialog() {
  //  Skills Redux state
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.skills.listLoading }),
    shallowEqual
  );

  return <LoadingDialog isLoading={isLoading} text="Loading..." />;
}
