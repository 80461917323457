export const actionTypes = {
  CatchError: "[Catch Skill Error] Action",
  StartCall: "[Start Skill Call] Action",
  ResetError: "[Reset Skill Error] Action",
  FetchSkill: "[Fetch Skill] Action",
  SkillFetched: "[Skill Fetched] Action",
  FetchSkills: "[Fetch Skills] Action",
  SkillsFetched: "[Skills Fetched] Action",
  UpsertSkill: "[Upsert Skill] Action",
  SkillCreated: "[Skill Created] Action",
  SkillUpdated: "[Skill Updated] Action",
  DeleteSkill: "[Delete Skill] Action",
  SkillDeleted: "[Skill Deleted] Action",
  UploadSkills: "[Upload Skills] Action",
  SkillsUploaded: "[Skills Uploaded] Action",
  Logout: "[Logout] Action",
};

export const callTypes = {
  list: "list",
  action: "action",
};

const initialSkillsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  skillForEdit: undefined,
  error: null,
};

export const actions = {
  catchError: (error, callType) => ({
    type: actionTypes.CatchError,
    payload: { error, callType },
  }),
  startCall: (callType) => ({
    type: actionTypes.StartCall,
    payload: { callType },
  }),
  resetError: () => ({ type: actionTypes.ResetError }),
  fetchSkill: (id) => ({
    type: actionTypes.FetchSkill,
    payload: { skillId: id },
  }),
  skillFetched: (skill) => ({
    type: actionTypes.SkillFetched,
    payload: { skillForEdit: skill },
  }),
  fetchSkills: (queryParams) => ({
    type: actionTypes.FetchSkills,
    payload: { queryParams },
  }),
  skillsFetched: (totalCount, skills) => ({
    type: actionTypes.SkillsFetched,
    payload: { totalCount, entities: skills },
  }),
  upsertSkill: (skill, pageSize, onHide) => ({
    type: actionTypes.UpsertSkill,
    payload: { skill, pageSize, onHide },
  }),
  skillCreated: (skill, pageSize) => ({
    type: actionTypes.SkillCreated,
    payload: { skill, pageSize },
  }),
  skillUpdated: (skill) => ({
    type: actionTypes.SkillUpdated,
    payload: { skill },
  }),
  deleteSkill: (id, handlePostDelete) => ({
    type: actionTypes.DeleteSkill,
    payload: { skillId: id, active: false, handlePostDelete },
  }),
  skillDeleted: (id) => ({
    type: actionTypes.SkillDeleted,
    payload: { skillId: id },
  }),
  uploadSkills: (file, handlePostUpload) => ({
    type: actionTypes.UploadSkills,
    payload: { file, handlePostUpload },
  }),
  skillsUploaded: () => ({ type: actionTypes.SkillsUploaded }),
  logout: () => ({ type: actionTypes.Logout }),
};

export const reducer = (state = initialSkillsState, action) => {
  switch (action.type) {
    case actionTypes.CatchError: {
      const newState = { ...state };
      const { error, callType } = action.payload;

      newState.error = error.response
        ? `${error.response.data.message}`
        : "Internal server error";

      if (callType === callTypes.list) {
        newState.listLoading = false;
      } else {
        newState.actionsLoading = false;
      }
      return newState;
    }

    case actionTypes.StartCall: {
      const newState = { ...state };
      const { callType } = action.payload;

      newState.error = null;
      if (callType === callTypes.list) {
        newState.listLoading = true;
      } else {
        newState.actionsLoading = true;
      }
      return newState;
    }

    case actionTypes.ResetError: {
      const newState = { ...state };

      newState.error = null;
      return newState;
    }

    // getSkillById
    case actionTypes.SkillFetched: {
      const newState = { ...state };
      const { skillForEdit } = action.payload;

      if (skillForEdit) {
        skillForEdit.variants =
          skillForEdit?.skill_variants?.map((variant) => variant.text) || [];
        delete skillForEdit.skill_variants;
      }

      newState.actionsLoading = false;
      newState.skillForEdit = skillForEdit;
      newState.error = null;
      return newState;
    }

    case actionTypes.SkillsFetched: {
      const newState = { ...state };
      const { totalCount, entities } = action.payload;

      newState.listLoading = false;
      newState.error = null;
      newState.entities = entities;
      newState.totalCount = totalCount;
      return newState;
    }

    case actionTypes.SkillCreated: {
      const newState = { ...state };
      const { skill, pageSize } = action.payload;

      newState.actionsLoading = false;
      newState.error = null;
      if (newState.entities.length < pageSize)
        newState.entities = [...newState.entities, skill];
      return newState;
    }

    case actionTypes.SkillUpdated: {
      const newState = { ...state };
      const { skill } = action.payload;

      newState.error = null;
      newState.actionsLoading = false;
      newState.entities = newState.entities.map((entity) => {
        if (entity.id === skill.id) return skill;
        return entity;
      });
      return newState;
    }

    case actionTypes.SkillDeleted: {
      const newState = { ...state };
      const { skillId } = action.payload;

      newState.error = null;
      newState.actionsLoading = false;
      newState.entities = newState.entities.filter((el) => el.id !== skillId);
      return newState;
    }

    case actionTypes.SkillsUploaded: {
      const newState = { ...state };

      newState.error = null;
      newState.actionsLoading = false;
      return newState;
    }

    case actionTypes.Logout: {
      return initialSkillsState;
    }

    default: {
      return state;
    }
  }
};
