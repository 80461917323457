import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "../../../../../_metronic/_partials/controls";
import { Button } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actions as build_team_actions } from "../../_redux/buildTeam/buildTeamRedux";
import TechStackComponent from "../techStackComponent/TechStackComponent";

const RecommendedStack = () => {
  const { selected_skills, server_response } = useSelector(
    (state) => ({
      selected_skills: state?.build_team?.selected_skills,
      server_response: state?.build_team?.server_response,
    }),
    shallowEqual
  );
  const [selected, setSelected] = useState(server_response[0]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (server_response.length > 0) handleSelect(selected);
  }, []);

  function handleSelect(item) {
    setSelected(item);

    const stackTypes = {
      be_stack: "backEnd",
      fe_stack: "frontEnd",
      qa_stack: "qa",
      devOps_stack: "devOps",
      full_stack: "fullStack",
    };

    Object.keys(stackTypes).forEach((stackType) => {
      if (item[stackType]) {
        selected_skills[stackTypes[stackType]] = [
          { name: item[stackType], id: item[stackType + "_id"] },
        ];
      }
    });

    selected_skills.fullStack = [
      ...selected_skills?.backEnd,
      ...selected_skills?.frontEnd,
    ];

    for (const techStack in selected_skills) {
      if (selected_skills[techStack]?.length > 0) {
        dispatch(build_team_actions.UpdateCurrentStack(techStack));
        break;
      }
    }

    dispatch(build_team_actions.SelectedSkills(selected_skills));
  }

  return (
    <Card
      className="uTSHome"
      style={{ height: "90vh", boxSizing: "border-box" }}
    >
      <CardBody
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        {server_response.length > 0 && (
          <h1
            style={{
              color: "#515444",
              fontSize: "3em",
              fontWeight: "600",
              marginBottom: "0.5em",
            }}
          >
           Congratulations! Your Tech Stack is Aligned
          </h1>
        )}
        {/* Recommended */}
        {server_response.length > 0 ? (
          <TechStackComponent
            title="Recommended"
            data={[server_response[0]]}
            selected={selected}
            handleSelect={handleSelect}
          />
        ) : (
          <p className="uTSHome__boldPurpleText">Sorry, No Recommendations </p>
        )}

        {/* alternate */}
        {server_response.length > 2 && (
          <TechStackComponent
            title="Alternatives"
            data={[...server_response.slice(1, 3)]}
            selected={selected}
            handleSelect={handleSelect}
          />
        )}
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Link to="/build-team/tech-stack-known">
            <Button
              variant="contained"
              style={{
                bottom: 0,
                left: 0,
                width: "auto",
                padding: "13px 42px",
                marginTop: "0em",
                backgroundColor: "#029863",
                color: "#fff",
                fontWeight: "bold",
                textTransform: "none",
                borderRadius: "2em",
                fontSize: "14px",
              }}
              disabled={selected && Object.keys(selected)?.length < 1}
            >
              Next
            </Button>
          </Link>
        </div>
      </CardBody>
    </Card>
  );
};

export default RecommendedStack;
