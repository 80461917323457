import React from "react";
import SVG from "react-inlinesvg";

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function Alert({ variant, message, closeAlert, icon = true }) {
  if (!message) return <></>;

  const svgVariant = variant === "success" ? "Done-circle" : "Info-circle";

  return (
    <div
      className={`alert alert-custom alert-light-${variant} fade show mb-10`}
      role="alert"
    >
      {icon && (
        <div className="alert-icon">
          <span className={`svg-icon svg-icon-3x svg-icon-${variant}`}>
            <SVG
              src={toAbsoluteUrl(`/media/svg/icons/Code/${svgVariant}.svg`)}
            ></SVG>
          </span>
        </div>
      )}
      <div className="alert-text font-weight-bold">{message}</div>
      <div className="alert-close" onClick={closeAlert}>
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i className="ki ki-close"></i>
          </span>
        </button>
      </div>
    </div>
  );
}
