import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { useHistory } from "react-router-dom";
import "./ThankYouPage.css";

const BuildTeamThankYouPage = () => {
  const [confetti, setConfetti] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setConfetti(true);
  }, []);

  function handleBack() {
    history.push("/build-team/home");
  }
  return (
    <div className="thank-you-container">
      {confetti && <Confetti numberOfPieces={500} recycle={false} />}
      <h1 className="thank-you-heading">Thank You!</h1>
      <p className="thank-you-message">
        Our team will get back to you shortly.
      </p>
      <div style={{ height: "10px",width:'100%', boxSizing: "border-box",marginTop:'2em' }}>
        <button
          size="large"
          className="thank-you-btn col-sm-1"
          onClick={handleBack}
         
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default BuildTeamThankYouPage;
