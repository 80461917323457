import React, { useState } from "react";
import { Button, Alert, Accordion, Card } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { Input } from "../../../../../_metronic/_partials/controls";
import axios from "axios";
import * as Yup from "yup";
import { BeatLoader } from "react-spinners";
import { App_Urls } from "../../../../Urls/urlConfig";

const feedBackSchema = Yup.object().shape({
  resume: Yup.mixed().required("Please upload your resume"),
  jd: Yup.mixed(),
  jd_text: Yup.string(),
});

export function SmartFeedback() {
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [variant, setVariant] = useState("");
  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append("resume", values.resume);
      formData.append("jd", values.jd);
      formData.append("jd_text", values.jd_text);

      setLoader(true);
      const url = App_Urls.SMART_FEEDBACK_URL;
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const feedback = response.data.feedback;
      if (feedback) {
        setShow(true);
        setVariant("success");
        setMessage(feedback);
        setLoader(false);
      }
    } catch (error) {
      setShow(true);
      setVariant("danger");
      setMessage(error.message);
      setLoader(false);
    }
  };
  const baseStyles = {
    fontSize: "1.4rem",
    fontFamily: "Arial, sans-serif",
    border: "1px solid #d6e9c6",
    textAlign: "center",
    marginTop: "20px",
  };

  const styles =
    variant === "danger"
      ? {
          ...baseStyles,
          backgroundColor: "#f2dede",
          color: "#a94442",
          border: "1px solid #ebccd1",
        }
      : {
          ...baseStyles,
          backgroundColor: "#dff0d8",
          color: "#3c763d",
        };

  return (
    <>
      {show && (
        <>
          <h2>Feedback:</h2>

          <Alert
            onClose={() => {
              setShow(false);
            }}
            dismissible
            style={styles}
          >
            {message}
          </Alert>
        </>
      )}

      {loader && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "50px",
          }}
        >
          <h3
            style={{
              marginBottom: "20px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Please Wait AI Generating Feedback
          </h3>
          <BeatLoader size={15} color={"#0099ff"} loading={loader} />
        </div>
      )}

      {!show && !loader && (
        <Accordion>
          <Card style={{ marginBottom: "20px" }}>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
              Disclosure 
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <p>
                  Your data privacy is a top priority for us. As part of our
                  ongoing efforts to provide you with valuable services, we
                  employ a third-party AI tool to enhance your experience and
                  offer intelligent insights.
                </p>
                <p>
                  When you interact with our platform, the data you provide may
                  be shared with the third-party AI tool. This tool processes
                  and analyzes the information you input, including text
                  interactions and inputs, in order to generate responses and
                  insights based on patterns it has learned from vast amounts of
                  text across the internet.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      )}
      <Formik
        initialValues={{
          resume: null,
          jd: null,
          jd_text: "",
        }}
        validationSchema={feedBackSchema}
        validate={(values) => {
          const errors = {};
          if (!values.jd && !values.jd_text) {
            errors.jd =
              "Please upload a job description file or enter job description text";
          }
          return errors;
        }}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ values, setFieldValue, errors }) => (
          <Form className="form form-label-right" autoComplete="off">
            {!show && !loader && (
              <div className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-4">
                    <label
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                      }}
                    >
                      Upload Resume
                    </label>
                    <Field
                      name="resume"
                      component={Input}
                      type="file"
                      placeholder="Upload Resume"
                      accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4">
                    <label
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                      }}
                    >
                      Upload Job description
                    </label>
                    <Field
                      name="jd"
                      component={Input}
                      type="file"
                      placeholder="Upload Resume"
                      accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                    />
                  </div>
                </div>
                <div
                  className="form-group row"
                  style={{
                    fontSize: "1.4rem",
                    fontWeight: "bold",
                    marginLeft: "2rem",
                  }}
                >
                  OR
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <label
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                      }}
                    >
                      Job Description
                    </label>
                    <Field
                      name="jd_text"
                      component={Input}
                      type="textarea"
                      placeholder="Describe about job role briefly for better feedback"
                      onChange={(event) => {
                        setFieldValue("jd_text", event.target.value);
                      }}
                    />
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    type="submit"
                    variant="outline-primary"
                    size="lg"
                    disabled={Object.keys(errors).length > 0}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
}
