import React, { useEffect, useRef } from "react";
import { Dropdown } from "react-bootstrap";

import { NoRecordsFoundMessage, PleaseWaitMessage } from "../../../_helpers";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, error) => {
  const classes = ["form-control", "col-lg-12"];
  if (touched && error) {
    classes.push("is-invalid");
  }

  if (touched && !error) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

const CustomInput = React.forwardRef(
  (
    {
      inputClassName,
      onClick,
      placeholder,
      searchValue,
      handleSearchChange,
      touched,
      error,
      fetchResults,
      isEdit,
      menuShow,
      className,
      // setMenuShow,
      handleSkillFieldClick,
    },

    ref
  ) => {
    useEffect(() => {
      fetchResults("");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        <input
          ref={ref}
          onClick={(e) => {
            onClick(e);
            menuShow.current = !menuShow.current;

            // setMenuShow((previousMenuShow)=>!previousMenuShow)
          }}
          onChange={(e) => {
            handleSearchChange(e.target?.value);
            handleSkillFieldClick();
            if (!menuShow.current) {
              onClick(e);
              menuShow.current = true;
              // setMenuShow(true);
            }
          }}
          disabled={!isEdit}
          type="text"
          placeholder={placeholder}
          value={searchValue}
          // onInput={(e) => handleSearchChange(e.target?.value)}

          className={`${getFieldCSSClasses(touched, error)} ${inputClassName} ${className}`}
          autoComplete="off"
        />
        <FieldFeedbackLabel error={error} touched={touched} />
      </>
    );
  }
);

CustomInput.displayName = "CustomInput";

export function AutocompleteSelect({
  inputClassName,
  placeholder,
  searchValue,
  className,
  handleSearchChange,
  handleItemSelect,
  isLoading = false,
  options,
  touched,
  error,
  fetchResults,
  isEdit = true,
  zIndex,
  handleSkillFieldClick,
}) {
  const menuShow = useRef(false);

  const handleToggle = (nextShow, meta, { source }) => {
    if (!source) menuShow.current = false;
  };

  return (
    <Dropdown onToggle={handleToggle}>
      <Dropdown.Toggle
        as={CustomInput}
        placeholder={placeholder}
        handleSearchChange={handleSearchChange}
        searchValue={searchValue}
        touched={touched}
        error={error}
        className={className}
        inputClassName={inputClassName}
        fetchResults={fetchResults}
        isEdit={isEdit}
        menuShow={menuShow}
        handleSkillFieldClick={handleSkillFieldClick}
        // setMenuShow={setMenuShow}
      />

      <Dropdown.Menu style={{ zIndex: zIndex }}>
        {isLoading ? (
          <Dropdown.Item>
            <PleaseWaitMessage entities={options} />
          </Dropdown.Item>
        ) : options?.length > 0 ? (
          options.map((opt) => (
            <Dropdown.Item
              key={opt.id}
              eventKey={opt.id}
              onClick={() => {
                // setMenuShow(false);
                menuShow.current = false;
                handleItemSelect(opt);
              }}
            >
              {opt.name}
            </Dropdown.Item>
          ))
        ) : (
          <Dropdown.Item>
            <NoRecordsFoundMessage entities={options} />
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
