import React from "react";
import { Field, FieldArray } from "formik";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
export function SkillsForm({ values, setFieldValue }) {
  return (
    <div>
      <FieldArray name="skills">
        {(arrayHelpers) => (
          <div>
            {values.skills.map((skill, index) => (
              <div key={index} className="mb-15">
                <div className="form-group row">
                  <div className="col-lg-3 mb-5">
                    <Field
                      name={`skills[${index}].skill_type`}
                      placeholder="Skill Type"
                      component="input"
                      type="text"
                      className="form-control"
                      onChange={(event) => {
                        setFieldValue(
                          `skills[${index}].skill_type`,
                          event.target.value
                        );
                      }}
                    />
                  </div>

                  <div className="col-lg-7 mb-5 mx-10">
                    <ReactQuill
                      value={values.skills[index].skill}
                      onChange={(value) => {
                        setFieldValue(`skills[${index}].skill`, value);
                      }}
                      placeholder="Skills Description"
                      style={{ height: "25rem" }}
                    />
                  </div>

                  <div className="col-lg-1 mb-5">
                    <button
                      type="button"
                      onClick={() => arrayHelpers.remove(index)}
                      className="btn btn-icon  btn-sm"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Trash.svg"
                          )}
                        />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            ))}

            <div className="form-group row">
              <div className="col-lg-12 mb-5">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() =>
                    arrayHelpers.push({
                      skill_type: "",
                      skill: "",
                    })
                  }
                >
                  Add Skill
                </button>
              </div>
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
}
