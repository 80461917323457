import React, { useState, useEffect } from "react";
import { Formik, Form, Field, FieldArray } from "formik";

import {
  CloseableBadges,
  Checkbox,
  Input,
} from "../../../../../_metronic/_partials/controls";
//import { ProfileDownload } from "../../../../util/profileDownload";
import { BeatLoader } from "react-spinners";
import * as Yup from "yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { actions as skillsActions } from "../../_redux/skills";
import { MultiSelect } from "../../../../../_metronic/_partials/controls";
import { Alert, Button } from "react-bootstrap";
import {
  searchProfilesBySkills,
  checkSkillExists,
} from "../../_redux/profiles/profilesCrud";

const validationSchema = Yup.object().shape({
  skillSearchText: Yup.string(),
  skills: Yup.array()
    .min(1, "Please add at least one skill.")
    .of(Yup.string().trim().required("Skill is required.")),
  exactMatch: Yup.boolean(),
});

export function InstantProfilesFilter() {
  const [dropdownZIndex, setDropdownZIndex] = useState("");

  const handleSkillFieldClick = () => {
    setDropdownZIndex(1);
  };

  const dispatch = useDispatch();
  const { skillListLoading, skillEntities } = useSelector(
    (state) => ({
      skillListLoading: state.skills.listLoading,
      skillEntities: state.skills.entities,
    }),
    shallowEqual
  );

  //Debouncing Skills Search
  const handleSkillSearchChange = (text) => {
    const queryParams = { filter: { search: text }, pageSize: 5 };
    dispatch(skillsActions.fetchSkills(queryParams));
  };

  const debouncedLoadOptions = useDebouncedCallback(
    handleSkillSearchChange,
    500
  );

  const handleRemoveItem = (values, name, index, setFieldValue) => {
    const newItems = [...values[name]];
    newItems.splice(index, 1);
    setFieldValue(name, newItems);
  };

  const handleAddSelectedItem = (item, baseSkills, setFieldValue) => {
    const index = baseSkills.findIndex((i) => i.id === item.id);
    if (index === -1) {
      const newBaseSkills = [...baseSkills, item];
      setFieldValue("baseSkills", newBaseSkills);
      setFieldValue("baseSkillSearchText", "");
    }
  };

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("secondary");
  const [loader, setLoader] = useState(false);
  const [allert, setAllert] = useState("");
  const [showAllert, setShowAllert] = useState(false);

  const handleKeyDown = async (
    e,
    skill,
    arrayHelpers,
    setFieldValue,
    skills
  ) => {
    if (e.key !== "Enter") return;

    e.preventDefault();

    const skillName = skill.trim();
    if (!skillName) return;

    // API call to check if skill exists in database
    const data = await checkSkillExists(skillName);

    if (data.skill) {
      setShowAllert(true);
      setAllert("Skill already exists in database");
      setVariant("danger");
    } else {
      if (skills.length < 5) {
        if (!skills.find((sk) => sk.name === skillName)) {
          arrayHelpers.push({ id: skillName, name: skillName });
          setFieldValue("skillSearchText", "");
        }
      } else {
        setShowAllert(true);
        setAllert("Maximum 5 skills can be added");
        setVariant("info");
      }
    }
  };

  const searchProfiles = async (skills, baseSkills, exactMatch) => {
    try {
      const skillNames = skills.map((skill) => skill.name);
      const baseSkill = baseSkills.map((skill) => skill.id);
      // API call to find profiles by  skills
      const response = await searchProfilesBySkills(
        skillNames,
        baseSkill,
        exactMatch
      );

      if (response.data.message) {
        setLoader(false);
        setShow(true);
        setMessage(response.data.message);
        setVariant("success");
      }
    } catch (error) {
      setMessage(error.message);
      setVariant("danger");
    }
  };

  const handleSubmit = async (values) => {
    setLoader(true);
    await searchProfiles(values.skills, values.baseSkills, values.exactMatch);
  };

  useEffect(() => {
    let timer;

    if (allert) {
      setShowAllert(true);
      timer = setTimeout(() => {
        setShowAllert(false);
        setAllert("");
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [allert]);

  return (
    <>
      {show && (
        <>
          <Alert
            variant={variant}
            onClose={() => {
              setShow(false);
            }}
            dismissible
            style={{
              fontSize: "1.4rem",
              fontFamily: "Arial, sans-serif",
              backgroundColor: "#dff0d8",
              color: "#3c763d",
              border: "1px solid #d6e9c6",
            }}
          >
            {message}
          </Alert>
        </>
      )}
      {loader && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "50px",
          }}
        >
          <h3
            style={{
              marginBottom: "20px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Please Wait Fetching Profiles
          </h3>
          <BeatLoader size={15} color={"#0099ff"} loading={loader} />
        </div>
      )}

      {!loader && !show && (
        <Formik
          initialValues={{
            skillSearchText: "",
            skills: [],
            baseSkillSearchText: "",
            baseSkills: [],
            exactMatch: false,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form className="form form-label-right" autoComplete="off">
              <div className="form-group row mb-3">
                <div className="col-lg-3 mb-3">
                  <label
                    style={{
                      fontSize: "1.4rem",
                      fontWeight: "500",
                    }}
                  >
                    Search Skills
                  </label>
                  <FieldArray name="skills">
                    {(arrayHelpers) => (
                      <Field
                        name="skillSearchText"
                        component={Input}
                        placeholder="Skills..."
                        onKeyDown={(e) =>
                          handleKeyDown(
                            e,
                            e.target.value,
                            arrayHelpers,
                            setFieldValue,
                            values.skills
                          )
                        }
                        noValidate
                      />
                    )}
                  </FieldArray>
                </div>
                <div className="col-lg-3 mb-3">
                  <label
                    style={{
                      fontSize: "1.4rem",
                      fontWeight: "500",
                    }}
                  >
                    Base Skills
                  </label>
                  <FieldArray name="skills">
                    <MultiSelect
                      inputClassName="col-lg-12"
                      placeholder="Base Skills..."
                      searchValue={values.baseSkillSearchText}
                      handleSearchChange={(text) => {
                        setFieldValue("baseSkillSearchText", text);
                        debouncedLoadOptions(text);
                      }}
                      fetchResults={handleSkillSearchChange}
                      isLoading={skillListLoading}
                      handleItemSelect={(item) =>
                        handleAddSelectedItem(
                          item,
                          values.baseSkills,
                          setFieldValue
                        )
                      }
                      options={skillEntities}
                      badge={false}
                      zIndex={dropdownZIndex}
                      handleSkillFieldClick={handleSkillFieldClick}
                    />
                  </FieldArray>
                </div>

                <div className="col-lg-2 d-flex align-items-center mt-5">
                  <Checkbox
                    isSelected={values.exactMatch}
                    onChange={() => {
                      setFieldValue("exactMatch", !values.exactMatch);
                    }}
                  >
                    Exact Match
                  </Checkbox>
                </div>
                <div className="col-lg-2 d-flex align-items-center mt-5">
                  <Button
                    type="submit"
                    variant="outline-success"
                    size="lg"
                    disabled={values.skills.length === 0}
                  >
                    Submit
                  </Button>
                </div>
              </div>

              {values.skills.length > 0 && (
                <div className="form-group row mb-1">
                  <label className="d-flex align-items-center mr-2">
                    Skills:
                  </label>
                  <CloseableBadges
                    values={values.skills}
                    removeItem={(index) =>
                      handleRemoveItem(values, "skills", index, setFieldValue)
                    }
                  />
                </div>
              )}

              {values.baseSkills.length > 0 && (
                <div className="form-group row mb-1">
                  <label className="d-flex align-items-center mr-2">
                    Base Skills
                  </label>
                  <CloseableBadges
                    values={values.baseSkills}
                    removeItem={(index) =>
                      handleRemoveItem(
                        values,
                        "baseSkills",
                        index,
                        setFieldValue
                      )
                    }
                  />
                </div>
              )}
            </Form>
          )}
        </Formik>
      )}
      {showAllert && (
        <Alert
          variant={variant}
          onClose={() => setShowAllert(false)}
          dismissible
          style={{ width: "fit-content" }}
        >
          {allert}
        </Alert>
      )}
    </>
  );
}
