import React from "react";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";

import axios from "axios";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const GoogleDrive = ({ setConnect, toast }) => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  function Googlebutton() {
    const login = useGoogleLogin({
      onSuccess: (codeResponse) => {
        axios
          .post(
            "/v1/admin/client_config",
            {
              code: codeResponse.code,
              storage: "gdrive",
            },
            {
              headers: {
                server1: true,
              },
            }
          )
          .then((response) => {
            setConnect(true);
            toast.success(response.data.message);
          })
          .catch((error) => {
            toast.error("Internal Server Error");
          });
      },
      flow: "auth-code",
      scope: "https://www.googleapis.com/auth/drive",
      onError: () => toast.error("Server Error"),
    });
    return (
      <>
        <button className="btn user-card-drive" onClick={() => login()}>
          <img
            src={toAbsoluteUrl("media/logos/drive.png")}
            alt="logo"
            width={"60px"}
            height={"50px"}
          />
        </button>
      </>
    );
  }
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Googlebutton />
    </GoogleOAuthProvider>
  );
};

export default GoogleDrive;
