import React from "react";
import _ from "lodash";

import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, error, noValidate = false) => {
  const classes = ["form-control"];
  if (touched && error) {
    classes.push("is-invalid");
  }

  if (!noValidate && touched && !error) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

function InputWrapper({
  values,
  type,
  field,
  setFieldValue,
  fieldTouched,
  fieldError,
  noValidate,
  ...props
}) {
  const id = field.name;
  if (type === "textarea")
    return (
      <textarea
        className={getFieldCSSClasses(fieldTouched, fieldError, noValidate)}
        {...field}
        {...props}
      />
    );

  if (type === "file")
    return (
      <div
        className={`custom-file ${getFieldCSSClasses(
          fieldTouched,
          fieldError,
          noValidate
        )}`}
      >
        <label
          className="custom-file-label text-nowrap overflow-hidden"
          htmlFor={id}
          role="button"
        >
          {values[field.name]?.name || values[field.name] || "Choose file"}
        </label>
        <input
          type="file"
          id={id}
          className="custom-file-input d-none cursor-pointer"
          {...field}
          {...props}
          value={undefined}
          onChange={(event) => {
            setFieldValue(field.name, event.currentTarget.files[0]);
          }}
        />
      </div>
    );

  return (
    <input
      type={type}
      className={getFieldCSSClasses(fieldTouched, fieldError, noValidate)}
      {...field}
      {...props}
    />
  );
}

export function Input({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  ...props
}) {
  const fieldTouched = _.get(touched, field.name);
  const fieldError = _.get(errors, field.name);

  return (
    <>
      {label && <label>Enter {label}</label>}

      <InputWrapper
        type={type}
        fieldError={fieldError}
        fieldTouched={fieldTouched}
        field={field}
        setFieldValue={setFieldValue}
        values={values}
        {...props}
      />

      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={fieldError}
          touched={fieldTouched}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
