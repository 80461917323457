import { call, put, takeLatest } from "redux-saga/effects";
import * as serverRequest from "./buildTeamCrud";
import { actionTypes, callTypes, actions } from "./buildTeamRedux";

function* handleFetchProfilesByTechStack(action) {
  try {
    yield put(actions.startCall(callTypes.list));
    const response = yield call(
      serverRequest.getAllProfilesByTechStack,
      action.payload.skill_ids
    );

    const { profiles } = response.data;

    yield put(actions.ProfilesFetchedByTechStack(profiles.rows));
  } catch (error) {
    error.clientMessage = "Can't find profiles";
    yield put(actions.catchError(error, callTypes.list));
  }
}

export function* saga() {
  yield takeLatest(
    actionTypes.FetchProfilesByTechStack,
    handleFetchProfilesByTechStack
  );
}
