import { call, put, takeLatest } from "redux-saga/effects";

import * as requestFromServer from "./usersCrud";
import { actionTypes, callTypes, actions } from "./usersRedux";

function* handleRegisterUser(action) {
  try {
    yield put(actions.startCall(callTypes.action));
    const response = yield call(
      requestFromServer.registerUser,
      action.payload.user
    );
    const { user } = response.data;

    yield put(actions.userRegistered(user, action.payload.pageSize));
    // Closes the Edit Modal
    action.payload.onHide();
  } catch (error) {
    error.clientMessage = "Can't register user";
    yield put(actions.catchError(error, callTypes.action));
  }
}
function* handleRegisterEmployee(action) {
  try {
    yield put(actions.startCall(callTypes.action));
    const response = yield call(
      requestFromServer.registerEmployee,
      action.payload.user
    );
    const { user } = response.data;

    yield put(actions.userRegistered(user, action.payload.pageSize));
    // Closes the Edit Modal
    action.payload.onHide();
  } catch (error) {
    error.clientMessage = "Can't register Employee";
    error.clientMessage = "Can't register Employee";
    yield put(actions.catchError(error, callTypes.action));
  }
}

function* handleUpdateUser(action) {
  try {
    yield put(actions.startCall(callTypes.action));
    const response = yield call(
      requestFromServer.udpateUser,
      action.payload.user
    );
    const { user } = response.data;

    yield put(actions.userUpdated(user));
    // Closes the Edit Modal
    action.payload.onHide();
  } catch (error) {
    error.clientMessage = "Can't update user";
    yield put(actions.catchError(error, callTypes.action));
  }
}
function* handleUpdateEmployee(action) {
  try {
    yield put(actions.startCall(callTypes.action));
    const response = yield call(
      requestFromServer.updateEmployee,
      action.payload.user
    );
    const { user } = response.data;

    yield put(actions.userUpdated(user));
    // Closes the Edit Modal
    action.payload.onHide();
  } catch (error) {
    error.clientMessage = "Can't update Employee";
    yield put(actions.catchError(error, callTypes.action));
  }
}


function* handleFetchUsers(action) {
  try {
    yield put(actions.startCall(callTypes.list));
    const response = yield call(
      requestFromServer.getAllUsers,
      action.payload.queryParams
    );
    const { users } = response.data;
    yield put(actions.usersFetched(users.count, users.rows));
  } catch (error) {
    error.clientMessage = "Can't find users";
    yield put(actions.catchError(error, callTypes.list));
  }
}

function* handleFetchUserById(action) {
  try {
    if (!action.payload.userId) yield put(actions.userFetched());
    else {
      yield put(actions.startCall(callTypes.action));
      const response = yield call(
        requestFromServer.getUserById,
        action.payload.userId
      );
      const { user } = response.data;
      yield put(actions.userFetched(user));
    }
  } catch (error) {
    error.clientMessage = "Can't find user";
    yield put(actions.catchError(error, callTypes.action));
  }
}
function* handleFetchEmployeeById(action) {
  try {
    if (!action.payload.userId) yield put(actions.userFetched());
    else {
      yield put(actions.startCall(callTypes.action));
      const response = yield call(
        requestFromServer.getEmployeeById,
        action.payload.userId
      );
    
      const { user } = response.data;
      yield put(actions.employeeFetched(user));
    }
  } catch (error) {
    error.clientMessage = "Can't find Employee";
    error.clientMessage = "Can't find Employee";
    yield put(actions.catchError(error, callTypes.action));
  }
}

function* handleDeleteUser(action) {
  try {
    yield put(actions.startCall(callTypes.action));
    yield call(requestFromServer.udpateUser, {
      id: action.payload.userId,
      active: action.payload.active,
      deleteData:true,
    });
    yield put(actions.userDeleted(action.payload.userId));
    action.payload.handlePostDelete();
  } catch (error) {
    error.clientMessage = "Can't delete user";
    yield put(actions.catchError(error, callTypes.action));
  }
}

export function* saga() {
  yield takeLatest(actionTypes.RegisterUser, handleRegisterUser);
  yield takeLatest(actionTypes.RegisterEmployee, handleRegisterEmployee);
  yield takeLatest(actionTypes.UpdateUser, handleUpdateUser);
  yield takeLatest(actionTypes.UpdateEmployee, handleUpdateEmployee);
  yield takeLatest(actionTypes.UpdateEmployee, handleUpdateEmployee);
  yield takeLatest(actionTypes.FetchUsers, handleFetchUsers);
  yield takeLatest(actionTypes.FetchUser, handleFetchUserById);
  yield takeLatest(actionTypes.FetchEmployee, handleFetchEmployeeById);
  yield takeLatest(actionTypes.DeleteUser, handleDeleteUser);
}
