// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import * as jobRolesRedux from "../../../_redux/jobRoles";
import * as uiHelpers from "../JobRolesUIHelpers";
import {
  PaginatedTable,
  ActionsColumnFormatter,
  BooleanColumnFormatter,
} from "../../../../../../_metronic/_partials/controls";
import { useJobRolesUIContext } from "../JobRolesUIContext";

export function JobRolesTable() {
  // Job Roles UI Context
  const jobRolesUIContext = useJobRolesUIContext();
  const jobRolesUIProps = useMemo(() => {
    return {
      queryParams: jobRolesUIContext.queryParams,
      setQueryParams: jobRolesUIContext.setQueryParams,
      openViewJobRoleDialog: jobRolesUIContext.openViewJobRoleDialog,
      openEditJobRoleDialog: jobRolesUIContext.openEditJobRoleDialog,
      openDeleteJobRoleDialog: jobRolesUIContext.openDeleteJobRoleDialog,
    };
  }, [jobRolesUIContext]);

  // Getting curret state of job roles list from store (Redux)
  const { currentState, allowedPages } = useSelector(
    (state) => ({
      currentState: state.jobRoles,
      allowedPages: state.auth.allowedPages,
    }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // Job Roles Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // server call by queryParams
    dispatch(jobRolesRedux.actions.fetchJobRoles(jobRolesUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobRolesUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "ID",
      style:{
        color:'#2C3E50',
        fontWeight:'500'
      }
    },
    {
      dataField: "name",
      text: "Name",
      style:{
        color:'#2C3E50',
        fontWeight:'500'
      }
    },
    {
      dataField: "active",
      text: "Active",
      formatter: BooleanColumnFormatter,
      formatExtraData: { field: "active" },
    },

    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        viewTitle: allowedPages.job_role?.read && "View Job Role",
        openViewDialog: jobRolesUIProps.openViewJobRoleDialog,
        editTitle: allowedPages.job_role?.update && "Edit Job Role",
        openEditDialog: jobRolesUIProps.openEditJobRoleDialog,
        deleteTitle: allowedPages.job_role?.delete && "Delete Job Role",
        openDeleteDialog: jobRolesUIProps.openDeleteJobRoleDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: jobRolesUIProps.queryParams.pageSize,
    page: jobRolesUIProps.queryParams.pageNumber,
  };

  return (
    <PaginatedTable
      paginationOptions={paginationOptions}
      listLoading={listLoading}
      entities={entities}
      columns={columns}
      setQueryParams={jobRolesUIProps.setQueryParams}
    />
  );
}
