import React from "react";
import { Avatar } from "@material-ui/core";

const getInitials = (name) => {
  const nameSplit = name.split(" ");
  const initials = nameSplit.map((part) => part.charAt(0));
  return initials.join("").toUpperCase();
};

const ColorAvatar = ({ name }) => {
  const color = "#d35400";
  const initials = getInitials(name);

  return <Avatar style={{ backgroundColor: color }}>{initials}</Avatar>;
};

export default ColorAvatar;
