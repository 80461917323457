/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import "./authpage.css";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";

export function AuthPage() {
  const year = new Date().getFullYear();

  return (
    <>
      <div
        id="auth-container"
        style={{ display: "flex", height: "100vh", flexWrap: "wrap" }}
      >
        {/* Left Side Muoro Page */}
        <div
          id="auth-left"
          style={{
            //backgroundImage: `url(${toAbsoluteUrl("/media/bg/purple1.png")})`,
            background: "linear-gradient(135deg, #667eea 0%, #764ba2 100%)",
            flex: 1,
            position: "relative",
            minWidth: "40%",
            height: "100%",
            backgroundSize: "cover",
            boxSizing: "border-box",
          }}
        >
          
          <div
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              rowGap: "2rem",
              justifyContent: "center",
              height: "70%",
              position: "absolute",
              top: "10%",
              padding: "1.5rem",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "1rem",
                width: "100%",
                height: "auto",
                alignItems: "center",
              }}
            >
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/muoro-logo.svg")}
                width={"20%"}
              />
              
            </div>
            <p
              id="right-para"
              style={{
                fontSize: "1.8rem",
                fontWeight: 400,
                color: "#FDFEFE",
                width: "90%",
                margin: "0 auto",
                textShadow: "2px 2px 2px rgba(0, 0, 0, 0.3)",
                textAlign: "center",
              }}
            >
              Build remote engineering teams with Muoro providing dedicated
              remote software engineer & development teams, offshore engineers
              and hire remote software developers.
            </p>
          </div>
          <div
            id="auth-footer"
            style={{
              position: "absolute",
              bottom: "2rem",
              right: "2rem",
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <div className="text-white">
              <a
                href="https://muoro.io"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white"
              >
                &copy;{year}Muoro
              </a>
            </div>
            <div>
              <a
                href="https://muoro.io/terms-conditions"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white"
              >
                Terms
              </a>
              <a
                href="https://muoro.io/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white ml-10"
              >
                Privacy
              </a>
              <a
                href="https://www.muoro.io/contact"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white ml-10"
              >
                Contact
              </a>
            </div>
          </div>
        </div>

        {/* Right Side Login Page */}
        <div
          id="auth-right"
          style={{
            flex: 1,
            minWidth: "30%",
            boxSizing: "border-box",
            height: "100%",
            background: "#ffffff",
          }}
        >
         
              
              
           
          <div id="right-login-div">
            <Switch>
              <ContentRoute path="/auth/login" component={Login} />

              <Redirect from="/auth" exact={true} to="/auth/login" />
              <Redirect to="/auth/login" />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
}
