import React, { useRef } from "react";
import "./Template2.css";
import ReactToPrint from "react-to-print";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers";
import { useHistory } from "react-router-dom";

import { actions } from "../../../../../_redux/profiles";
import { useDispatch } from "react-redux";

const ResumeTemplate2 = ({ values }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    firstname,
    lastname,
    summary,
    skills,
    work_experience,
    education,
    projects,
    certifications,
  } = values;

  const containerRef2 = useRef(null);

  const handleEdit = () => {
    const path = "/resume/resume-builder";
    dispatch(actions.ResumeForEdit(true));
    history.push(path);
  };

  return (
    <>
      <div
        className="text-right"
        style={{ display: "flex", flexDirection: "row-reverse", gap: "1rem" }}
      >
        <ReactToPrint
          trigger={() => <button className="button-88">Download</button>}
          content={() => containerRef2.current}
          documentTitle={`${firstname}_muoro`}
          options={{
            header: null,
            footer: null,
          }}
        />

        <button className="button-89" onClick={handleEdit}>
          Edit
        </button>
      </div>
      <div className="r-container" id="resumePreview" ref={containerRef2}>
        <div className="resumeBuilder-watermark">
          <img
            src={toAbsoluteUrl("/media/logos/logo-with-letter.png")}
            width={"100px"}
            height={"100px"}
            alt="logo"
          />
        </div>
        <header>
          <h1>{`${firstname} ${lastname}`}</h1>
        </header>

        <section>
          <h2 className="r-sub-headings">Professional Summary</h2>

          <div
            className="r-summary"
            dangerouslySetInnerHTML={{ __html: summary }}
          />
        </section>

        {education?.length >= 1 && (
          <div className="section">
            <h2 className="r-sub-headings">Education </h2>

            {education &&
              education?.map((edu, index) => (
                <div key={index} className="r-education">
                  <div
                    className="r-exp-item"
                    style={{ position: "relative", width: "100%" }}
                  >
                    {edu.name !== "" && (
                      <h4 className="secondary-heading">{`${edu.name}`}</h4>
                    )}
                    {edu.end_date && (
                      <p
                        className="secondary-heading"
                        style={{ position: "absolute", right: 0 }}
                      >{`${edu.end_date}`}</p>
                    )}
                  </div>
                  <p className="secondary-heading">{edu.organization}</p>
                </div>
              ))}
          </div>
        )}
        {skills?.length > 0 ? (
          <div className="section">
            <h2 className="r-sub-headings">Skills </h2>

            {skills?.map((skil, index) => (
              <div className="r-skills" key={index}>
                <span className="secondary-heading">
                  {skil.skill_type || skil.skill_type !== ""
                    ? skil.skill_type
                    : "Programming Skills"}
                </span>
                <span>:</span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: skil.skill,
                  }}
                ></span>
              </div>
            ))}
          </div>
        ) : null}

        {work_experience?.length >= 1 ? (
          <div className="r-experience">
            <h2 className="r-sub-headings">Experience</h2>

            {work_experience &&
              work_experience.map((exp, index) => (
                <div key={index} style={{ marginBottom: "5rem" }}>
                  <div
                    className="r-exp-item"
                    style={{ position: "relative", width: "100%" }}
                  >
                    {exp.job_title !== "" && (
                      <h4 className="secondary-heading">{`${exp.job_title}`}</h4>
                    )}
                    {exp.start_date && (
                      <span
                        className="secondary-heading"
                        style={{ position: "absolute", right: 0 }}
                      >
                        {`${exp.start_date} - `}
                        {exp.is_current ? "Till-Present" : exp.end_date}
                      </span>
                    )}
                  </div>
                  {exp.organization !== "" && (
                    <p className="secondary-heading">{exp.organization}</p>
                  )}
                  <div
                    className="r-summary"
                    dangerouslySetInnerHTML={{ __html: exp.description }}
                  />
                </div>
              ))}
          </div>
        ) : null}

        {certifications?.length >= 1 && (
          <div className="section">
            <h2 className="r-sub-headings">Certifications </h2>

            {certifications.map((certificate, idx) => (
              <p
                className="secondary-heading"
                style={{ display: "list-item" }}
                key={idx}
              >
                {certificate}
              </p>
            ))}
          </div>
        )}

        {projects?.length >= 1 && (
          <div className="r-projects">
            <h2 className="r-sub-headings">Projects </h2>

            {projects &&
              projects.map((proj, index) => (
                <div key={index} className=" r-projects-list">
                  {proj.projectName && proj.projectName.trim() !== "" && (
                    <h4 className="r-subheader">
                      <span className="project-headings">Project Name</span>
                      <span className="project-headings">:</span>
                      <span className="project-headings">{`${proj.projectName}`}</span>
                    </h4>
                  )}
                  {proj.role && proj.role.trim() !== "" && (
                    <h4 className="r-subheader">
                      <span className="project-headings">Role</span>
                      <span className="project-headings">:</span>
                      <span className="project-headings">
                        {" "}
                        {`${proj.role}`}
                      </span>
                    </h4>
                  )}
                  {proj.start_date && (
                    <h4 className="r-subheader">
                      <span className="project-headings">Duration</span>
                      <span className="project-headings">:</span>
                      <span className="project-headings">
                        {" "}
                        {`${proj.start_date} - `}
                        {`${proj.end_date}`}
                      </span>
                    </h4>
                  )}
                  {proj.companyName && proj.companyName.trim() !== "" && (
                    <h4 className="r-subheader">
                      <span className="project-headings">Company Name</span>
                      <span className="project-headings">:</span>
                      <span className="project-headings">{`${proj.companyName}`}</span>
                    </h4>
                  )}

                  {proj.techStack && proj.techStack.trim() !== "" && (
                    <h4 className="r-subheader">
                      <span className="project-headings">Tech Stack</span>
                      <span className="project-headings">:</span>
                      <span className="project-headings">{`${proj.techStack}`}</span>
                    </h4>
                  )}

                  <br />
                  <h4 className="project-headings">Responsibilities</h4>
                  <div
                    className="r-summary"
                    dangerouslySetInnerHTML={{ __html: proj.description }}
                  />
                </div>
              ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ResumeTemplate2;
