/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import HomePage from "./modules/home/HomePage";
import BuildTeamLayout from "./modules/Build team/BuildTeamLayout";
import BuildTeamPage from "./modules/Build team/BuildTeamPage";
export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );
  const location = useLocation();
  return (
    <Switch>
      <Route exact path="/" component={HomePage} />
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route path="/auth/login">
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/resume" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /* Redirect to `/auth` when the user is not authorized */
        <Redirect to="/auth/login" />
      ) : (
        <>
          {location.pathname.startsWith("/build-team") ? (
            <Route path="/build-team">
              <BuildTeamLayout>
                <BuildTeamPage />
              </BuildTeamLayout>
            </Route>
          ) : (
            <Route path="/">
              <Layout>
                <BasePage />
              </Layout>
            </Route>
          )}
        </>
      )}
    </Switch>
  );
}
