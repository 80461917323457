// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, FieldArray } from "formik";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { actions } from "../../../_redux/skills";
import {
  Error,
  Input,
  MultiSelect,
  Select,
} from "../../../../../../_metronic/_partials/controls";

// Validation schema
const JobRoleEditSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, "Minimum 1 symbol")
    .max(50, "Maximum 50 symbols")
    .required("Name is required"),
  active: Yup.boolean(),
  skillSearchText: Yup.string().min(1, "Minimum 1 symbol"),
  skills: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
        name: Yup.string(),
      })
    )
    .min(1, "Minimum 1 skill required"),
});

export function JobRoleEditForm({
  saveJobRole,
  isEdit = true,
  jobRole,
  actionsLoading,
  onHide,
}) {
  const { skillListLoading, entities, errorMsg } = useSelector(
    (state) => ({
      skillListLoading: state.skills.listLoading,
      entities: state.skills.entities,
      errorMsg: state.jobRoles.error,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const handleSkillSearchChange = (text) => {
    const queryParams = { filter: { search: text }, pageSize: 5 };
    dispatch(actions.fetchSkills(queryParams));
  };
  const handleAddSkill = (selectedSkill, skills, push) => {
    if (!skills.find((skill) => skill.id === selectedSkill.id))
      push(selectedSkill);
  };

  const [dropdownZIndex, setDropdownZIndex] = useState("");

  const handleSkillFieldClick = () => {
    setDropdownZIndex(1);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={jobRole}
        validationSchema={JobRoleEditSchema}
        onSubmit={(values) => {
          saveJobRole(values);
        }}
      >
        {({ values, setFieldValue, handleSubmit, touched, errors, dirty }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}

              <Error message={errorMsg} />

              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* Name */}
                  <div className="col-lg-4">
                    <label>{isEdit ? "Enter Job Role" : "Job Role"}</label>
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Name"
                      disabled={!isEdit}
                    />
                  </div>

                  {/* Active */}
                  <div className="col-lg-4">
                    <label>{isEdit ? "Select Active" : "Active"}</label>
                    <Select name="active" disabled={!isEdit}>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </Select>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-12">
                    <label>{isEdit ? "Select Skills" : "Skills"}</label>
                    
                    <FieldArray name="skills">
                      {(arrayHelpers) => (
                        <MultiSelect
                          searchValue={values.skillSearchText}
                          handleSearchChange={(text) => {
                            setFieldValue("skillSearchText", text);
                            handleSkillSearchChange(text);
                          }}
                          fetchResults={handleSkillSearchChange}
                          isLoading={skillListLoading}
                          removeItem={arrayHelpers.remove}
                          handleItemSelect={(item) =>
                            handleAddSkill(
                              item,
                              values.skills,
                              arrayHelpers.push
                            )
                          }
                          options={entities}
                          selected={values.skills}
                          touched={touched.skillSearchText}
                          error={errors.skills}
                          isEdit={isEdit}
                          zIndex={dropdownZIndex}
                          handleSkillFieldClick={handleSkillFieldClick}
                        />
                      )}
                    </FieldArray>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            {isEdit && (
              <Modal.Footer>
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate"
                  disabled={!dirty}
                >
                  Save
                </button>
              </Modal.Footer>
            )}
          </>
        )}
      </Formik>
    </>
  );
}
