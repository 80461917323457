export function ProfileDownload(response) {
  // Create ObjectURL from response data
  const href = URL.createObjectURL(response.data);

  // Extract filename from response headers
  const contentDisposition = response.headers["content-disposition"];
  const filename = contentDisposition.split("filename=")[1];

  // Create "a" HTML element with href to file & click
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();

  // Clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}
