import React from "react";
import { Card,CardBody } from "../../../../_metronic/_partials/controls";
import './styles.scss';
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

const UnknownTechStackHome = () => {
  return (
    <Card className="uTSHome" style={{ height: "90vh", boxSizing: "border-box", padding: "2em" }}>
      <CardBody
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <h3 className="uTSHome__boldPurpleText">30 seconds. 5 quick questions</h3>
        <h1 className="uTSHome__largeBlackText ">
          Let's find your perfect <br /> app-building solution
        </h1>
        <h3 className="uTSHome__subHeading">
          Depending on the complexity of your app, you have different <br />
          developer options.
        </h3>

        <Link to="/build-team/questionnaire">
          <Button
            variant="contained"
            style={{
              bottom: 0,
              left: 0,
              width: "100%",
              padding: "13px 42px" ,
              marginTop: "2em",
              backgroundColor: "#029863",
              color:'#ffffff',
              fontWeight: "bold",
              textTransform: "none",
              fontSize: "1.1em",
            }}
          >
            Let's go
          </Button>
        </Link>
      </CardBody>
    </Card>
  );
};

export default UnknownTechStackHome;
