export const actionTypes = {
  CatchError: "[Catch Permission Error] Action",
  ResetError: "[Reset Permission Error] Action",
  StartCall: "[Start Permission Call] Action",
  FetchPermission: "[Fetch Permission] Action",
  PermissionFetched: "[Permission Fetched] Action",
  FetchPermissions: "[Fetch Permissions] Action",
  PermissionsFetched: "[Permissions Fetched] Action",
  UpsertPermission: "[Upsert Permission] Action",
  PermissionCreated: "[Permission Created] Action",
  PermissionUpdated: "[Permission Updated] Action",
  DeletePermission: "[Delete Permission] Action",
  PermissionDeleted: "[Permission Deleted] Action",
  Logout: "[Logout] Action",
};

export const callTypes = {
  list: "list",
  action: "action",
};

const initialPermissionsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  permissionForEdit: undefined,
  error: null,
};

export const actions = {
  catchError: (error, callType) => ({
    type: actionTypes.CatchError,
    payload: { error, callType },
  }),
  resetError: () => ({ type: actionTypes.ResetError }),
  startCall: (callType) => ({
    type: actionTypes.StartCall,
    payload: { callType },
  }),
  fetchPermission: (id) => ({
    type: actionTypes.FetchPermission,
    payload: { permissionId: id },
  }),
  permissionFetched: (permission) => ({
    type: actionTypes.PermissionFetched,
    payload: { permissionForEdit: permission },
  }),
  fetchPermissions: (queryParams) => ({
    type: actionTypes.FetchPermissions,
    payload: { queryParams },
  }),
  permissionsFetched: (totalCount, permissions) => ({
    type: actionTypes.PermissionsFetched,
    payload: { totalCount, entities: permissions },
  }),
  upsertPermission: (permission, pageSize, onHide) => ({
    type: actionTypes.UpsertPermission,
    payload: { permission, pageSize, onHide },
  }),
  permissionCreated: (permission, pageSize) => ({
    type: actionTypes.PermissionCreated,
    payload: { permission, pageSize },
  }),
  permissionUpdated: (permission) => ({
    type: actionTypes.PermissionUpdated,
    payload: { permission },
  }),
  deletePermission: (id, handlePostDelete) => ({
    type: actionTypes.DeletePermission,
    payload: { permissionId: id, active: false, handlePostDelete },
  }),
  permissionDeleted: (id) => ({
    type: actionTypes.PermissionDeleted,
    payload: { permissionId: id },
  }),
  logout: () => ({ type: actionTypes.Logout }),
};

export const reducer = (state = initialPermissionsState, action) => {
  switch (action.type) {
    case actionTypes.CatchError: {
      const newState = { ...state };
      const { error, callType } = action.payload;

      newState.error = error.response
        ? `${error.response.data.message}`
        : "Internal server error";
      if (callType === callTypes.list) {
        newState.listLoading = false;
      } else {
        newState.actionsLoading = false;
      }
      return newState;
    }

    case actionTypes.ResetError: {
      const newState = { ...state };

      newState.error = null;
      return newState;
    }

    case actionTypes.StartCall: {
      const newState = { ...state };
      const { callType } = action.payload;

      newState.error = null;
      if (callType === callTypes.list) {
        newState.listLoading = true;
      } else {
        newState.actionsLoading = true;
      }
      return newState;
    }

    // getPermissionById
    case actionTypes.PermissionFetched: {
      const newState = { ...state };
      const { permissionForEdit } = action.payload;

      newState.actionsLoading = false;
      newState.permissionForEdit = permissionForEdit;
      newState.error = null;
      return newState;
    }

    case actionTypes.PermissionsFetched: {
      const newState = { ...state };
      const { totalCount, entities } = action.payload;

      newState.listLoading = false;
      newState.error = null;
      newState.entities = entities;
      newState.totalCount = totalCount;
      return newState;
    }

    case actionTypes.PermissionCreated: {
      const newState = { ...state };
      const { permission, pageSize } = action.payload;

      newState.actionsLoading = false;
      newState.error = null;
      if (newState.entities.length < pageSize)
        newState.entities = [...newState.entities, permission];
      return newState;
    }

    case actionTypes.PermissionUpdated: {
      const newState = { ...state };
      const { permission } = action.payload;

      newState.error = null;
      newState.actionsLoading = false;
      newState.entities = newState.entities.map((entity) => {
        if (entity.id === permission.id) return permission;
        return entity;
      });
      return newState;
    }

    case actionTypes.PermissionDeleted: {
      const newState = { ...state };
      const { permissionId } = action.payload;

      newState.error = null;
      newState.actionsLoading = false;
      newState.entities = newState.entities.filter(
        (el) => el.id !== permissionId
      );
      return newState;
    }

    case actionTypes.Logout: {
      return initialPermissionsState;
    }

    default: {
      return state;
    }
  }
};
