import React from "react";
import { Card, CardBody } from "../../../_metronic/_partials/controls";

const ContactUs = () => {
  return (
    <Card style={{ height: "100%", boxSizing: "border-box" }}>
      <CardBody>
        <div
          style={{
            width: "70%",
            margin: "auto",
            border: "1px solid #ABB2B9",
            borderRadius: "1.4rem",
            height: "70%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            rowGap: "1.2rem",
          }}
        >
          <h2
            style={{ fontSize: "2rem", color: "#566573", textAlign: "center" }}
          >
            Contact Us
          </h2>
          <p
            style={{
              fontSize: "1.2rem",
              color: "#566573",
              textAlign: "center",
            }}
          >
            If you encounter any issues, please feel free to write to us at{" "}
            <a href="mailto:developer@muoro.com">developer@muoro.com</a>.
          </p>
        </div>
      </CardBody>
    </Card>
  );
};

export default ContactUs;
