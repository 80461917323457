import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { NotAuthorizedMessage } from "../../../../../../_metronic/_helpers";

import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import { actions } from "../../../_redux/skills";
import { useSkillsUIContext } from "../SkillsUIContext";
import { SkillsUploadForm } from "./SkillsUploadForm";

export function SkillsUploadDialog({ show, onHide }) {
  // Skills UI Context
  const skillsUIContext = useSkillsUIContext();
  const skillsUIProps = useMemo(() => {
    return {
      queryParams: skillsUIContext.queryParams,
    };
  }, [skillsUIContext]);

  // Skills Redux state
  const dispatch = useDispatch();
  const { isLoading, allowedPages } = useSelector(
    (state) => ({
      isLoading: state.skills.actionsLoading,
      allowedPages: state.auth.allowedPages,
    }),
    shallowEqual
  );

  const handlePostSkillsUpload = () => {
    dispatch(actions.fetchSkills(skillsUIProps.queryParams));
    // closing modal
    onHide();
  };

  const uploadSkills = (values) => {
    // server request for uploading skills
    dispatch(actions.uploadSkills(values.file, handlePostSkillsUpload));
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      {allowedPages.skill?.create ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Upload Skills
            </Modal.Title>
          </Modal.Header>

          <SkillsUploadForm
            uploadSkills={uploadSkills}
            onHide={onHide}
            actionsLoading={isLoading}
          />
        </>
      ) : (
        <NotAuthorizedMessage />
      )}
    </Modal>
  );
}
