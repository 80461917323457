/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

import menuListData from "./AsideMenuData";
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuList({ layoutProps }) {
  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {menuListData.map((data, index) => (
          <AsideMenuItem key={index} menuItem={data} />
        ))}
      </ul>
    </>
  );
}
