// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";

import { toAbsoluteUrl } from "../../../../_helpers";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  {
    viewTitle = "",
    openViewDialog,
    editTitle = "",
    openEditDialog,
    deleteTitle = "",
    openDeleteDialog,
    downloadTitle = "",
    urlField,
    viewPdf = "",
    openPDFViewerDialog,
  }
) {
  return (
    <>
      {viewTitle && (
        <button
          title={viewTitle}
          className="btn btn-outline-primary btn-sm mx-1 data-view"
          onClick={() => openViewDialog(row.id)}
        >
          View
        </button>
      )}

      {editTitle && !downloadTitle && (
        <a
          title={editTitle}
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1 data-edit"
          onClick={() => openEditDialog(row.id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </a>
      )}

      {!row.is_primary_vendor && deleteTitle ? (
        <a
          title={deleteTitle}
          className="btn btn-icon btn-light btn-hover-danger btn-sm mx-1 data-delete"
          onClick={() => openDeleteDialog(row.id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      ) : !row.resume_path ? (
        <a
          title={deleteTitle}
          className="btn btn-icon btn-light btn-sm mx-1 data-delete"
          style={{ opacity: 0, pointerEvents: "none" }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger"></span>
        </a>
      ) : null}

      {downloadTitle && (
        <a
          target="_blank"
          rel="noopener noreferrer"
          download
          href={row[urlField]}
          title={downloadTitle}
          className="btn btn-icon btn-outline-success btn-hover-success btn-sm mx-1 data-download"
        >
          <span className="svg-icon svg-icon-md svg-icon-success">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Files/NewDownload1.svg")}
            />
          </span>
        </a>
      )}

      {row.resume_path && (
        <a
          title={row.resume_path}
          className="btn btn-icon btn-outline-info btn-hover-info btn-sm mx-1 data-resume"
          onClick={() => openPDFViewerDialog(row)}
        >
          <span className="svg-icon svg-icon-md">
            {row.resume_path.endsWith(".pdf") ? (
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/pdf.svg")} />
            ) : row.resume_path.endsWith(".doc") ||
              row.resume_path.endsWith(".docx") ? (
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/doc.svg")} />
            ) : null}
          </span>
        </a>
      )}
    </>
  );
}
