import axios from "axios";
import { App_Urls } from "../../../../Urls/urlConfig";

const PROFILES_URL = App_Urls.PROFILES_URL;
const INSTANT_PROFILE_URL = App_Urls.INSTANT_PROFILE_URL;
const SKILLS_URL = App_Urls.SKILLS_URL;
const PROFILE_MANAGED_BY = App_Urls.PROFILE_MANAGED_BY;
// CREATE OR UPDATE=>  PUT: upsert a profile to the server
export function upsertProfile(profile) {
  const formData = new FormData();
  formData.append("profile_id", profile.id);
  formData.append("name", profile.name);
  formData.append("approved", profile.approved);
  formData.append("rating", profile.rating);
  formData.append("result", profile.result);
  formData.append("resume", profile.resume);
  formData.append("email", profile.email);
  formData.append("phone_no", profile.phone_no);
  formData.append("resource_cost", profile.resource_cost);
  formData.append("country_code", profile.country_code);
  formData.append("resource_type", profile.resource_type);
  formData.append("managed_by", profile.managed_by);
  formData.append("interviewer", profile.interviewer);
  if (profile.interview_date !== "")
    formData.append("interview_date", profile.interview_date);
  formData.append("available", profile.available);
  formData.append("muoro_resume_link", profile.muoro_resume_link);
  formData.append("experience", JSON.stringify(profile.experience));

  if (profile.skills.length > 0) {
    formData.append("skills", JSON.stringify(profile.skills));
  }
  if (profile.deployment_history.length > 0) {
    formData.append(
      "deployment_history",
      JSON.stringify(profile.deployment_history)
    );
  }
  //Create New Profile
  if (!profile.id) {
    return axios.post(PROFILES_URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } else {
    //Update Profile
    return axios.put(`${PROFILES_URL}/${profile.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

// READ
export function getAllProfiles(queryParams) {
  return axios.get(PROFILES_URL, {
    params: {
      search: queryParams.filter.search,
      skill_ids: queryParams.filter.skillIds,
      job_role_ids: queryParams.filter.jobRoleIds,
      exact_match: queryParams.filter.exactMatch,
      approved: queryParams.filter.approved,
      available: queryParams.filter.available,
      max_resource_cost: queryParams.filter.max_resource_cost,
      is_deployed: queryParams.filter.is_deployed,
      limit: queryParams.pageSize,
      offset:
        queryParams.pageNumber &&
        (queryParams.pageNumber - 1) * queryParams.pageSize,
    },
  });
}

export function getProfileById(profileId) {
  return axios.get(`${PROFILES_URL}/${profileId}`);
}

//get profile managers
export function getProfileManagers() {
  return axios.get(PROFILE_MANAGED_BY);
}

export function exportProfilesToFile(queryParams) {
  return axios.get(`${PROFILES_URL}/export`, {
    params: {
      skill_ids: queryParams.filter.skillIds,
      job_role_ids: queryParams.filter.jobRoleIds,
      exact_match: queryParams.filter.exactMatch,
      max_resource_cost: queryParams.filter.max_resource_cost,
    },
    server1: true,
    responseType: "blob",
  });
}

export function uploadProfilesFromDrive() {
  return axios.post(`${PROFILES_URL}/bulk`);
}

// Instant Profiles
export async function searchProfilesBySkills(
  skillNames,
  baseSkill,
  exactMatch
) {
  const url = INSTANT_PROFILE_URL;
  const response = await axios.get(url, {
    params: {
      skills: skillNames,
      base_skills: baseSkill,
      exact_match: exactMatch,
    },
    server1: true,
  });

  return response;
}

export async function checkSkillExists(skillName) {
  const url = `${SKILLS_URL}/name/${skillName}`;
  const response = await axios.get(url);

  return response.data;
}
