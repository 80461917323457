import React, { useState, useEffect } from "react";
import Joyride from "react-joyride";
import { useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";

const JoyrideTutorial = ({ steps, redirectRoute }) => {
  const [showTutorial, setShowTutorial] = useState(false);
  const history = useHistory();
  const tutorial = localStorage.getItem("tutorial_done");
  const { user, tutorial_done } = useSelector(
    (state) => ({
      user: state.auth?.user,
      tutorial_done: state.auth?.tutorial_done,
    }),
    shallowEqual
  );
  useEffect(() => {
    setShowTutorial(true);
  }, []);
  const handleJoyrideCallback = async (data) => {
    if (data.status === "finished" || data.status === "skipped") {
      setShowTutorial(false);
      if (data.action === "skip" || !redirectRoute) {
        try {
          await axios.put("/v1/admin/client/tutorial-status", {
            tutorial_done: true,
          });
          console.log("API call to set tutorial_done to true");
        } catch (error) {
          console.log("Error while making API call:", error);
        }
        localStorage.setItem("tutorial_done", true);
      }
      // Redirect to the specified route
      if (data.type === "step:after" && redirectRoute) {
        history.push(redirectRoute);
      }
    }
  };

  return (
    <Joyride
      steps={steps}
      run={
        user?.is_primary_vendor && !tutorial && !tutorial_done && showTutorial
      }
      continuous={true}
      showSkipButton={true}
      hideCloseButton={true}
      disableOverlay={false}
      showProgress={true}
      disableScrolling={true}
      spotlightClicks={true}
      callback={handleJoyrideCallback}
    />
  );
};

export default JoyrideTutorial;
