// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import * as skillsRedux from "../../../_redux/skills";
import * as uiHelpers from "../SkillsUIHelpers";
import {
  PaginatedTable,
  ActionsColumnFormatter,
  BooleanColumnFormatter,
} from "../../../../../../_metronic/_partials/controls";
import { useSkillsUIContext } from "../SkillsUIContext";

export function SkillsTable() {
  // Skills UI Context
  const skillsUIContext = useSkillsUIContext();
  const skillsUIProps = useMemo(() => {
    return {
      queryParams: skillsUIContext.queryParams,
      setQueryParams: skillsUIContext.setQueryParams,
      openViewSkillDialog: skillsUIContext.openViewSkillDialog,
      openEditSkillDialog: skillsUIContext.openEditSkillDialog,
      openDeleteSkillDialog: skillsUIContext.openDeleteSkillDialog,
    };
  }, [skillsUIContext]);

  // Getting current state of skills list from store (Redux)
  const { currentState, allowedPages } = useSelector(
    (state) => ({
      currentState: state.skills,
      allowedPages: state.auth.allowedPages,
    }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // Skills Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // server call by queryParams
    dispatch(skillsRedux.actions.fetchSkills(skillsUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillsUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "ID",
      style:{
        color:'#2C3E50',
        fontWeight:'500'
      }
    },
    {
      dataField: "name",
      text: "Name",
      style:{
        color:'#2C3E50',
        fontWeight:'500'
      }
    },
    {
      dataField: "active",
      text: "Active",
      formatter: BooleanColumnFormatter,
      formatExtraData: { field: "active" },
    },

    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        viewTitle: allowedPages.skill?.read && "View Skill",
        openViewDialog: skillsUIProps.openViewSkillDialog,
        editTitle: allowedPages.skill?.update && "Edit Skill",
        openEditDialog: skillsUIProps.openEditSkillDialog,
        deleteTitle: allowedPages.skill?.delete && "Delete Skill",
        openDeleteDialog: skillsUIProps.openDeleteSkillDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: skillsUIProps.queryParams.pageSize,
    page: skillsUIProps.queryParams.pageNumber,
  };

  return (
    <PaginatedTable
      paginationOptions={paginationOptions}
      listLoading={listLoading}
      entities={entities}
      columns={columns}
      setQueryParams={skillsUIProps.setQueryParams}
      
    />
  );
}
