import React, { useMemo, useState } from "react";
import { Formik, FieldArray } from "formik";
import { isEqual } from "lodash";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { actions as skillsActions } from "../../../_redux/skills";
import { actions as jobRolesActions } from "../../../_redux/jobRoles";
import { useProfilesUIContext } from "../ProfilesUIContext";
import {
  CloseableBadges,
  Checkbox,
  MultiSelect,
  Select,
} from "../../../../../../_metronic/_partials/controls";

const prepareFilter = (queryParams, values) => {
  const {
    searchText,
    skills,
    jobRoles,
    approved,
    is_deployed,
    max_resource_cost,
    available,
    exactMatch,
  } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};

  filter.search = searchText;
  filter.skillIds = skills.map((skill) => skill.id);
  filter.jobRoleIds = jobRoles.map((jobRole) => jobRole.id);
  filter.skills = skills;
  filter.max_resource_cost = max_resource_cost;
  filter.jobRoles = jobRoles;
  filter.approved = approved;
  filter.available = available;
  filter.is_deployed = is_deployed;
  filter.exactMatch = exactMatch;

  newQueryParams.filter = filter;
  return newQueryParams;
};

export function ProfilesFilter() {
  const [dropdownZIndex, setDropdownZIndex] = useState("");

  const handleScroll = () => {
    setDropdownZIndex(-2);
  };

  window.addEventListener("scroll", handleScroll);

  const handleSkillFieldClick = () => {
    setDropdownZIndex(2);
  };

  //Debouncing Skills Search
  const handleSkillSearchChange = (text) => {
    const queryParams = { filter: { search: text }, pageSize: 5 };
    dispatch(skillsActions.fetchSkills(queryParams));
  };

  const debouncedLoadOptions = useDebouncedCallback(
    handleSkillSearchChange,
    500
  );

  // Profiles UI Context
  const profilesUIContext = useProfilesUIContext();
  const profilesUIProps = useMemo(() => {
    return {
      queryParams: profilesUIContext.queryParams,
      setQueryParams: profilesUIContext.setQueryParams,
    };
  }, [profilesUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(profilesUIProps.queryParams, values);
    if (!isEqual(newQueryParams, profilesUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      profilesUIProps.setQueryParams(newQueryParams);
    }
  };

  const {
    skillListLoading,
    skillEntities,
    jobRoleListLoading,
    jobRoleEntities,
  } = useSelector(
    (state) => ({
      skillListLoading: state.skills.listLoading,
      skillEntities: state.skills.entities,
      jobRoleListLoading: state.jobRoles.listLoading,
      jobRoleEntities: state.jobRoles.entities,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  //Debouncing Job Role Search
  const handleJobRoleSearchChange = (text) => {
    const queryParams = { filter: { search: text }, pageSize: 5 };
    dispatch(jobRolesActions.fetchJobRoles(queryParams));
  };

  const debouncedLoadOptionsJobRole = useDebouncedCallback(
    handleJobRoleSearchChange,
    500
  );

  const handleRemoveItem = (values, name, index, setFieldValue, submit) => {
    const newItems = [...values[name]];
    newItems.splice(index, 1);
    setFieldValue(name, newItems);
    submit();
  };

  const handleAddSelectedItem = (selectedItem, items, push, submit) => {
    if (!items.find((item) => item.id === selectedItem.id)) push(selectedItem);

    submit();
  };

  //prefilter
  const handleInput = (value, setFieldValue, handleSubmit) => {
    // Only call handleSubmit if input has at least three characters
    if (value.length >= 3) {
      setTimeout(() => {
        handleSubmit();
      }, 500);
    } else if (value.length === 0) handleSubmit();
    setFieldValue("searchText", value);
  };

  const handleCost = (value, setFieldValue, handleSubmit) => {
    if (value.length >= 3) {
      setTimeout(() => {
        handleSubmit();
      }, 500);
    } else if (value.length === 0) handleSubmit();
    setFieldValue("max_resource_cost", value);
  };

  // memoize filter values
  const filterValues = useMemo(() => {
    return profilesUIProps.queryParams?.filter || {};
  }, [profilesUIProps.queryParams]);

  return (
    <>
      <Formik
        initialValues={{
          searchText: filterValues.search || "",
          skillSearchText: "",
          skills: filterValues.skills || [],
          jobRoleSearchText: "",
          jobRoles: filterValues.jobRoles || [],
          approved: filterValues.approved || "",
          available: filterValues.available || "",
          max_resource_cost: null,
          is_deployed: filterValues.is_deployed || "",
          exactMatch: filterValues.exactMatch || false,
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({ values, handleSubmit, handleBlur, setFieldValue }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row mb-5">
              <div className="col-lg-3 profile-approved">
                <Select
                  name="approved"
                  label={"Approved"}
                  value={values.approved}
                  onChange={(e) => {
                    setFieldValue("approved", e.target.value);
                    handleSubmit();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <option value="all">All</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Select>
              </div>
              <div className="col-lg-3 profile-available">
                <Select
                  name="approved"
                  label={"Available"}
                  value={values.available}
                  onChange={(e) => {
                    setFieldValue("available", e.target.value);
                    handleSubmit();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <option value="all">All</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Select>
              </div>
              <div className="col-lg-3 profile-deployed">
                <Select
                  name="is_deployed"
                  label={"Deployed"}
                  value={values.is_deployed}
                  onChange={(e) => {
                    setFieldValue("is_deployed", e.target.value);
                    handleSubmit();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <option value="all">All</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Select>
              </div>
              <div className="col-lg-2 d-flex align-items-center mt-5 profile-exact">
                <Checkbox
                  isSelected={values.exactMatch}
                  onChange={() => {
                    setFieldValue("exactMatch", !values.exactMatch);
                    handleSubmit();
                  }}
                >
                  Exact Match
                </Checkbox>
              </div>
            </div>
            <div className="form-group row mb-3">
              <div className="col-lg-3">
                <label>Search By Name or Email</label>
                <input
                  type="text"
                  className="form-control profile-search"
                  name="searchText"
                  placeholder="Search..."
                  autoComplete="off"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(event) =>
                    handleInput(event.target.value, setFieldValue, handleSubmit)
                  }
                />
              </div>

              <div className="col-lg-3 mb-3 profile-skill">
                <label>Search By Skills</label>
                <FieldArray name="skills">
                  {(arrayHelpers) => (
                    <MultiSelect
                      inputClassName="col-lg-12"
                      placeholder="Skills..."
                      searchValue={values.skillSearchText}
                      handleSearchChange={(text) => {
                        setFieldValue("skillSearchText", text);
                        debouncedLoadOptions(text);
                      }}
                      fetchResults={handleSkillSearchChange}
                      isLoading={skillListLoading}
                      handleItemSelect={(item) =>
                        handleAddSelectedItem(
                          item,
                          values.skills,
                          arrayHelpers.push,
                          handleSubmit
                        )
                      }
                      options={skillEntities}
                      badge={false}
                      zIndex={dropdownZIndex}
                      handleSkillFieldClick={handleSkillFieldClick}
                    />
                  )}
                </FieldArray>
              </div>

              <div className="col-lg-3 mb-3 profile-jobrole">
                <label>Search By Job Roles</label>
                <FieldArray name="jobRoles">
                  {(arrayHelpers) => (
                    <MultiSelect
                      inputClassName="col-lg-12"
                      placeholder="Job Roles..."
                      searchValue={values.jobRoleSearchText}
                      handleSearchChange={(text) => {
                        setFieldValue("jobRoleSearchText", text);
                        debouncedLoadOptionsJobRole(text);
                      }}
                      fetchResults={handleJobRoleSearchChange}
                      isLoading={jobRoleListLoading}
                      handleItemSelect={(item) =>
                        handleAddSelectedItem(
                          item,
                          values.jobRoles,
                          arrayHelpers.push,
                          handleSubmit
                        )
                      }
                      options={jobRoleEntities}
                      badge={false}
                      zIndex={dropdownZIndex}
                      handleSkillFieldClick={handleSkillFieldClick}
                    />
                  )}
                </FieldArray>
              </div>

              <div className="col-lg-3 profile-cost">
                <label>Search By Resource Cost</label>
                <input
                  type="number"
                  className="form-control profile-search"
                  name="max_resource_cost"
                  placeholder="cost..."
                  autoComplete="off"
                  onBlur={handleBlur}
                  value={values.max_resource_cost}
                  onChange={(event) =>
                    handleCost(event.target.value, setFieldValue, handleSubmit)
                  }
                />
              </div>

             

              
            </div>

            {values.skills.length > 0 && (
              <div className="form-group row mb-1">
                <label className="d-flex align-items-center mr-2">
                  Skills:
                </label>
                <CloseableBadges
                  values={values.skills}
                  removeItem={(index) =>
                    handleRemoveItem(
                      values,
                      "skills",
                      index,
                      setFieldValue,
                      handleSubmit
                    )
                  }
                />
              </div>
            )}

            {values.jobRoles.length > 0 && (
              <div className="form-group row mb-1">
                <label className="d-flex align-items-center mr-2">
                  Job Roles:
                </label>
                <CloseableBadges
                  values={values.jobRoles}
                  removeItem={(index) =>
                    handleRemoveItem(
                      values,
                      "jobRoles",
                      index,
                      setFieldValue,
                      handleSubmit
                    )
                  }
                />
              </div>
            )}
          </form>
        )}
      </Formik>
    </>
  );
}
