import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";

import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";

export function JobRoleEditDialogHeader({ id, isEdit = true, onHide }) {
  // Job Roles Redux state
  const { jobRoleForEdit, actionsLoading } = useSelector(
    (state) => ({
      jobRoleForEdit: state.jobRoles.jobRoleForEdit,
      actionsLoading: state.jobRoles.actionsLoading,
    }),
    shallowEqual
  );

  const [title, setTitle] = useState("");
  // Title couting
  useEffect(() => {
    let _title = id ? "" : "New Job Role";
    if (jobRoleForEdit && id) {
      _title =
        (!isEdit ? "View" : "Edit") + ` job role '${jobRoleForEdit.name}'`;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [jobRoleForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
        {!isEdit && (
          <button
            type="button"
            className="btn btn-danger btn-sm ml-auto"
            title="close"
            onClick={onHide}
          >
            close
          </button>
        )}
      </Modal.Header>
    </>
  );
}
