import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { NotAuthorizedMessage } from "../../../../../../_metronic/_helpers";
import {
  Error,
  ModalProgressBar,
} from "../../../../../../_metronic/_partials/controls";

import { actions } from "../../../_redux/jobRoles";
import { useJobRolesUIContext } from "../JobRolesUIContext";

export function JobRoleDeleteDialog({ id, show, onHide }) {
  // Job Roles UI Context
  const jobRolesUIContext = useJobRolesUIContext();
  const jobRolesUIProps = useMemo(() => {
    return {
      queryParams: jobRolesUIContext.queryParams,
    };
  }, [jobRolesUIContext]);

  // Job roles Redux state
  const dispatch = useDispatch();
  const { isLoading, allowedPages, errorMsg } = useSelector(
    (state) => ({
      isLoading: state.jobRoles.actionsLoading,
      allowedPages: state.auth.allowedPages,
      errorMsg: state.jobRoles.error,
    }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handlePostJobRoleDelete = () => {
    dispatch(actions.fetchJobRoles(jobRolesUIProps.queryParams));
    // closing delete modal
    onHide();
  };

  const deleteJobRole = () => {
    // server request for deleting job role by id
    dispatch(actions.deleteJobRole(id, handlePostJobRoleDelete,true));
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      {allowedPages.job_role?.delete ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Job Role Delete
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Error message={errorMsg} />

            {!isLoading && (
              <span>Are you sure to permanently delete this Job Role?</span>
            )}
            {isLoading && <span>Job Role is deleting...</span>}
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>

            <button
              type="button"
              onClick={deleteJobRole}
              className="btn btn-primary btn-elevate"
            >
              Delete
            </button>
          </Modal.Footer>
        </>
      ) : (
        <NotAuthorizedMessage />
      )}
    </Modal>
  );
}
