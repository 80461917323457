import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { toAbsoluteUrl } from "../../../_helpers";

export function BrandLogo({ asideWidth }) {
  const client_logo = useSelector(
    (state) => state.auth.client_logo?.url,
    shallowEqual
  );

  const logoSrc = client_logo
    ? client_logo
    : toAbsoluteUrl("/media/logos/logo.png");

  return (
    <>
      <img
        height="40"
        alt="logo"
        src={logoSrc}
        className="ml-2 text-white d-flex align-items-center"
        style={{borderRadius:'5rem'}}
      />
      {asideWidth > 150 && (
        <span
          id="kt_aside"
          style={{
            fontSize: client_logo ? "0.8rem" : "1.5rem",
            fontWeight: "500",
            margin: "auto 0.2rem",
          }}
        >
          {client_logo ? "Powered by Muoro" : ""}
        </span>
      )}
    </>
  );
}
