import React from "react";
// import { Link } from "react-router-dom";
import { useFormikContext } from "formik";

export function Header({ heading, subText }) {
  const { isSubmitting, touched, isValid } = useFormikContext();

  return (
    <div className="card-header py-3">
      <div className="card-title align-items-start flex-column">
        <h3 className="card-label font-weight-bolder text-dark">{heading}</h3>
        <span className="text-muted font-weight-bold font-size-sm mt-1">
          {subText}
        </span>
      </div>
      <div className="card-toolbar">
        <button
          type="submit"
          className="btn btn-success mr-2"
          disabled={isSubmitting || (touched && !isValid)}
        >
          Save Changes
          {isSubmitting}
        </button>
        {/* <Link to="/user-profile/profile-overview" className="btn btn-secondary">
          Cancel
        </Link> */}
      </div>
    </div>
  );
}
