import React, { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import JoyrideTutorial from "../../../../../_metronic/_partials/controls/JoyrideTutorial";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { JobRolesFilter } from "./job-roles-filter/JobRolesFilter";
import { useJobRolesUIContext } from "./JobRolesUIContext";
import { JobRolesTable } from "./job-roles-table/JobRolesTable";
import { NotAuthorizedMessage } from "../../../../../_metronic/_helpers";

export function JobRolesCard() {
  const { allowedPages } = useSelector(
    (state) => ({ allowedPages: state.auth.allowedPages }),
    shallowEqual
  );

  const jobRolesUIContext = useJobRolesUIContext();
  const jobRolesUIProps = useMemo(() => {
    return {
      newJobRoleButtonClick: jobRolesUIContext.newJobRoleButtonClick,
    };
  }, [jobRolesUIContext]);
  const steps = [
    {
      target: ".jobrole",
      content:
        "Welcome to  Job role page , this page contains your list of job roles.",
      placement: "center",
      disableBeacon: true,
    },
   {
      target: ".jobrole-add",
      content: "If you want to add job role.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".jobrole-search",
      content: "You can search from the list of job roles.",
      placement: "right",
      disableBeacon: true,
    },
    {
      target: ".data-view",
      content: "You can view details of jobrole",
      placement: "left",
      disableBeacon: true,
    },
    {
      target: ".data-edit",
      content: "You can edit details of jobrole",
      placement: "left",
      disableBeacon: true,
    },
    {
      target: ".data-delete",
      content: "You can delete the jobrole",
      placement: "left",
      disableBeacon: true,
    },
  ];
  const redirectRoute = "/resume/profiles";
  return (
    <Card className='jobrole'>
      <CardHeader title="Job Roles list">
        {allowedPages.job_role?.create && (
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-outline-primary jobrole-add"
              onClick={jobRolesUIProps.newJobRoleButtonClick}
            >
              Add Job Role
            </button>
          </CardHeaderToolbar>
        )}
      </CardHeader>
      <CardBody>
        {allowedPages.job_role?.read ? (
          <>
            <JobRolesFilter />
            <JobRolesTable />
            <JoyrideTutorial steps={steps} redirectRoute={redirectRoute}/>
          </>
        ) : (
          <NotAuthorizedMessage />
        )}
      </CardBody>
    </Card>
  );
}
