import React from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import { actions } from "../../_redux/profiles";
import { ProfilesUploadDialog } from "./profiles-upload-dialog/ProfilesUploadDialog";
import { ProfileEditDialog } from "./profile-edit-dialog/ProfileEditDialog";
import { ProfilesLoadingDialog } from "./profiles-loading-dialog/ProfilesLoadingDialog";
import { ProfilesUIProvider } from "./ProfilesUIContext";
import { ProfilesCard } from "./ProfilesCard";
import ProfilePDFViewer from "./pdf-viewer/ProfilePDFViewer";

export function ProfilesPage({ history }) {
  const dispatch = useDispatch();

  //making Profiles table visible if hidden
  dispatch(actions.ProfilesTableState());

  const profilesUIEvents = {
    uploadProfilesButtonClick: () => {
      history.push("/resume/profiles/upload");
    },
    exportProfilesButtonClick: (queryParams) => {
      dispatch(actions.exportProfiles(queryParams));
    },
    newProfileButtonClick: () => {
      history.push("/resume/profiles/new");
    },
    openViewProfileDialog: (id) => {
      history.push(`/resume/profiles/${id}/view`);
    },
    openEditProfileDialog: (id) => {
      history.push(`/resume/profiles/${id}/edit`);
    },
    openPDFViewerDialog: (row) => {
      history.push(`/resume/profiles/${row.id}/view_resume`);
    },
  };

  const handleHide = () => {
    history.push("/resume/profiles");
    dispatch(actions.resetError());
  };

  return (
    <>
      <ProfilesUIProvider profilesUIEvents={profilesUIEvents}>
        <ProfilesLoadingDialog />

        <Switch>
          <Route exact path="/resume/profiles/new">
            {({ match }) => (
              <ProfileEditDialog show={match != null} onHide={handleHide} />
            )}
          </Route>

          <Route exact path="/resume/profiles/:id/view">
            {({ match }) => (
              <ProfileEditDialog
                isEdit={false}
                show={match != null}
                id={match && match.params.id}
                onHide={handleHide}
              />
            )}
          </Route>

          <Route exact path="/resume/profiles/:profileId/view_resume">
            {({ match }) => (
              <ProfilePDFViewer
                show={match != null}
                id={match && match.params.profileId}
                onHide={handleHide}
              />
            )}
          </Route>
          <Route path="/resume/profiles">
            <Route exact path="/resume/profiles/upload">
              {({ match }) => (
                <ProfilesUploadDialog
                  show={match != null}
                  onHide={handleHide}
                />
              )}
            </Route>
            <ProfilesCard />
          </Route>
        </Switch>
      </ProfilesUIProvider>
    </>
  );
}
